import React, { ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WpTypography } from 'components/wp-typography/wpTypography';
import WpButton from 'components/wp-button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import WpToolTip, { IWpToolTipProps } from 'components/wp-tooltip';

interface IWpZeroStateProps {
  title: string;
  description?: string;
  button?: {
    label?: string;
    fn?: any;
    disabled?: boolean;
    tooltip?: IWpToolTipProps;
  };
  children?: ReactNode;
  inCard?: boolean;
  illustration: JSX.Element;
  titleVariant?: 'h2' | 'h3' | 'p1' | 'p1Bold';
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    button: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    container: {
      marginTop: theme.spacing(1),
      height: '100%',
      textAlign: 'center',
    },
    text: {
      margin: theme.spacing(1, 'auto'),
      padding: theme.spacing(0, 3),
      maxWidth: 700,
      '& p': {
        marginTop: theme.spacing(1),
      },
    },
    photo: {
      width: 'auto',
      margin: theme.spacing(4, 2),

      '& img, svg': {
        width: '100%',
        height: 'auto',
        maxHeight: '350px',
      },
    },
    children: {
      width: '100%',
    },
  })
);

const WpZeroState = (props: IWpZeroStateProps) => {
  const { title, description, children, inCard = true, illustration, titleVariant = 'h2' } = props;

  const { fn, label, disabled, tooltip, ...others } = props.button ?? {};

  const classes = useStyles();

  const wpButton = () => (
    <WpButton
      className={classes.button}
      onClick={fn}
      variant="contained"
      color="primary"
      disableRipple
      disabled={disabled}
      {...others}
    >
      {label}
    </WpButton>
  );

  const renderZeroState = () => (
    <div className={classes.container}>
      <div className={classes.photo}>{illustration}</div>
      <div className={classes.text}>
        <WpTypography variant={titleVariant}>{title}</WpTypography>
        {description && <WpTypography>{description}</WpTypography>}
        {children && <div className={classes.children}> {children}</div>}
        {props.button &&
          (tooltip ? (
            <WpToolTip {...tooltip}>
              <span>{wpButton()}</span>
            </WpToolTip>
          ) : (
            wpButton()
          ))}
      </div>
    </div>
  );
  return inCard ? (
    <>
      <Card variant="outlined">
        <CardContent>{renderZeroState()}</CardContent>
      </Card>
    </>
  ) : (
    <>{renderZeroState()}</>
  );
};

export default WpZeroState;
