import React, { createContext, useContext } from 'react';

interface IDisableActionContext {
  actionAttributes: string[];
}
export const DisableActionsContext = createContext<IDisableActionContext>({
  actionAttributes: [],
});
import { getNodeText } from './getNodeText';

export const useDisableBySelector = (node: React.ReactNode) => {
  if (!node) return false;

  const { actionAttributes } = useContext(DisableActionsContext);

  if (!actionAttributes?.length) return false;
  return actionAttributes?.includes(getNodeText(node));
};
