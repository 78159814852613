import React from 'react';
import Breadcrumbs, { BreadcrumbsProps } from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import WpIcon from 'components/wp-icon';
import arrowRightOutline from 'components/wp-icon/icons/arrow-right-outline';

export interface IWpBreadCrumb {
  label?: string;
  icon?: IconProp;
  href?: string;
  customClass?: string;
  isSingle?: boolean;
  onClick?: (bread: IWpBreadCrumb) => void;
}

export interface IWpBreadCrumbsProps extends Partial<BreadcrumbsProps> {
  list: Array<IWpBreadCrumb>;
}

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    breadcrumb: {
      '& .MuiBreadcrumbs-separator': {
        '& svg': {
          fontSize: '12px',
          color: theme.palette.common.black,
        },
      },
    },
    passive: {
      ...theme.tag?.p1,
      color: theme.palette.common.black,
      padding: '0 !important',
      cursor: 'pointer',
      '& svg': {
        margin: '0 5px',
      },
    },
    active: {
      ...theme.tag?.p2,
      padding: '0 !important',
      color: theme.palette.common.black,
    },
  })
);
const WpBreadCrumbs = React.forwardRef(function WpBreadCrumbs(props: IWpBreadCrumbsProps, ref) {
  const {
    separator = <WpIcon svgIcon={arrowRightOutline} size={14} />,
    maxItems,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    list = [],
  } = props;
  const handleClick = (data: IWpBreadCrumb) => {
    if (data.onClick) data.onClick(data);
  };

  const classes = useStyles();

  // TODO: RB - this component is going to be deleted from the design system

  return (
    <Breadcrumbs
      className={classes.breadcrumb}
      aria-label="breadcrumb"
      separator={separator}
      maxItems={maxItems}
      itemsBeforeCollapse={itemsBeforeCollapse}
      itemsAfterCollapse={itemsAfterCollapse}
      innerRef={ref}
    >
      {list.map((value: IWpBreadCrumb, index: number) => {
        const last = index === list.length - 1;
        return last && !value.isSingle ? (
          <Typography className={classes.active} key={index}>
            {value.label}
          </Typography>
        ) : (
          <Link
            className={classes.passive}
            key={index}
            onClick={() => {
              handleClick(value);
            }}
          >
            {value.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
});
export default WpBreadCrumbs;
