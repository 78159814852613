import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const wpCheckBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    wpCheckBox: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      marginLeft: 0,
      marginRight: 0,
      '&.MuiSvgIcon-root': {
        fontSize: '20px',
      },
      '& .MuiFormControlLabel-label': {
        ...theme.tag.p1,
        marginLeft: theme.spacing(1),
        whiteSpace: 'break-spaces',
      },
      '& .MuiCheckbox-colorPrimary.Mui-disabled': {
        opacity: 0.6,
      },
    },
    icon: {
      borderRadius: '2px',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
      backgroundColor: theme.palette.common.white,

      '&.error': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
      },

      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
    description: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(0.5),
    },
  })
);
