import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const wpBottomNavStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(0, 1, 1, 1),
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      display: 'block',
      boxSizing: 'border-box',
      padding: 16,
    },
    navBar: {
      border: '1px solid',
      borderColor: theme.palette.common.lightbrown,
      borderRadius: 10,
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      backgroundColor: theme.palette.common.white,
    },
    mobileNavBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderTop: `1px solid ${theme.palette.common.lightbrown}`,
      padding: theme.spacing(2),
      gap: theme.spacing(2),

      backgroundColor: theme.palette.common.white,

      '& .MuiButton-root': {
        height: '42px', //TODO place this value in design token file
      },
    },
    primaryBtn: {
      flex: '1',
    },
    left: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    right: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',

      gap: theme.spacing(2),
    },
  })
);
