import React, { ReactElement, useEffect } from 'react';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import WpToolTip, { WpToolTipEnum } from '../wp-tooltip/wpToolTip';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import {
  //Controller,
  DeepMap,
  FieldError,
  //useFormContext,
} from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export interface IWpRadioProps extends Omit<RadioProps, 'name'> {
  info?: string | Array<string> | ReactElement;
  name?: string | Array<string>;
  hoverMessage?: string;
  isFixedHoverMessage?: boolean;
}

export interface IWpFormRadioProps extends Partial<RadioGroupProps> {
  list: Array<IWpRadioProps>;
  label?: React.ReactNode;
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  disabled?: boolean;
  displayKey?: string;
  displayValue?: string;
}

const radioStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    radiocontainer: {
      background: theme.palette.common.white,
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.common.black,
    },

    bold: {
      fontWeight: theme.tag.p1Bold.fontWeight,
    },
    radiogroup: {
      border: '1px solid ' + theme.palette.common.lightbrown,
      boxSizing: 'border-box',
      borderBottom: 'none',

      '& .MuiRadio-root': {
        padding: 0,
      },
      '& .MuiTypography-body1': {
        marginLeft: theme.spacing(1),
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
        padding: '8px 12px ',
        width: '100%',
        boxSizing: 'border-box',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: 'rgba(106, 98, 98, 0.5) !important',
      },
    },
    radiofirst: {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    radiolast: {
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      borderBottom: '1px solid ' + theme.palette.common.lightbrown,
    },
    info: {
      ...theme.tag?.p1,
      fontFamily: theme.typography.fontFamily,
      margin: '-8px 0 8px 34px',
      '&.disabled': {
        color: theme.palette.text.disabled,
      },
    },
    onerror: {
      borderColor: theme.palette.error.main,
    },
    defaultRadio: {
      color: theme.palette.common.lightbrown,
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: theme.palette.common.white,

      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
    checkedIcon: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
      background: theme.palette.primary.disabled,
      '&:before': {
        display: 'block',
        width: 10,
        height: 10,
        content: '""',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        margin: 3,
        boxSizing: 'border-box',
      },
    },
  })
);
const FromRadio = React.forwardRef(function FromRadio(props: IWpFormRadioProps, ref) {
  const {
    name,
    id = `input-${name}`,
    label,
    // required,
    errorobj,
    list,
    value,
    onChange,
    color = 'primary',
    disabled,
    displayKey = 'value',
    displayValue = 'name',
  } = props;
  let isError = false;
  let errorMessage = '';
  if (errorobj && name && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }
  const classes = radioStyles();
  const [selectedValue, setselectedValue] = React.useState<any>(value);
  let valueType = typeof value;

  const handleClick = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    switch (valueType) {
      case 'number':
        setselectedValue(Number(target.value));
        break;
      case 'boolean':
        setselectedValue(target.value == 'true');
        break;
      default:
        setselectedValue(target.value);
        break;
    }
  };

  useEffect(() => {
    if (list.length && value) {
      valueType = typeof value;
      setselectedValue(value);
    }
  }, [value, list]);

  const renderFormControlLabel = (radio: IWpRadioProps) => {
    const controlLabel = (
      <FormControlLabel
        value={radio[displayKey]}
        control={
          <Radio
            color={color as any}
            onChange={handleClick}
            disabled={disabled || radio.disabled}
            checked={radio[displayKey] === selectedValue}
            className={classes.defaultRadio}
            // inputProps={{
            //   required: required || false,
            // }}
            // error={isError}
            // helperText={errorMessage}
            icon={<span className={classes.icon} />}
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          />
        }
        label={
          Array.isArray(radio[displayValue]) ? (
            radio[displayValue].map((item: string) => (
              <>
                {item}
                <br />
                {radio.info}
              </>
            ))
          ) : (
            <>
              <span className={classes.bold}>{radio[displayValue]}</span>
              <br />
              {Array.isArray(radio.info)
                ? radio.info.map((str) => (
                    <>
                      {str}
                      <br />
                    </>
                  ))
                : radio.info}
            </>
          )
        }
      />
    );
    if (!radio.hoverMessage) return controlLabel;

    return (
      <WpToolTip
        placement="bottom-start"
        title={radio.hoverMessage || ''}
        tooltype={radio.isFixedHoverMessage ? WpToolTipEnum.fixed : WpToolTipEnum.custom}
        arrow
        isTrackPosition={!radio.isFixedHoverMessage}
      >
        {controlLabel}
      </WpToolTip>
    );
  };

  return (
    <>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <RadioGroup value={selectedValue} onChange={onChange} ref={ref} color={color} className={classes.radiocontainer}>
        {list.map((radio: IWpRadioProps, index: number) => {
          const last = index === list.length - 1;
          return (
            <div
              className={clsx(classes.radiogroup, {
                [classes.radiofirst]: index == 0,
                [classes.radiolast]: last,
                [classes.onerror]: isError,
              })}
              key={index}
            >
              {renderFormControlLabel(radio)}
            </div>
          );
        })}
      </RadioGroup>
      {isError && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  );
});
export default FromRadio;

// export const WpFormRadio = React.forwardRef(function WpFormRadio(
//   props: IWpFormRadioProps,
//   ref
// ) {
//   const { control } = useFormContext();
//   return (
//     <Controller
//       as={FromRadio}
//       control={control}
//       defaultValue=""
//       {...props}
//       innerRef={ref}
//     />
//   );
// });
