import React from 'react';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import WpButton from '../wp-button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import WpToolTip, { IWpToolTipProps } from '../wp-tooltip/wpToolTip';
import { useDisableBySelector } from 'utility';
import WpIcon from 'components/wp-icon';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';

export interface IWpComboButtonOption {
  label?: string;
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  /**
   * @deprecated Use `slim` prop instead.
   */
  size?: number;
  slim?: boolean;
  tooltip?: Omit<IWpToolTipProps, 'children'>;
}

export interface IWpComboButtonProps {
  options: IWpComboButtonOption[];
  onButtonClick?: (ev: EventListener) => void;
  label?: string;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary' | 'default' | 'inherit';
  placement?: 'bottom' | 'top';
  /**
   * @deprecated Use `slim` prop instead.
   */
  size?: number;
  slim?: boolean;
  disabled?: boolean;
  noGutter?: boolean;
}

const WpComboButton: React.FC<IWpComboButtonProps> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const isDisableBySelector = useDisableBySelector(props.label);
  React.useEffect(() => {
    const containerWidth: any = anchorRef ? anchorRef.current?.clientWidth : 0;
    const buttonWidth = containerWidth - 16;
    setWidth(buttonWidth);
  }, []);

  const getButtonType = (type?: boolean) => {
    if (type) {
      return '4px 8px';
    } else {
      return '8px 16px';
    }
  };
  const useStyles = (props: IWpComboButtonProps) => {
    return makeStyles((theme: Theme) =>
      createStyles({
        splitButtonText: {
          margin: props.noGutter ? 0 : `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
          padding: getButtonType(props?.slim),
          borderRight: 'none !important',
        },
        splitButtonIcon: {
          minWidth: 0,
          margin: props.noGutter ? 0 : theme.spacing(0.5),
          marginLeft: 0,
          padding: getButtonType(props?.slim),
          paddingLeft: 10,
          borderLeft: `1px solid ${theme.palette.common.white}`,
          '& .MuiButton-label': {
            transition: 'transform .2s ease-in-out',
          },
          '&.open.top': {
            '& .MuiButton-label': {
              transform: 'rotate(180deg)',
            },
          },
        },
        expandedButton: {
          minWidth: width,
          margin: theme.spacing(0),
          padding: getButtonType(props?.slim),
          '&:hover': {
            backgroundColor: theme.palette.common.white,
          },
        },
      })
    )();
  };

  const classes = useStyles(props);

  const handleSplitButtonTextClick = (event: any) => {
    if (props.onButtonClick) props.onButtonClick(event);
  };

  const handleExpandedButtonClick = (option: IWpComboButtonOption) => {
    setOpen((prevOpen) => !prevOpen);
    if (option && option.onClick) option.onClick();
  };

  const handleSplitButtonIconClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <WpButton
          onClick={handleSplitButtonTextClick}
          variant={props.variant}
          color={props.color}
          className={classes.splitButtonText}
          disabled={isDisableBySelector || props.disabled}
        >
          {props.label}
        </WpButton>
        <WpButton
          variant={props.variant}
          color={props.color}
          onClick={handleSplitButtonIconClick}
          className={clsx(classes.splitButtonIcon, { open, top: props.placement === 'top' })}
          isVertical={false}
          disabled={isDisableBySelector}
        >
          <WpIcon svgIcon={caretDownSolid} color="white" size={12} />
        </WpButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={props.placement}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <>
              {props.options?.length > 0 &&
                props.options.map((option) => {
                  const isDisabled = useDisableBySelector(option.label);
                  const button = (
                    <WpButton
                      variant="outlined"
                      color={props.color}
                      onClick={() => handleExpandedButtonClick(option)}
                      className={clsx(classes.expandedButton, { top: placement === 'top' })}
                      disabled={isDisabled || option.disabled}
                    >
                      {option.label}
                    </WpButton>
                  );
                  return (
                    <div key={option.label}>
                      {option.tooltip ? (
                        <WpToolTip isTrackPosition={false} {...option.tooltip}>
                          <span>{button}</span>
                        </WpToolTip>
                      ) : (
                        button
                      )}
                    </div>
                  );
                })}
            </>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default WpComboButton;
