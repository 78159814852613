import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        d="M2.908 9.994a.612.612 0 00.35-.175c.17-.16 4.228-4.03 4.257-4.061a1.119 1.119 0 000-1.516C7.485 4.211 3.43.342 3.258.181a.608.608 0 00-.354-.175.629.629 0 00-.543.188.61.61 0 00-.174.485.601.601 0 00.18.395c.015.016.946.905 2.07 1.976A782.33 782.33 0 016.48 5c0 .002-.92.88-2.043 1.95-1.124 1.071-2.055 1.96-2.07 1.976a.61.61 0 00-.169.306.687.687 0 00.022.352.657.657 0 00.39.383c.09.03.201.04.298.027z"
        clipRule="evenodd"
      />
    </>
  ),
};

export default icon;
