import { IconType } from './icons/icon-types';

export enum IWpIconsEnum {
  checkSquare_outline = 'checkSquare_outline',
  checkSquare_solid = 'checkSquare_solid',
  infoCircle_solid = 'infoCircle_solid',
  infoCircle_outline = 'infoCircle_outline',
  alertTriangle_solid = 'alertTriangle_solid',
  alertTriangle_outline = 'alertTriangle_outline',
  alertCircle_solid = 'alertCircle_solid',
  alertCircle_outline = 'alertCircle_outline',
  block_outline = 'block_outline',
  checkCircle_solid = 'checkCircle_solid',
  deleteCircle_solid = 'deleteCircle_solid',
  deleteCircle_outline = 'deleteCircle_outline',
  questionCircle_outline = 'questionCircle_outline',
  questionCircle_solid = 'questionCircle_solid',
  clock_outline = 'clock_outline',
  notification_outline = 'notification_outline',
  logout_outline = 'logout_outline',
  search_outline = 'search_outline',
  filter_outline = 'filter_outline',
  person_outline = 'person_outline',
  person_solid = 'person_solid',
  arrowDown_outline = 'arrowDown_outline',
  arrowUp_outline = 'arrowUp_outline',
  arrowLeft_outline = 'arrowLeft_outline',
  arrowRight_outline = 'arrowRight_outline',
  home_outline = 'home_outline',
  file_outline = 'file_outline',
  image_outline = 'image_outline',
  download_outline = 'download_outline',
  externalLink_outline = 'externalLink_outline',
  copy_outline = 'copy_outline',
  employee_outline = 'employee_outline',
  video_outline = 'video_outline',
  trash_outline = 'trash_outline',
  caretUp_solid = 'caretUp_solid',
  caretDown_solid = 'caretDown_solid',
  caretLeft_solid = 'caretLeft_solid',
  caretRight_solid = 'caretRight_solid',
  employee_solid = 'employee_solid',
  star_solid = 'star_solid',
  star_outline = 'star_outline',
  sync_outline = 'sync_outline',
  user_outline = 'user_outline',
  userCheck_outline = 'userCheck_outline',
  userDisable_outline = 'userDisable_outline',
  close_outline = 'close_outline',
  add_outline = 'add_outline',
  calendar_outline = 'calendar_outline',
  calendarCheck_outline = 'calendarCheck_outline',
  calendarDisable_outline = 'calendarDisable_outline',
  verticalMenu_outline = 'verticalMenu_outline',
  multiplePeople_outline = 'multiplePeople_outline',
  reports_outline = 'reports_outline',
  store_outline = 'store_outline',
  puzzle_outline = 'puzzle_outline',
  settings_outline = 'settings_outline',
  lightbulb_outline = 'lightbulb_outline',
  multipleFiles_outline = 'multipleFiles_outline',
  notification_solid = 'notification_solid',
  pencil_outline = 'pencil_outline',
  pencilSquare_outline = 'pencilSquare_outline',
  mail_outline = 'mail_outline',
  cog_outline = 'cog_outline',
  dollarSign_outline = 'dollarSign_outline',
  payroll_outline = 'payroll_outline',
  calculator_outline = 'calculator_outline',
  viewEnable_outline = 'viewEnable_outline',
  viewDisable_outline = 'viewDisable_outline',
  percent_outline = 'percent_outline',
  minus_outline = 'minus_outline',
  sparkle_solid = 'sparkle_solid',
  camera_outline = 'camera_outline',
  imageFlower_outline = 'imageFlower_outline',
  clock_solid = 'clock_solid',
  equals_outline = 'equals_outline',
  capsLock_outline = 'capsLock_outline',
  caretSort_solid = 'caretSort_solid',
  horizontalMenu_solid = 'horizontalMenu_solid',
  printer_outline = 'printer_outline',
  hamburger_outline = 'hamburger_outline',
  roundedArrow_outline = 'roundedArrow_outline',
  calendarWarning_outline = 'calendarWarning_outline',
  alarm_outline = 'alarm_outline',
  forward_outline = 'forward_outline',
  multiplePeople_solid = 'multiplePeople_solid',
  user_solid = 'user_solid',
}

export type IconColor =
  | 'primary'
  | 'primaryDisabled'
  | 'secondary'
  | 'secondaryDisabled'
  | 'success'
  | 'error'
  | 'link'
  | 'warning'
  | 'black'
  | 'white'
  | 'lightbrown'
  | 'brown'
  | 'blue'
  | 'red'
  | 'orange'
  | 'green'
  | 'grey'
  | 'yellow'
  | 'disabled';

export type GutterDirection =
  | 'x' // left and right
  | 'y' // top and bottom
  | 'top' // top
  | 'right' // right
  | 'bottom' // bottom
  | 'left' // left
  | 'all'; // all sides;
export interface IWpIconProps {
  color?: IconColor;
  size?: number;
  svgIcon?: IconType;
  gutter?: GutterDirection;
  showLabel?: boolean;
  customClass?: string | undefined;
}
