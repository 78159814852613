import { useFlags } from 'launchdarkly-react-client-sdk';
import { convertToLDFeatureName } from './utils';
import { AppEnvironments, LdCLient } from './constants';

const useFeatureFlag = () => {
  const flags = useFlags();

  const feature = (featureName: string) => {
    const convertedFeatureName: string = convertToLDFeatureName(featureName);
    return flags[convertedFeatureName]; // the temporary feature toggle will be kept until we have a confirmation on the Launch darkly workflow.
  };
  return { feature };
};

export { useFeatureFlag, LdCLient, AppEnvironments };
