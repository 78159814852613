import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81254 4.53125L7.18746 4.53125C7.27214 4.53125 7.34538 4.49918 7.40723 4.43504C7.46908 4.37084 7.5 4.2949 7.5 4.20714C7.5 4.11939 7.46908 4.04344 7.40723 3.9793L5.21977 1.71085C5.15786 1.64671 5.08462 1.61458 5 1.61458C4.91538 1.61458 4.84208 1.64671 4.78029 1.71085L2.59283 3.9793C2.53104 4.04344 2.5 4.11939 2.5 4.20714C2.5 4.2949 2.53104 4.37084 2.59283 4.43504C2.65462 4.49918 2.72792 4.53125 2.81254 4.53125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.18746 5.78125H2.81254C2.72786 5.78125 2.65462 5.81332 2.59277 5.87746C2.53092 5.94166 2.5 6.0176 2.5 6.10536C2.5 6.19311 2.53092 6.26906 2.59277 6.3332L4.78023 8.60165C4.84214 8.66579 4.91538 8.69792 5 8.69792C5.08462 8.69792 5.15792 8.66579 5.21971 8.60165L7.40717 6.3332C7.46896 6.26906 7.5 6.19311 7.5 6.10536C7.5 6.0176 7.46896 5.94166 7.40717 5.87746C7.34538 5.81332 7.27208 5.78125 7.18746 5.78125Z"
      />
    </>
  ),
};

export default icon;
