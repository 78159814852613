import { PopoverOrigin } from '@material-ui/core/Popover';
import { useEffect, useState } from 'react';

type MenuPositionProps = {
  ref: React.MutableRefObject<HTMLDivElement>;
  items: any[];
  initialPaperProps: {
    style: {
      maxHeight: string;
      marginTop: string;
    };
  };
  isClearable?: boolean;
  isSearchable?: boolean;
  isLinkEnabled?: boolean;
};
// Hook for managing menu position of wpDropdown
export const useMenuPosition = ({
  ref,
  items,
  initialPaperProps,
  isClearable,
  isSearchable,
  isLinkEnabled,
}: MenuPositionProps) => {
  type PaperProps = typeof initialPaperProps;

  const [anchorOrigin, setAnchcorOrigin] = useState<PopoverOrigin>({ vertical: 'bottom', horizontal: 'center' });
  const [transformOrigin, setTransformOrigin] = useState<PopoverOrigin>({ vertical: 'top', horizontal: 'center' });
  const [labelWidth, setlabelWidth] = useState<number>(0);

  const getPaperProps = (): PaperProps => {
    const isTopPosition = anchorOrigin.vertical === 'top';
    const marginTop = !isTopPosition ? initialPaperProps.style?.marginTop : '-' + initialPaperProps.style?.marginTop;
    return {
      ...initialPaperProps,
      style: {
        ...initialPaperProps.style,
        marginTop,
      },
    };
  };

  useEffect(() => {
    const setMenuPosition = (e: any) => {
      const maxHeight = +initialPaperProps.style?.maxHeight.split('px')[0];

      const currentItemHeight = 34;
      const itemsCount = items.length;
      const itemsCountInScroll = itemsCount > 5 ? currentItemHeight * 5 : currentItemHeight * itemsCount;
      const menuHeight = itemsCountInScroll > maxHeight || itemsCountInScroll === 0 ? maxHeight : itemsCountInScroll;

      const selectHeight = ref.current?.clientHeight || 0;
      const getAdditionalHeight = (): number => {
        let res = 0;
        if (isClearable) {
          res += 48;
        }
        if (isSearchable) {
          res += 50;
        }
        if (isLinkEnabled) {
          res += currentItemHeight;
        }
        return res;
      };
      const additionalHeight = getAdditionalHeight();

      if (document.body.clientHeight - e.clientY < menuHeight + selectHeight + additionalHeight) {
        setAnchcorOrigin({ vertical: 'top', horizontal: 'center' });
        setTransformOrigin({ vertical: 'bottom', horizontal: 'center' });
      } else {
        setAnchcorOrigin({ vertical: 'bottom', horizontal: 'center' });
        setTransformOrigin({ vertical: 'top', horizontal: 'center' });
      }
    };

    if (ref?.current) {
      setlabelWidth(ref?.current?.clientWidth || 0);
      ref?.current?.addEventListener('mouseenter', setMenuPosition);
    }

    return () => {
      ref?.current && ref?.current?.removeEventListener('mouseenter', setMenuPosition);
    };
  }, []);

  return { anchorOrigin, transformOrigin, newPaperProps: getPaperProps(), labelWidth };
};
