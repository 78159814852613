import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useDisableBySelector } from 'utility';
import clsx from 'clsx';
import WpToolTip from 'components/wp-tooltip';

export interface IWpButton {
  // your custom options with their types
  isVertical?: boolean;
  /**
   * @deprecated Use `slim` prop instead.
   */
  isLarge?: boolean;
  /**
   * @deprecated No longer used.
   */
  noButtonWidth?: boolean;
  noGutter?: boolean;
  /**
   * @deprecated No longer used.
   */
  slim?: boolean;
  important?: boolean;
  /**
   * Can only be used on marketing websites!
   */
  fullWidth?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      width: 'auto',
      margin: theme.spacing(2),
      padding: theme.spacing(0.75, 2),
      //large and small buttons
      '& .MuiButton-label': {
        '& > span': {
          display: 'inline-block',
        },
      },

      borderRadius: 6,

      ...theme.tag.p1,
      textTransform: 'none',

      '&.MuiButton-outlined': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.common.white,
        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.primary.disabled}`,
          color: theme.palette.primary.disabled,
        },
        '&:hover': {
          border: `1px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 4px 6px 0px rgba(44, 39, 54, 0.25)',
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.primary.dark}`,
          outlineOffset: '1px',
          backgroundColor: theme.palette.common.white,
        },
        '&:active': {
          outline: 'none',
          boxShadow: ' 0px 4px 6px 0px rgba(44, 39, 54, 0.2) inset',
        },
      },
      '&.MuiButton-contained': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

        boxShadow: 'none',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.primary.disabled,
          color: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '&:target': {
          boxShadow: '0px 4px 6px 0px rgba(44, 39, 54, 0.2) inset',
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.primary.main}`,
          outlineOffset: '2px',
        },
        '&:active': {
          boxShadow: 'inset 0px 4px 8px rgba(0, 0, 0, 0.2)',
          outline: 'none',
        },

        '&.important': {
          backgroundColor: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.dark,
          },
        },
      },
      '&.MuiButton-text': {
        ...theme.tag.p1Bold,

        color: theme.palette.link.main,
        '&.Mui-disabled': {
          color: theme.palette.link.disabled,
        },
        '&:hover': {
          textDecoration: 'underline',
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.link.main}`,
          outlineOffset: '2px',
        },
        '&:active': {
          outline: 'none',
          backgroundColor: theme.palette.link.disabled,
          textDecoration: 'underline',
        },
      },
      '&.MuiButton-textSecondary': {
        color: theme.palette.common.black,
        '&.Mui-disabled': {
          color: theme.palette.common.black,
          opacity: '0.6',
        },
        '&:hover': {
          textDecoration: 'underline',
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.primary.dark}`,
          outlineOffset: '2px',
        },
        '&:active': {
          outline: 'none',
          backgroundColor: theme.palette.link.disabled,
          textDecoration: 'underline',
        },
      },
      '&.MuiButton-outlinedSecondary': {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.common.white,
        '&.Mui-disabled': {
          border: `1px solid ${theme.palette.common.brown}`,
          color: theme.palette.common.brown,
        },
        '&:hover': {
          border: `1px solid ${theme.palette.primary.dark}`,
          color: theme.palette.primary.dark,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.common.white,
          boxShadow: '0px 4px 6px 0px rgba(44, 39, 54, 0.25)',
        },
        '&.Mui-focusVisible': {
          outline: `2px solid ${theme.palette.primary.dark}`,
          outlineOffset: '1px',
          backgroundColor: theme.palette.common.white,
        },
        '&:active': {
          outline: 'none',
          boxShadow: ' 0px 4px 6px 0px rgba(44, 39, 54, 0.2) inset',
        },
      },
    },
    rootNoGutter: {
      margin: 0,
    },
    rootFullWidth: {
      width: '100%',
    },
    rootVertical: {
      marginTop: 0,
      marginLeft: 0,
    },
    rootSlim: {
      padding: theme.spacing(0.5, 1),
    },
  })
);

const WpButton = <C extends React.ElementType>(props: ButtonProps<C, { component?: C }> & IWpButton) => {
  const classes = useStyles(props);
  const { important = false, className, disabled, ...others } = props;

  const isDisableBySelector = useDisableBySelector(props?.children);

  const renderButton = () => (
    <Button
      className={clsx(classes.root, className, {
        ['important']: important,
        [classes.rootNoGutter]: props.noGutter,
        [classes.rootFullWidth]: props.fullWidth,
        [classes.rootVertical]: props.isVertical,
        [classes.rootSlim]: props.slim,
      })}
      {...others}
      disabled={isDisableBySelector || disabled}
      disableRipple
    >
      {props?.children}
    </Button>
  );

  if (props.tooltip) {
    return (
      <WpToolTip title={props.tooltip}>
        <span>{renderButton()}</span>
      </WpToolTip>
    );
  }

  return renderButton();
};

export default WpButton;
