import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import WpButton from '../wp-button';
import WpFilterWidgetFilterType from './filter-type/filterType';
import StyledMenu from './filter-styled-menu/filterStyledMenu';
import { IWpFilterWidgetDropdownType } from './wpFilterWidget.interface';
import { WpCheckbox } from '../wp-checkbox';
import { wpFilterWidgetStyles } from './wpFilterWidgetStyles';
import { NothingCreatedIcon } from '../../assets/nothingCreatedIcon';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { FilterDate } from './filter-type/filterDate';
import WpIcon from 'components/wp-icon';
import closeOutline from 'components/wp-icon/icons/close-outline';
import filterOutline from 'components/wp-icon/icons/filter-outline';

export interface IWpFilterWidgetProps {
  onCancel?: () => void;
  onApply: (options: IWpFilterWidgetDropdownType[]) => void;
  options: IWpFilterWidgetDropdownType[];
  defaultFilterCheck?: boolean;
  onViewDisabledItems?: (shouldShowDisabledItem: boolean) => void;
  viewDisabledItemsState?: boolean;
  hideSelectAll?: boolean;
  hideDropDown?: boolean;
  onClearAll?: () => any;
  onViewDisabledText?: string;
  showResultText?: string;
  cancelText?: string;
  filterText?: string;
  clearAllText?: string;
  showCancel?: boolean;
  disableFilter?: boolean;
  showNoFilters?: boolean;
  noFilterTitle?: string;
  noFilterDesc?: string;
  filterDropdownClassName?: string;
}

const WpFilterWidget = ({
  cancelText = 'Cancel',
  clearAllText = 'Clear all',
  defaultFilterCheck = false,
  disableFilter = false,
  filterText = 'Filter',
  hideDropDown = false,
  hideSelectAll = false,
  onApply,
  onCancel,
  onClearAll,
  onViewDisabledItems,
  onViewDisabledText = 'View disabled items',
  options,
  showCancel = false,
  showResultText = 'Show result',
  viewDisabledItemsState = false,
  showNoFilters = false,
  noFilterTitle = 'Nothing to filter',
  noFilterDesc = '',
  filterDropdownClassName,
}: IWpFilterWidgetProps) => {
  const [height, setHeight] = useState(0);
  const theme = useTheme();
  const classes = wpFilterWidgetStyles({ height });
  const [showDisabledItem, setShowDisabledItem] = useState(false);
  const [filterOptionsBackup, setFilterOptionsBackup] = useState<IWpFilterWidgetDropdownType[]>([]);
  const [tempFilterOptions, setTempFilterOptions] = useState<IWpFilterWidgetDropdownType[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // keepSelected must be required for prevent wrong using of this function
  const closeDropdown = ({ keepSelected }: { keepSelected: boolean }) => {
    setAnchorEl(null);

    if (!keepSelected) {
      onCancel?.();
      setTempFilterOptions(JSON.parse(JSON.stringify(filterOptionsBackup)));
    }
  };

  const changeTempOption = React.useCallback((index, filterItemList) => {
    setTempFilterOptions((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      return temp.map((item: IWpFilterWidgetDropdownType, idx: number) => {
        if (idx === index) return { ...item, filterItemList };
        return { ...item };
      });
    });
  }, []);

  const changeDateOption = React.useCallback((index, date) => {
    setTempFilterOptions((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      return temp.map((item: IWpFilterWidgetDropdownType, idx: number) => {
        if (idx === index) return { ...item, dateValue: date };
        return { ...item };
      });
    });
  }, []);

  function onFilterApply(shouldCloseModal: boolean) {
    if (shouldCloseModal) {
      setFilterOptionsBackup(JSON.parse(JSON.stringify(tempFilterOptions)));
      onApply(tempFilterOptions);
      closeDropdown({ keepSelected: true });
    }
  }

  function handleClearAll() {
    handleViewDisabledItem(null, false); // to reset the disabled state
    const clearedFilterOptions = tempFilterOptions.map((item) => {
      if (item.isDate) {
        return { ...item, dateValue: null };
      } else
        return {
          ...item,
          filterItemList: item.filterItemList.map((el) => ({ ...el, isSelected: false })),
        };
    });
    setTempFilterOptions(clearedFilterOptions);
    onApply?.(clearedFilterOptions);
    onClearAll?.();
    closeDropdown({ keepSelected: true });
  }

  function handleViewDisabledItem(_ev: unknown, checked: boolean) {
    setShowDisabledItem(checked);
    onViewDisabledItems?.(checked);
  }

  useEffect(() => {
    setFilterOptionsBackup(options);
    setTempFilterOptions(JSON.parse(JSON.stringify(options)));
  }, [options]);

  useEffect(() => {
    setShowDisabledItem(defaultFilterCheck);
  }, [defaultFilterCheck]);

  useEffect(() => {
    if (viewDisabledItemsState !== undefined) {
      setShowDisabledItem(viewDisabledItemsState);
    }
  }, [viewDisabledItemsState]);

  // method for calculating the height of dropdown
  const getBodyHeight = (value: number, selectedId?: number) => {
    const perRow = 4;
    const result = tempFilterOptions.reduce((resultArray: any, item, index) => {
      const chunkIndex = Math.floor(index / perRow);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    const selected = result[result.length - 1].filter((item: any) => item.id == selectedId);
    const newVal = value > 150 ? 150 : value; // set max height of dropdown

    if (selected.length > 0) {
      setHeight(newVal + theme.spacing(3));
    } else {
      setHeight(0);
    }
  };

  return (
    <>
      <WpButton
        noGutter
        variant="outlined"
        color="default"
        onClick={openDropdown}
        className={classes.wpFilterBtn}
        aria-controls="wp-filter-dropdown"
        aria-haspopup="true"
        disabled={disableFilter}
      >
        <WpIcon svgIcon={filterOutline} color={disableFilter ? 'primaryDisabled' : 'black'} gutter="right" size={14} />
        {filterText}
      </WpButton>
      <StyledMenu
        id="wp-filter-dropdown"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => closeDropdown({ keepSelected: false })}
        keepMounted
        className={clsx(classes.wpFilterDropdown, filterDropdownClassName)}
      >
        {tempFilterOptions.length || !showNoFilters ? (
          <div className="wp-filter-btn-dropdown">
            <div className="wpfbd-header">
              <div className="wpfbd-left-section wpfbd-view-disabled">
                {onViewDisabledItems && (
                  <WpCheckbox label={onViewDisabledText} value={showDisabledItem} onChange={handleViewDisabledItem} />
                )}
              </div>
              <div className="wpfbd-right-section">
                <div className="wpfbd-close-icon" onClick={() => closeDropdown({ keepSelected: false })}>
                  <WpIcon svgIcon={closeOutline} color="black" />
                </div>
              </div>
            </div>
            {!hideDropDown && (
              <div className="wpfbd-body">
                {tempFilterOptions.map((filter, index) => (
                  <Fragment key={`Filter_Type_${filter.id}`}>
                    {filter.isDate ? (
                      <FilterDate filterType={filter} index={index} changeDateOption={changeDateOption} />
                    ) : (
                      <WpFilterWidgetFilterType
                        filterType={filter}
                        changeTempOption={changeTempOption}
                        index={index}
                        hideSelectAllGlobal={hideSelectAll}
                        setHeight={getBodyHeight}
                      />
                    )}
                  </Fragment>
                ))}
              </div>
            )}
            <div className="wpfbd-footer">
              {showCancel && (
                <div className="wpfbd-left-section">
                  <WpButton variant="outlined" color="default" onClick={() => closeDropdown({ keepSelected: false })}>
                    {cancelText}
                  </WpButton>
                </div>
              )}
              <div className={clsx('wpfbd-left-section', { ['wpfbd-float-right']: showCancel })}>
                <WpButton variant="contained" color="primary" onClick={() => onFilterApply(true)}>
                  {showResultText}
                </WpButton>
                <WpButton variant="text" onClick={handleClearAll}>
                  {clearAllText}
                </WpButton>
              </div>
            </div>
          </div>
        ) : (
          <div className="wp-no-filter">
            <NothingCreatedIcon></NothingCreatedIcon>
            <Typography variant="h3">{noFilterTitle}</Typography>
            <Typography variant="body1">{noFilterDesc}</Typography>
          </div>
        )}
      </StyledMenu>
    </>
  );
};

export default WpFilterWidget;
