import React from 'react';
import { createStyles, withStyles, makeStyles, Theme } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';

import { Controller, DeepMap, FieldError, useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
import { get } from '../../utility';

import WpToolTip, { WpToolTipEnum } from '../wp-tooltip/wpToolTip';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';

import DefaultFormText from './defaultFormTemplate';
import { useMenuPosition } from './useMenuPosition';
import Typography from '@material-ui/core/Typography';
import WpIcon from 'components/wp-icon';
import infoCircleSolid from 'components/wp-icon/icons/info-circle-solid';
import alertCircleSolid from 'components/wp-icon/icons/alert-circle-solid';
import closeOutline from 'components/wp-icon/icons/close-outline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

interface IStyleProps {
  width: number;
}

export interface IWpDropdownProps extends Partial<SelectProps> {
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  menuItems?: any[];
  errormessage?: string;
  selectAllLabel?: string;
  hasSelectAll?: boolean;
  callBackOnChange?: (selectedValue: string | []) => void;
  callBackOnAction?: (selectedValue: string | []) => void;
  callBackOnClose?: () => void;
  name: string;
  placeholder?: string;
  isLinkEnabled?: boolean;
  disabled?: boolean;
  linkText?: string;
  displayKey?: string;
  displayValue?: string;
  displayValueSelected?: string;
  returnValue?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  selectAllText?: string;
  onFocusSelect?: () => void;
  errorMessageProp?: string | null;
  keydownEvent?: (e: any) => void;
  customVariant?: 'outlined' | 'standard' | 'filled';
  disablePortal?: boolean;
  formTemplate?: any;
  handleAddForm?: (value: any) => void;
  handleCancelForm?: () => void;
  showForm?: boolean;
  onClearSectionClick?: () => void;
  isCloseOnClearSection?: boolean;
  callBackOnOpen?: () => void;
  disabledTooltip?: React.ReactNode;
  wrapOptionsText?: boolean;
  optionsTextMaxLength?: number;
  tooltip?: string;
  isInTable?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkColor: {
      color: theme.palette?.accent?.color3,
    },
    selectItemWidth: (props: IStyleProps) => ({
      width: props.width + 'px',
    }),
    clearSection: {
      '&.has-scroll': {
        marginRight: '12px',
      },
      height: '26px',
      backgroundColor: theme.palette.common.brown,
      borderRadius: '4px',
      ...theme.tag.p1,
      padding: '0 !important',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    customSelect: {
      '& span': {
        padding: 0,
      },
      '& .MuiMenu-paper': {
        padding: '20px !important',
      },
    },
    subheader: {
      position: 'relative',
      margin: '12px 16px 10px',
      padding: 0,
    },
    menuItemHidden: {
      display: 'none',
    },
    searchInput: {
      margin: theme.spacing(1, 0),
    },
    selectItem: {
      pointerEvents: 'none',
    },
    icon: {
      padding: theme.spacing(0.5, 0.5, 0.25, 0.5),
    },
    tooltip: {
      display: 'inline-block',
    },
    errorStart: {
      paddingLeft: theme.spacing(1),
    },
  })
);

const PaperProps = {
  style: {
    maxHeight: '170px',
    marginTop: '4px',
  },
  variant: 'outlined',
};

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    '&.Mui-selected, &:active': {
      backgroundColor: 'inherit',
    },
    '&.Mui-selected:hover, &:hover': {
      backgroundColor: theme.palette.primary.light,
      '&.Mui-disabled': {
        backgroundColor: 'inherit',
      },
    },
    '& .MuiListItemText-root': {
      margin: '0 !important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit',
    },
  },
}))(MenuItem);

const WpDropdown = React.forwardRef(function WpDropdown(props: IWpDropdownProps, ref) {
  const { control } = useFormContext();
  const {
    name,
    id = `wp-dropdown-${name}`,
    multiple,
    value = multiple ? [] : '',
    hasSelectAll = false,
    selectAllLabel = 'Select All',
    menuItems = [],
    callBackOnChange,
    callBackOnAction,
    callBackOnClose,
    onFocusSelect,
    required,
    isLinkEnabled,
    linkText = 'Action',
    placeholder,
    displayKey = 'key',
    displayValue = 'value',
    displayValueSelected,
    returnValue = '',
    errorobj,
    isClearable = false,
    errorMessageProp = null,
    disabled = false,
    isSearchable = false,
    searchPlaceholder = 'Search',
    selectAllText = 'All',
    keydownEvent,
    customVariant = 'outlined',
    disablePortal = false,
    formTemplate,
    showForm = false,
    handleCancelForm,
    handleAddForm,
    onClearSectionClick,
    isCloseOnClearSection = false,
    callBackOnOpen,
    disabledTooltip,
    wrapOptionsText = false,
    optionsTextMaxLength = 100,
  } = props;
  const resultArray: any[] = [];
  const resultArrayCallback: any[] = [];

  let isError = false;
  let errorMessage = '';
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  } else if (errorMessageProp !== null) {
    isError = true;
    errorMessage = errorMessageProp;
  }
  const nestedErrorMessage = get(errorobj || {}, name);
  if (!isError && nestedErrorMessage) {
    isError = true;
    errorMessage = nestedErrorMessage.message;
  }

  let isLink = false;
  if (isLinkEnabled) {
    isLink = true;
  }
  const [showPlaceholder, setShowPlaceholder] = React.useState(value === 'none');
  const [selectedValue, setselectedValue] = multiple
    ? React.useState<string[]>(value as [])
    : React.useState(value as string);
  const [searchText, setSearchText] = React.useState<string>('');
  const currentValue: any = useWatch({ control, name });

  const innerRef = ref || React.useRef();
  const { anchorOrigin, transformOrigin, newPaperProps, labelWidth } = useMenuPosition({
    ref: innerRef as React.MutableRefObject<HTMLDivElement>,
    items: menuItems,
    initialPaperProps: PaperProps,
    isClearable,
    isSearchable,
    isLinkEnabled,
  });
  const classes = useStyles({
    width: labelWidth,
  });

  React.useEffect(() => {
    let value = currentValue;
    if (value) {
      if (hasSelectAll && multiple) {
        if ((value as string[])[(value as string[]).length - 1] === 'all') {
          value = selectedValue.length === menuItems?.length ? [] : menuItems?.map((item) => item[displayKey]);
        } else if ((value as string[]).includes('all')) {
          //we pressed not 'all' option, but 'all' is selected
          const indexOfAll = value.indexOf('all');
          value.splice(indexOfAll, 1);
        }
      }
      setselectedValue(value as never);
    }
  }, [currentValue]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as unknown;

    if (value) {
      // if (hasSelectAll && multiple) {
      //   if ((value as string[])[(value as string[]).length - 1] === 'all') {
      //     value = selectedValue.length === menuItems?.length ? [] : menuItems?.map((item) => item[displayValue]);
      //   }
      // }

      // setselectedValue(value as never);
      // // if (!!callBackOnAction && value == linkText) {
      // //   callBackOnAction(value as never);
      // // }
      if (!!callBackOnChange && value != linkText) {
        if (!multiple) {
          const result = menuItems.find((itemVal) => itemVal[displayKey] === value);
          const resultCallback = returnValue ? result[returnValue] : result;
          callBackOnChange(resultCallback as never);
        } else if ((value as string[])[(value as string[]).length - 1] === 'all') {
          let resultCallback: any = [];
          const filteredValue = menuItems.filter(({ disable }) => !disable);
          const result = selectedValue.length === menuItems?.length ? [] : [...filteredValue];
          if (returnValue) {
            resultCallback = result?.map((item) => item[returnValue]);
          } else {
            resultCallback = result;
          }
          callBackOnChange(resultCallback as never);
        } else {
          let result: string;
          const usedValue: any = value;
          for (let i = 0; i < usedValue.length; i++) {
            result = menuItems.find((itemVal) => itemVal[displayKey] === usedValue[i]);
            resultArray.push(result);
            if (returnValue) {
              resultArrayCallback.push(result[returnValue]);
            }
          }
          const resultCallback = returnValue ? resultArrayCallback : resultArray;
          callBackOnChange(resultCallback as never);
        }
      }
    }
  };

  const clearAll = () => {
    if (multiple) setselectedValue([] as any);
    else setselectedValue('' as any);
  };

  const renderClearIcon = ({ resetValue, onChange }: { resetValue: [] | null; onChange: any }) => {
    return isClearable && selectedValue ? (
      <InputAdornment position="end" style={{ position: 'absolute', right: 16 }}>
        <IconButton
          onClick={(e) => {
            !isCloseOnClearSection && e.stopPropagation();
            clearAll();
            onChange(resetValue); // sets the empty value to the react hook form state
            if (callBackOnChange) callBackOnChange(resetValue as never); // to fire an empty state to the callbackonChange event
            onClearSectionClick && onClearSectionClick();
          }}
        >
          <WpIcon svgIcon={closeOutline} color="brown" size={12} />
        </IconButton>
      </InputAdornment>
    ) : (
      []
    );
  };

  const renderSearchText = () => {
    return isSearchable ? (
      <ListSubheader disableSticky={true}>
        <TextField
          onChange={(e: any) => setSearchText(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
          fullWidth={true}
          variant="outlined"
          placeholder={searchPlaceholder}
          autoFocus={true}
          className={classes.searchInput}
        />
      </ListSubheader>
    ) : undefined;
  };

  const renderValueWithTooltip = (key: number) => {
    const item = displayedItems.find((item) => item[displayKey] === key)?.[displayValueSelected || displayValue];
    const title = item || placeholder;

    if (disabled && disabledTooltip) {
      return (
        <WpToolTip
          isTrackPosition={false}
          aria-multiline={true}
          title={disabledTooltip}
          placement="bottom-start"
          tooltype={WpToolTipEnum.custom}
        >
          <span>
            <span className={classes.selectItem}>{title}</span>
          </span>
        </WpToolTip>
      );
    }
    const characterlengthCoef = 8.5;
    const ref = innerRef as any;
    const parentWidth = ref?.current?.offsetWidth - 42; // 42px it's padding
    const showTooltip = title && title?.length * characterlengthCoef > parentWidth;
    return showTooltip ? (
      <WpToolTip
        isTrackPosition={false}
        aria-multiline={true}
        title={title}
        placement="bottom-start"
        tooltype={WpToolTipEnum.custom}
      >
        <span>
          <span className={classes.selectItem}>{title}</span>
        </span>
      </WpToolTip>
    ) : (
      <>{title}</>
    );
  };

  const handleDropDownKeyDown = (e: any) => {
    //When user types inside input
    if (e.target.type === 'text') {
      e.stopPropagation();
    }
    keydownEvent && keydownEvent(e);
  };

  const renderMenuItem = (item: any) => {
    const value = item[displayValue];
    const isLongName = typeof value === 'string' && value.length > optionsTextMaxLength && wrapOptionsText;
    return isLongName ? (
      <WpToolTip title={value} placement="bottom-start" tooltype={WpToolTipEnum.custom} arrow={false}>
        <Typography variant="inherit" noWrap>
          {value}
        </Typography>
      </WpToolTip>
    ) : (
      value
    );
  };

  const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedItems = React.useMemo(
    () => menuItems.filter((item) => containsText(item[displayValue].toString(), searchText)),
    [searchText, menuItems]
  );
  const isDefaultInput = customVariant === 'outlined';
  return (
    <React.Fragment>
      {props.label &&
        (props.tooltip ? (
          <WpToolTip tooltype={2} title={props.tooltip} arrow>
            <span className={classes.tooltip}>
              <InputLabel htmlFor={id}>
                {props.label}
                <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
              </InputLabel>
            </span>
          </WpToolTip>
        ) : (
          <InputLabel htmlFor={id}>{props.label}</InputLabel>
        ))}
      {multiple === true && (
        <Controller
          control={control}
          name={name}
          defaultValue={selectedValue}
          rules={{ required: required }}
          innerRef={innerRef}
          render={({ onChange, onBlur }) => {
            return (
              <Select
                endAdornment={renderClearIcon({ resetValue: [], onChange })}
                IconComponent={(props) => (
                  <div className={clsx(props?.className, classes.icon)}>
                    <WpIcon svgIcon={caretDownSolid} size={12} color="brown" />
                  </div>
                )}
                onChange={(e) => {
                  onChange(e);
                  handleChange(e);
                }}
                onBlur={onBlur}
                onFocus={() => {
                  if (onFocusSelect) onFocusSelect();
                  setShowPlaceholder(false);
                }}
                onOpen={() => {
                  if (callBackOnOpen) callBackOnOpen();
                }}
                onClose={(e: any) => {
                  setShowPlaceholder(e.target.value === undefined);
                  setSearchText('');
                  if (callBackOnClose) callBackOnClose();
                }}
                onKeyDownCapture={handleDropDownKeyDown}
                value={selectedValue}
                label=""
                variant={customVariant}
                input={isDefaultInput ? <OutlinedInput /> : undefined}
                renderValue={(selected) => {
                  if (!(selected as string[]).length) return placeholder;
                  if (menuItems.length > 1 && (selected as string[]).length === menuItems.length) return selectAllText;
                  return menuItems
                    .filter((item) => (selected as string[]).includes(item[displayKey]))
                    .map((item) => item[displayValueSelected || displayValue])
                    ?.join(', ');
                }}
                multiple
                inputRef={ref}
                ref={innerRef}
                fullWidth={true}
                error={isError}
                MenuProps={{
                  PaperProps: newPaperProps,
                  anchorOrigin,
                  transformOrigin,
                  disablePortal,
                  getContentAnchorEl: null,
                  autoFocus: false,
                  MenuListProps: {
                    subheader: renderSearchText(),
                  },
                }}
                displayEmpty
                className={classes.customSelect}
                disabled={disabled}
              >
                {placeholder && (
                  <StyledMenuItem value="" disabled className={!showPlaceholder ? classes.menuItemHidden : ''}>
                    {placeholder}
                  </StyledMenuItem>
                )}
                {hasSelectAll && menuItems && !searchText && (
                  <StyledMenuItem className="select-checkbox" value="all">
                    <Checkbox
                      color="primary"
                      style={{ padding: '0px !important' }}
                      checked={selectedValue.length === menuItems.length && menuItems.length !== 0}
                      indeterminate={selectedValue.length > 0 && selectedValue.length < menuItems.length}
                      disableRipple
                    />
                    <ListItemText primary={selectAllLabel} key="all" />
                  </StyledMenuItem>
                )}
                {displayedItems &&
                  displayedItems.map((item) =>
                    item.disable ? (
                      <WpToolTip
                        key={item[displayKey]}
                        title={item?.hoverContent ? <>{item?.hoverContent}</> : ''}
                        placement="bottom-start"
                        tooltype={WpToolTipEnum.custom}
                        arrow
                        PopperProps={{
                          style: { pointerEvents: 'none' },
                        }}
                      >
                        <StyledMenuItem
                          key={item[displayKey]}
                          value={`${item[displayKey]}_disabled`}
                          disabled
                          style={{ pointerEvents: 'stroke' }}
                        >
                          <Checkbox color="primary" disabled disableRipple />
                          <ListItemText primary={item[displayValue]} key={item[displayKey]} />
                        </StyledMenuItem>
                      </WpToolTip>
                    ) : (
                      <StyledMenuItem
                        className={clsx({ [classes.selectItemWidth]: wrapOptionsText })}
                        key={item[displayKey]}
                        value={item[displayKey]}
                      >
                        <Checkbox
                          color="primary"
                          checked={selectedValue.indexOf(item[displayKey]) > -1}
                          disableRipple
                        />
                        {renderMenuItem(item)}
                      </StyledMenuItem>
                    )
                  )}
                {showForm && (
                  <DefaultFormText
                    formTemplate={formTemplate}
                    handleAddClick={handleAddForm}
                    handleCancel={handleCancelForm}
                  />
                )}
              </Select>
            );
          }}
        />
      )}

      {multiple !== true && (
        <Controller
          control={control}
          name={name}
          defaultValue={selectedValue}
          rules={{ required: required }}
          innerRef={innerRef}
          render={({ onChange, onBlur, value }) => (
            <Select
              startAdornment={
                isError && props.isInTable ? (
                  <div className={classes.errorStart}>
                    <WpToolTip title={errorMessage}>
                      <span>
                        <WpIcon svgIcon={alertCircleSolid} color="error" />
                      </span>
                    </WpToolTip>
                  </div>
                ) : (
                  <></>
                )
              }
              endAdornment={renderClearIcon({ resetValue: null, onChange })}
              IconComponent={(props) => (
                <div className={clsx(props?.className, classes.icon)}>
                  <WpIcon svgIcon={caretDownSolid} size={12} color="brown" />
                </div>
              )}
              onChange={(e) => {
                if (!e.target.value) return;
                onChange(e);
                handleChange(e);
              }}
              onBlur={onBlur}
              onOpen={() => {
                if (callBackOnOpen) callBackOnOpen();
              }}
              onFocus={() => setShowPlaceholder(false)}
              onClose={(e: any) => {
                setShowPlaceholder(e.target.value === undefined);
                setSearchText('');
                if (callBackOnClose) callBackOnClose();
              }}
              onKeyDownCapture={handleDropDownKeyDown}
              value={value}
              label=""
              variant={customVariant}
              input={isDefaultInput ? <OutlinedInput /> : undefined}
              ref={innerRef}
              fullWidth={true}
              error={isError}
              renderValue={renderValueWithTooltip}
              MenuProps={{
                PaperProps: newPaperProps,
                anchorOrigin,
                transformOrigin,
                disablePortal,
                getContentAnchorEl: null,
                autoFocus: false,
                MenuListProps: {
                  subheader: renderSearchText(),
                },
              }}
              displayEmpty
              className={classes.customSelect}
              disabled={disabled}
            >
              {placeholder && (
                <StyledMenuItem value="" disabled className={!showPlaceholder ? classes.menuItemHidden : ''}>
                  {placeholder}
                </StyledMenuItem>
              )}

              {displayedItems &&
                displayedItems.map((item) =>
                  item.disable ? (
                    <WpToolTip
                      key={item[displayKey]}
                      title={item?.hoverContent ? <>{item?.hoverContent}</> : ''}
                      placement="bottom-start"
                      tooltype={WpToolTipEnum.custom}
                      arrow
                      PopperProps={{
                        style: { pointerEvents: 'none' },
                      }}
                    >
                      <StyledMenuItem
                        key={item[displayKey]}
                        value={`${item[displayKey]}_disabled`}
                        disabled
                        style={{ pointerEvents: 'stroke' }}
                      >
                        {item[displayValue]}
                      </StyledMenuItem>
                    </WpToolTip>
                  ) : (
                    <StyledMenuItem
                      className={clsx({ [classes.selectItemWidth]: wrapOptionsText })}
                      key={item[displayKey]}
                      value={item[displayKey]}
                    >
                      {renderMenuItem(item)}
                    </StyledMenuItem>
                  )
                )}
              {isLink && (
                <StyledMenuItem
                  className={classes.linkColor}
                  onClick={() => {
                    if (!!callBackOnAction) {
                      callBackOnAction(linkText as never);
                    }
                  }}
                >
                  {linkText}
                </StyledMenuItem>
              )}
              {showForm && (
                <DefaultFormText
                  formTemplate={formTemplate}
                  handleAddClick={handleAddForm}
                  handleCancel={handleCancelForm}
                />
              )}
            </Select>
          )}
        />
      )}
      {isError && !props.isInTable && <FormHelperText error>{errorMessage}</FormHelperText>}
    </React.Fragment>
  );
});

export default WpDropdown;
