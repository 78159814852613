import { PopoverOrigin } from '@material-ui/core/Popover';
import { useEffect, useState } from 'react';

export const useDatePickerPosition = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const pickerHeight = 340;
  const [anchorOrigin, setAnchcorOrigin] = useState<PopoverOrigin>({ vertical: 'bottom', horizontal: 'center' });
  const [transformOrigin, setTransformOrigin] = useState<PopoverOrigin>({ vertical: 'top', horizontal: 'center' });
  useEffect(() => {
    const setMenuPosition = (e: any) => {
      if (window.innerHeight - e.clientY < pickerHeight) {
        setAnchcorOrigin({ vertical: 'top', horizontal: 'center' });
        setTransformOrigin({ vertical: 'bottom', horizontal: 'center' });
      } else {
        setAnchcorOrigin({ vertical: 'bottom', horizontal: 'center' });
        setTransformOrigin({ vertical: 'top', horizontal: 'center' });
      }
    };

    if (ref?.current) {
      ref?.current?.addEventListener('mouseenter', setMenuPosition);
    }

    return () => {
      ref?.current && ref?.current?.removeEventListener('mouseenter', setMenuPosition);
    };
  }, []);

  return { anchorOrigin, transformOrigin };
};
