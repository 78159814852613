import React, { ElementType, useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { useWpMediaQuery } from 'styles/useMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';

export enum WpToolTipEnum {
  default = 1,
  custom = 2,
  fixed = 4,
}

export interface IWpToolTipProps extends TooltipProps {
  tooltype?: number;
  isTrackPosition?: boolean;
  as?: ElementType;
  show?: boolean;
}

const getCustomClasses = (theme: Theme, additionalClasses?: any) => ({
  arrow: {
    '&:before': {
      border: '1px solid ' + theme.palette.common.lightbrown,
    },
    color: theme.palette.common.white,
    ...additionalClasses?.arrow,
  },
  tooltip: {
    ...theme.tag?.p3,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    border: '1px solid ' + theme.palette.common.lightbrown,
    padding: theme.spacing(),
    borderRadius: 6,
    minWidth: 72,
    maxWidth: 589,
    ...additionalClasses?.tooltip,
  },
});

const CustomTooltip = withStyles((theme) => getCustomClasses(theme))(Tooltip);

const FixedTooltip = withStyles((theme) =>
  getCustomClasses(theme, {
    arrow: {
      left: '5px !important',
    },
    tooltip: {
      margin: 0,
    },
  })
)(Tooltip);

const tooltipStyles = makeStyles(() =>
  createStyles({
    button: {
      textAlign: 'left',
    },
  })
);

const WpToolTip = React.forwardRef(function WpToolTip(props: IWpToolTipProps, ref) {
  const {
    title,
    isTrackPosition = true,
    placement = 'bottom-start',
    tooltype = 2,
    as: WrapperComponent,
    show = true,
    children,
    ...rest
  } = props;

  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (e: any) => {
    setPosition({ x: e.pageX, y: e.pageY });
  };
  const popperProps = {
    anchorEl: {
      clientHeight: 0,
      clientWidth: 0,
      getBoundingClientRect: () => ({
        top: position.y,
        left: position.x,
        right: position.x,
        bottom: position.y,
        x: position.x,
        y: position.y,
        width: 0,
        height: 0,
        toJSON: () => null,
      }),
    },
  };

  const classes = tooltipStyles();

  const { isMobileScreen } = useWpMediaQuery();

  const [open, setOpen] = useState(false);

  const mobilePopperProps = {
    disablePortal: true,
  };

  const closeTooltip = () => {
    setOpen(false);
  };

  const openTooltip = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  if (!show) {
    return children;
  }

  const wrappedChildren = WrapperComponent ? <WrapperComponent>{children}</WrapperComponent> : children;

  return (
    <>
      {tooltype == WpToolTipEnum.default &&
        (isMobileScreen ? (
          <ClickAwayListener onClickAway={closeTooltip}>
            <Tooltip
              title={title || ''}
              {...rest}
              ref={ref}
              tabIndex={-1}
              placement={placement}
              // mobile props
              disableFocusListener
              disableTouchListener
              PopperProps={mobilePopperProps}
              open={open}
              onClose={closeTooltip}
              onOpen={openTooltip}
            >
              <Button className={classes.button} onClick={openTooltip} disableRipple>
                {children}
              </Button>
            </Tooltip>
          </ClickAwayListener>
        ) : (
          <Tooltip
            title={title || ''}
            onMouseMove={isTrackPosition ? handleMouseMove : undefined}
            PopperProps={isTrackPosition ? popperProps : undefined}
            placement={placement}
            {...rest}
            ref={ref}
            tabIndex={-1}
          >
            {wrappedChildren}
          </Tooltip>
        ))}

      {tooltype == WpToolTipEnum.custom &&
        (isMobileScreen ? (
          <ClickAwayListener onClickAway={closeTooltip}>
            <CustomTooltip
              title={title || ''}
              {...rest}
              ref={ref}
              tabIndex={-1}
              placement={placement}
              // mobile props
              disableFocusListener
              disableTouchListener
              PopperProps={mobilePopperProps}
              open={open}
              onClose={closeTooltip}
              onOpen={openTooltip}
            >
              <Button className={classes.button} onClick={openTooltip} disableRipple>
                {children}
              </Button>
            </CustomTooltip>
          </ClickAwayListener>
        ) : (
          <CustomTooltip
            title={title || ''}
            onMouseMove={isTrackPosition ? handleMouseMove : undefined}
            PopperProps={isTrackPosition ? popperProps : undefined}
            placement={placement}
            {...rest}
            ref={ref}
            tabIndex={-1}
          >
            {wrappedChildren}
          </CustomTooltip>
        ))}

      {tooltype == WpToolTipEnum.fixed &&
        (isMobileScreen ? (
          <ClickAwayListener onClickAway={closeTooltip}>
            <FixedTooltip
              title={title || ''}
              {...rest}
              ref={ref}
              tabIndex={-1}
              placement={placement}
              // mobile props
              disableFocusListener
              disableTouchListener
              PopperProps={mobilePopperProps}
              open={open}
              onClose={closeTooltip}
              onOpen={openTooltip}
            >
              <Button className={classes.button} onClick={openTooltip} disableRipple>
                {children}
              </Button>
            </FixedTooltip>
          </ClickAwayListener>
        ) : (
          <FixedTooltip title={title || ''} {...rest} ref={ref} tabIndex={-1} placement={placement}>
            {wrappedChildren}
          </FixedTooltip>
        ))}
    </>
  );
});

export default WpToolTip;
