import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0C6.32608 0 7.59785 0.526784 8.53553 1.46447C9.47322 2.40215 10 3.67392 10 5C10 6.32608 9.47322 7.59785 8.53553 8.53553C7.59785 9.47322 6.32608 10 5 10C3.67392 10 2.40215 9.47322 1.46447 8.53553C0.526784 7.59785 0 6.32608 0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447ZM5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.76898C5.27614 2.76898 5.5 2.99284 5.5 3.26898V4.81501L7.13862 6.72657C7.31833 6.93623 7.29407 7.25188 7.08441 7.4316C6.87476 7.61132 6.5591 7.58705 6.37938 7.37739L4.62038 5.32539C4.5427 5.23477 4.5 5.11934 4.5 4.99998V3.26898C4.5 2.99284 4.72386 2.76898 5 2.76898Z"
      />
    </>
  ),
};

export default icon;
