import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H4C4.27614 0 4.5 0.223858 4.5 0.5C4.5 0.776142 4.27614 1 4 1H1.5C1.36739 1 1.24021 1.05268 1.14645 1.14645C1.05268 1.24021 1 1.36739 1 1.5V8.5C1 8.63261 1.05268 8.75978 1.14645 8.85355C1.24022 8.94732 1.36739 9 1.5 9H8.5C8.63261 9 8.75978 8.94732 8.85355 8.85355C8.94732 8.75978 9 8.63261 9 8.5V6C9 5.72386 9.22386 5.5 9.5 5.5C9.77614 5.5 10 5.72386 10 6V8.5C10 8.89783 9.84196 9.27936 9.56066 9.56066C9.27936 9.84196 8.89783 10 8.5 10H1.5C1.10217 10 0.720644 9.84196 0.43934 9.56066C0.158035 9.27936 0 8.89783 0 8.5V1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0.5C6.5 0.223858 6.72386 0 7 0H9.5C9.77614 0 10 0.223858 10 0.5V3C10 3.27614 9.77614 3.5 9.5 3.5C9.22386 3.5 9 3.27614 9 3V1H7C6.72386 1 6.5 0.776142 6.5 0.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85355 0.146447C10.0488 0.341709 10.0488 0.658291 9.85355 0.853553L5.35355 5.35355C5.15829 5.54882 4.84171 5.54882 4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645L9.14645 0.146447C9.34171 -0.0488155 9.65829 -0.0488155 9.85355 0.146447Z"
      />
    </>
  ),
};

export default icon;
