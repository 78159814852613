import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IWpGridProps } from '../wpGrid.interface';
import clsx from 'clsx';
import { __IWpGridStickyColumnData, __IWpGridStickyColumnsDataGroup } from '..';
import WpToolTip from '../../wp-tooltip/wpToolTip';
import { WpToolTipEnum } from '../../wp-tooltip/wpToolTip';
import { StyleProps } from '../wpGrid.interface';
import { wpGridStyles } from '../wpGridStyles';
import WpIcon from 'components/wp-icon';
import { disabledThemeColor } from '../constants';
import arrowDownOutline from 'components/wp-icon/icons/arrow-down-outline';
import Collapse from '@material-ui/core/Collapse';

interface IRowStyle {
  isSubCol?: boolean;
  isSubColStart?: boolean;
  isSubColEnd?: boolean;
}

const WpGridRow: React.FC<{
  gridData: IWpGridProps;
  keyExtractor?: (row: any) => string;
  row: any;
  rowIndex: number;
  isFooter?: boolean;
  stickyColumnDataGroup: __IWpGridStickyColumnsDataGroup;
  hasExpandedGridTable?: boolean;
  hasDefaultExpandedChildContext?: boolean;
  disabled?: boolean;
}> = (props) => {
  const tooltipWidth = '150px';
  const [expanded, setExpanded] = useState(false || props?.hasDefaultExpandedChildContext);
  const limitForTextLength = 15 || props.gridData?.ellipseTextLength;
  let styleProps: StyleProps = { width: tooltipWidth };
  const classes = wpGridStyles(styleProps);
  const disabled = props.disabled;

  function toggleExpansion() {
    setExpanded(!expanded);
  }

  const renderRowStyle = ({ isSubCol, isSubColStart, isSubColEnd }: IRowStyle) => {
    if (isSubCol) {
      if (isSubCol && isSubColStart) {
        return { paddingBottom: '16px', paddingTop: '16px' };
      } else if (isSubCol && isSubColEnd) {
        return { paddingTop: '16px', paddingBottom: '16px' };
      }
      return { paddingBottom: '816x', paddingTop: '16px' };
    }
    return {};
  };

  const renderStickyColumnStyle = (stickyData: __IWpGridStickyColumnData) => {
    if (!stickyData) return {};
    return {
      left: stickyData.leftPosition,
      right: stickyData.rightPosition,
    };
  };

  let previousCellMergeCompensationCount = 0;
  const confirmIfNotMerged = (colSpan?: number) => {
    if (previousCellMergeCompensationCount > 0) {
      previousCellMergeCompensationCount -= 1;
      return false;
    }
    if (colSpan) previousCellMergeCompensationCount = colSpan - 1;
    return true;
  };

  const showContent = (col: any, isContentTemplate: any) => {
    return isContentTemplate
      ? col.templateSupply(props.row, col, { isFooter: !!props.isFooter, rowIndex: props.rowIndex })
      : props.row[col.field];
  };

  const showEllipseAndDisplayTooltip = (
    col: any,
    colIndex: number,
    isContentTemplate?: boolean,
    specifiedWidth?: any
  ) => {
    styleProps = { width: specifiedWidth ? specifiedWidth : tooltipWidth };

    return props.gridData.ellipseColumnIndex?.includes(colIndex) &&
      typeof props.row[col.field] === 'string' &&
      props.row[col.field].length > limitForTextLength ? (
      <WpToolTip
        aria-multiline={true}
        title={showContent(col, isContentTemplate)}
        placement={'bottom-start' || props.gridData?.ellipseToolTipPlacement}
        tooltype={WpToolTipEnum.custom}
        arrow
      >
        <span className={classes.columWithEllipse} style={{ width: styleProps.width }}>
          {showContent(col, isContentTemplate)}
        </span>
      </WpToolTip>
    ) : (
      showContent(col, isContentTemplate)
    );
  };

  const renderTable = (col: any, colIndex: number, item?: any) => {
    return (
      <TableCell
        key={
          props.keyExtractor
            ? `Grid_Row_Cell_${props.keyExtractor(props.row)}_${colIndex}`
            : `Grid_Row_Cell_${props.rowIndex}_${colIndex}`
        }
        align={col.align}
        style={{
          ...renderRowStyle(props.row),
          ...renderStickyColumnStyle(props.stickyColumnDataGroup[colIndex]),
        }}
        className={`${col.sticky || ''} ${col.classList ? col.classList.join(' ') : ''}`}
        colSpan={props.row[col.field + '_colSpan']}
        rowSpan={props.row[col.field + '_rowSpan']}
      >
        <div className={`wpg-child-row-with-toogler ${props.row.iconStart ? 'iconstart' : ''}`}>{item[col.field]}</div>
      </TableCell>
    );
  };

  return (
    <>
      <TableRow
        style={
          disabled
            ? { background: disabledThemeColor, cursor: 'not-allowed' }
            : props.row.rowColor
            ? { background: props.row.rowColor }
            : {}
        }
        className={clsx(props.row.classList ? props.row.classList.join(' ') : '', 'wp-grid-table-row')}
      >
        {props.gridData.childContent && props.gridData.iconColumnIndex === undefined && (
          <TableCell className={`wpg-child-row-toggler ${expanded ? 'expanded' : ''}`}>
            {props.row.hasChildren && props.gridData.iconColumnIndex === undefined && (
              <div onClick={toggleExpansion}>
                <WpIcon svgIcon={arrowDownOutline} size={14} />
              </div>
            )}
          </TableCell>
        )}
        {props.row.isWholeRowContent && (
          <TableCell
            style={{
              ...renderRowStyle(props.row),
            }}
            colSpan={props.gridData.columns.length + 1}
            className="wp-row-child-style"
          >
            {props.row.isWholeRowContent}
          </TableCell>
        )}
        {props.gridData.columns.map((col, colIndex) => {
          if (props.row.isWholeRowContent) return [];
          return (
            confirmIfNotMerged(props.row[col.field + '_colSpan']) && (
              <TableCell
                key={
                  props.keyExtractor
                    ? `Grid_Row_Cell_${props.keyExtractor(props.row)}_${colIndex}`
                    : `Grid_Row_Cell_${props.rowIndex}_${colIndex}`
                }
                align={col.align}
                style={{
                  verticalAlign: col?.verticalAlign || 'middle',
                  fontVariantNumeric: col?.tabularNum ? 'tabular-nums' : 'normal',
                  ...renderRowStyle(props.row),
                  ...renderStickyColumnStyle(props.stickyColumnDataGroup[colIndex]),
                }}
                className={`${col.sticky || ''} ${col.classList ? col.classList.join(' ') : ''}`}
                colSpan={props.row[col.field + '_colSpan']}
                rowSpan={props.row[col.field + '_rowSpan']}
              >
                <div className={`wpg-child-row-with-toogler ${props.row.iconStart ? 'iconstart' : ''}`}>
                  {col.templateSupply
                    ? showEllipseAndDisplayTooltip(col, colIndex, true, col.width)
                    : showEllipseAndDisplayTooltip(col, colIndex, false, col.width)}

                  {props.gridData.childContent &&
                    props.row.hasChildren &&
                    props.gridData.iconColumnIndex !== undefined &&
                    colIndex === props.gridData.iconColumnIndex && (
                      <div
                        className={clsx(
                          `wpg-child-row-toggler ${expanded ? 'expanded' : ''}`,
                          `wpg-child-row-toggler ${props.row.iconStart ? 'iconstartStyles' : 'iconendStyles'}`
                        )}
                      >
                        <div onClick={toggleExpansion}>
                          <WpIcon svgIcon={arrowDownOutline} size={14} />
                        </div>
                      </div>
                    )}
                </div>
              </TableCell>
            )
          );
        })}
      </TableRow>

      {props.gridData.childContent &&
        props.row.hasChildren &&
        expanded &&
        (props?.hasExpandedGridTable ? (
          props?.gridData?.childContent(props?.row)?.props.rows?.length &&
          props?.gridData?.childContent(props?.row)?.props.rows.map((item: any) => (
            <TableRow
              key={`Grid_Row_Child_${props.rowIndex}`}
              className={clsx(props.row.classList ? props.row.classList.join(' ') : '', 'wp-grid-table-row')}
            >
              {props.gridData.childContent && props.gridData.iconColumnIndex === undefined && (
                <TableCell className={`wpg-child-row-toggler ${expanded ? 'expanded' : ''}`}>
                  {props.row.hasChildren && props.gridData.iconColumnIndex === undefined && <></>}
                </TableCell>
              )}
              {props.gridData?.columns.length &&
                props.gridData?.columns.map((col, colIndex) => {
                  if (props.row.isWholeRowContent) return [];
                  return confirmIfNotMerged(props.row[col.field + '_colSpan']) && renderTable(col, colIndex, item);
                })}
            </TableRow>
          ))
        ) : (
          <TableRow key={`Grid_Row_Child_${props.rowIndex}`}>
            <TableCell colSpan={props.gridData.columns.length + 1} className="wp-row-child-style">
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {props.gridData.childContent(props.row)}
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};

export default WpGridRow;
