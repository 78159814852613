import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        d="M5,0a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,0Zm.625,7.5a.625.625,0,0,1-1.25,0v-2a.625.625,0,0,1,1.25,0ZM5,4A1,1,0,1,1,6,3,1,1,0,0,1,5,4Z"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0}
      />
    </>
  ),
};

export default icon;
