import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0517498 0.278485C0.136026 0.107945 0.309775 0 0.500003 0H9.5C9.69023 0 9.86398 0.107945 9.94826 0.278485C10.0325 0.449026 10.0127 0.652616 9.89718 0.803725L6.75 4.91927V7.75C6.75 7.91316 6.6704 8.06605 6.53673 8.15962L4.03673 9.90962C3.884 10.0165 3.68446 10.0296 3.5191 9.94349C3.35373 9.85739 3.25 9.68644 3.25 9.5V4.91927L0.102824 0.803725C-0.0127295 0.652616 -0.0325267 0.449026 0.0517498 0.278485ZM1.5118 1L4.14718 4.44627C4.21387 4.53348 4.25 4.64022 4.25 4.75V8.53967L5.75 7.48967V4.75C5.75 4.64022 5.78614 4.53348 5.85282 4.44627L8.48821 1H1.5118Z"
      />
    </>
  ),
};

export default icon;
