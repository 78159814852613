import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { wpSearchBoxStyles } from './wpSearchBoxStyles';
import { debounce } from 'utility';
import clsx from 'clsx';
import WpIcon from 'components/wp-icon';
import searchOutline from 'components/wp-icon/icons/search-outline';
import CircularProgress from '@material-ui/core/CircularProgress';

const WpSearchBox = forwardRef<
  any,
  {
    searchString?: string;
    onChange: (searchString: string) => void;
    placeholder?: string;
    debounceTime?: number;
    isStatic?: boolean;
    minCharsStartSearch?: number;
  }
>(function WpSearchBox(props, ref) {
  const [value, setValue] = useState<string | undefined>('' || props?.searchString);

  const [loader, setLoader] = useState(false);
  const classes = wpSearchBoxStyles();
  const debounceDelay = props.debounceTime || 400;

  const { minCharsStartSearch = 3 } = props;

  useImperativeHandle(ref, () => ({
    setValue(value: string) {
      setValue(value);
    },
  }));

  const debouncedFunction = useCallback(
    debounce((value: string) => {
      setLoader(false);
      props.onChange?.(value);
    }, debounceDelay),
    []
  );

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (minCharsStartSearch) {
      if (minCharsStartSearch <= event.target.value.length || event.target.value.length === 0) {
        setLoader(true);
        debouncedFunction(event.target.value);
      }
    } else if (!props.isStatic) {
      debouncedFunction(event.target.value);
    } else if (!!props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <div className={clsx(classes.wpSearchBox, ' expanded')}>
      <div className="wpsb-wrapper">
        <div className="wpsb-search-icon">
          {loader ? <CircularProgress size={14} /> : <WpIcon size={14} color="brown" svgIcon={searchOutline} />}
        </div>
        <input
          type="text"
          className="wpsb-input"
          value={value}
          onChange={onValueChange}
          placeholder={props.placeholder || ''}
        />
      </div>
    </div>
  );
});

export default WpSearchBox;
