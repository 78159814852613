import { createStyles, makeStyles } from '@material-ui/core/styles';

export const wpMenuStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      display: 'flex',
      flexDirection: 'row',
    },
    wpMenu: {
      color: theme.palette.common.white,
      '& .wp-menu-single-btn': {
        margin: 0,
        '&:hover': {
          textDecoration: 'none',
        },
        '& .arrow-down-label': {
          marginRight: theme.spacing(1),
        },
      },
      '& .textIcon': {
        fontSize: 16,
      },
      display: 'inline-block',
      '&.disabled': {
        pointerEvents: 'none',
        opacity: 0.4,
      },
      '& .wp-menu-btn': {
        padding: theme.spacing(0.5, 1),
        textTransform: 'none',

        '&.MuiButton-root': {
          margin: 0,
        },
        '&.part1': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          minWidth: 'auto',
          marginRight: 0,
        },
        '&.part2': {
          ...theme.tag.p1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          minWidth: 'auto',

          borderLeft: `1px solid ${theme.palette.common.white}`,
        },
        '&.MuiButton-outlined': {
          borderColor: theme.palette.primary.main,
          color: theme.palette.primary.main,

          '&.part1': {
            borderRight: 0,
          },

          '&.part2 ': {
            borderLeft: '1px solid',
          },
        },
        '&.MuiButton-text': {
          color: theme.palette.link.main,
          padding: theme.spacing(0.25, 0.5),
          paddingTop: 0,

          '&.part2': {
            color: theme.palette.link.disabled,
          },
        },
      },
    },
    wpMenuDropdown: {
      marginTop: theme.spacing(0.5),
      '& .MuiMenuItem-root.disabled': {
        pointerEvents: 'none',
        opacity: 0.4,
      },
    },
  })
);
