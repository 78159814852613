import { createStyles, makeStyles } from '@material-ui/core/styles';

export const filterTypeStyles = makeStyles((theme) =>
  createStyles({
    wpFbdDropdown: {
      boxSizing: 'border-box',
      width: '270px',
      padding: theme.spacing(1),

      '& .wp-filter-btn': {
        ...theme.tag.p1,
        width: '100%',
        margin: 0,
        padding: theme.spacing(0.75, 1),
        color: theme.palette.common.black,
        border: '1px solid' + theme.palette.common.lightbrown,
        alignItems: 'start',
        justifyContent: 'start',
        boxShadow: 'none',

        '&:hover': {
          border: '1px solid' + theme.palette.primary.main,
        },
      },
      '& .wpfbd-dropdown-arrow': {
        position: 'absolute',
        right: 10,
        color: theme.palette.common.black,
        top: 8,
        width: 12,
        height: 'auto',
      },
      '& .Mui-disabled': {
        opacity: 0.5,
      },
      '& .MuiButton-outlined:hover': {
        backgroundColor: 'inherit',
      },
    },
    wpFbdDropdownLookMenu: {
      '& .MuiPaper-root': {
        width: '254px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        border: '1px solid' + theme.palette.common.lightbrown,
        marginTop: theme.spacing(1),
        maxHeight: '150px',
      },
      '&.isSingleSelectMode': {
        '& .MuiPaper-root': {
          paddingLeft: 0,
        },
        '& .MuiList-root': {
          paddingBottom: 0,
        },
        '& .wpfbd-dropdown-options': {
          paddingTop: 0,
        },
      },
      '& .wpfbd-dropdown-options': {
        display: 'flex',
        flexDirection: 'column',

        '& .wpfbd-dropdown-option-single': {
          padding: theme.spacing(),
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        '& .wpfbd-dropdown-option': {
          '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start',
            overflow: 'hidden',
          },
          '& .MuiFormControlLabel-label': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },
    dateContainer: {
      padding: theme.spacing(1),
      width: 232,
    },
  })
);
