import { createStyles, makeStyles } from '@material-ui/core/styles';

export const wpFilterWidgetStyles = makeStyles((theme) =>
  createStyles({
    wpFilterBtn: {
      '&.MuiButton-outlined': {
        border: `1px solid ${theme.palette.common.lightbrown}`,
        color: theme.palette.common.black,
      },
      height: 34,
    },
    iconMargin: {
      marginRight: '5px',
    },
    wpFilterDropdown: {
      padding: theme.spacing(2),

      '& .MuiPaper-root': {
        marginTop: '4px',
      },
      '& .MuiMenu-paper': {
        borderRadius: '4px',
        border: '1px solid ' + theme.palette.common.lightbrown,
      },
      '& .MuiMenu-list': {
        maxWidth: '1008px',
      },
      '& .MuiList-padding': {
        paddingTop: '0px',
        paddingBottom: '0px',
        boxSizing: 'border-box',
      },
      '& .wpfbd-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .wpfbd-view-disabled': {
          padding: theme.spacing(2),
        },

        '& .wpfbd-close-icon': {
          padding: theme.spacing(2),
          cursor: 'pointer',
        },
      },

      '& .wpfbd-footer': {
        paddingTop: ({ height }: { height: number }) => height,
      },
      '& .wpfbd-body': {
        padding: theme.spacing(0, 1),
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        alignItems: 'flex-end',

        '& .MuiButton-outlined': {
          marginLeft: 0,
          marginTop: 0,
        },
      },

      '& .wpfbd-left-section': {
        display: 'inline-block',
      },

      '& .wpfbd-float-right': {
        float: 'right',
      },

      '& .wp-no-filter': {
        maxWidth: '578px',
        padding: '25px 76px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: theme.typography?.fontFamily,
        boxSizing: 'border-box',
        textAlign: 'center',
        '& svg': {
          height: '105px',
          width: '154px',
        },
      },
    },
  })
);
