import React, { Fragment, ReactNode } from 'react';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import WpToolTip, { WpToolTipEnum } from '../wp-tooltip/wpToolTip';
import { Controller, DeepMap, FieldError, useFormContext, useWatch } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import WpIcon from 'components/wp-icon';
import checkSquareSolid from 'components/wp-icon/icons/check-square-solid';
import WpTypography from 'components/wp-typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.error.main,
      },
      '& .MuiRadio-root, & .MuiCheckbox-root': {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    fieldLabel: {
      ...theme.tag.p1Bold,
    },
    errorState: {
      '&.MuiRadio-root': {
        color: theme.palette.error.main,
      },
      '&.MuiCheckbox-root': {
        color: theme.palette.error.main,
      },
    },
    formControl: {
      marginLeft: theme.spacing(-1),
      '& .MuiButtonBase-root': {
        padding: theme.spacing(0.5, 1),
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.common.black,
      },
    },
    tooltipDisplay: {
      display: 'inline-block',
    },
    labelContainer: {
      display: 'flex',
    },
    disabled: {
      opacity: '.26',
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
      backgroundColor: theme.palette.common.white,
      '&.error': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
      },

      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
    checkedIcon: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
      background: theme.palette.primary.disabled,
      '&:before': {
        display: 'block',
        width: 10,
        height: 10,
        content: '""',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        margin: 3,
        boxSizing: 'border-box',
      },
    },
    checkIcon: {
      borderRadius: '2px',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
      backgroundColor: theme.palette.common.white,
      '&.error': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
      },
      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
  })
);

export enum RadioCheckTypeEnum {
  notDefined = 0,
  radioButton,
  checkbox,
}

type FieldKeyValue = string | number | Array<string | number>;

type DataParams = {
  label: React.ReactNode;
  value: string;
  disabled?: boolean;
  hoverMessage?: string;
  infoMessage?: React.ReactNode;
  afterLabel?: React.ReactNode;
};

type HookParams = { value: any; handleChange: any };

export interface IWpRadioCheckNew {
  callBackOnChange?: (event: any) => void;
  elementType?: RadioCheckTypeEnum;
  title?: string | ReactNode;
  id?: number;
  name?: any;
  dataList?: Record<any, any>[];
  rules?: any;
  displayKey?: string;
  displayValue?: string;
  otherProps?: Record<any, any>;
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: FieldKeyValue;
  disabled?: boolean;
  controlledOnchange?: (onchange: any, event: any) => void;
  className?: any;
  fixedToolTip?: boolean;
  description?: ReactNode;
}

export const WpRadioCheckNew = ({
  name,
  disabled,
  title,
  elementType,
  dataList,
  callBackOnChange,
  controlledOnchange,
  defaultValue,
  rules,
  errorobj,
  className,
  fixedToolTip,
  description,
}: IWpRadioCheckNew) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const formValue: any = useWatch({ name, control });

  const standardizeValue = (value: any) => {
    return isNaN(value) ? value : Number(value);
  };

  const handleChangeForCheckBox = (event: any, onChange: any) => {
    const temp = [...(formValue || [])];
    const value = standardizeValue(event.target.value);

    if (event.target.checked) temp.push(value);
    else {
      if (temp.includes(value)) {
        const index = temp.findIndex((item) => item === value);
        if (index !== -1) temp.splice(index, 1);
      }
    }
    callBackOnChange?.(event);
    onChange(temp);
  };

  let errorMessage = '';
  let isError = false;
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  React.useEffect(() => {
    if (elementType === RadioCheckTypeEnum.checkbox && Array.isArray(defaultValue) && defaultValue?.length) {
      setValue(name, defaultValue);
    }
  }, []);

  const renderCheckRadio = (dataParams: DataParams, { handleChange }: HookParams) => {
    const { value, disabled } = dataParams;
    const className = clsx({ [classes.disabled]: disabled, [classes.errorState]: isError });
    if (elementType == RadioCheckTypeEnum.checkbox)
      return (
        <Checkbox
          color="primary"
          disableRipple
          icon={<span className={clsx(classes.checkIcon, { ['error']: isError })} />}
          checkedIcon={<WpIcon svgIcon={checkSquareSolid} color="primary" />}
          checked={formValue?.includes(standardizeValue(value)) || false}
          className={className}
          disabled={disabled}
          onChange={(e) => handleChangeForCheckBox(e, handleChange)}
        />
      );
    return (
      <Radio
        color="primary"
        disableRipple
        disabled={disabled}
        className={className}
        icon={<span className={clsx(classes.icon, { ['error']: isError })} />}
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      />
    );
  };

  const renderFormControl = (params: DataParams, hookParams: HookParams) => {
    const { hoverMessage, infoMessage, afterLabel } = params;
    const controlLabel = (
      <FormControlLabel
        value={params.value.toString()}
        className={classes.formControl}
        control={renderCheckRadio(params, hookParams)}
        label={params.label}
      />
    );
    return (
      <Fragment key={`wp-checkradio__${params.value}`}>
        <div className={classes.labelContainer}>
          {hoverMessage ? (
            <WpToolTip
              placement="bottom-start"
              title={hoverMessage || ''}
              tooltype={fixedToolTip ? WpToolTipEnum.fixed : WpToolTipEnum.custom}
              arrow
              isTrackPosition={!fixedToolTip}
            >
              {controlLabel}
            </WpToolTip>
          ) : (
            controlLabel
          )}
          {afterLabel}
        </div>

        {infoMessage}
      </Fragment>
    );
  };

  const getControlledValue = (value: any) => {
    switch (elementType) {
      case RadioCheckTypeEnum.radioButton:
        return value?.toString() || '';
      default:
        return '';
    }
  };

  if (!dataList?.length) {
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography className={classes.fieldLabel}> No Data </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid className={clsx(classes.root, className)}>
      {title && <Typography className={classes.fieldLabel}> {title} </Typography>}
      {description && <WpTypography>{description}</WpTypography>}
      <Controller
        name={name}
        rules={rules}
        control={control}
        defaultValue={elementType !== RadioCheckTypeEnum.checkbox ? defaultValue : ''}
        render={({ value, onChange, ...other }) => {
          return (
            <FormControl disabled={disabled}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={getControlledValue(value)}
                onChange={(event) => {
                  if (controlledOnchange) {
                    controlledOnchange(onChange, event);
                  } else {
                    onChange(event);
                    callBackOnChange?.(event);
                  }
                }}
                {...other}
              >
                {dataList.map((item: DataParams) => renderFormControl(item, { value, handleChange: onChange }))}
              </RadioGroup>
              <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Grid>
  );
};
