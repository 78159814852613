import React, { useEffect, useState, useRef, UIEvent, useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import WpGridRow from './wp-grid-row/wpGridRow';
import WpGridHeaderCell from './wp-header-cell/wpHeaderCell';
import { NothingCreatedIcon } from 'assets/nothingCreatedIcon';
import { IWpGridColumn, IWpGridProps, SoftConfigType, __IWpGridStickyColumnsDataGroup } from './wpGrid.interface';
import { WpGridColumnSortDirectionEnum, WpGridStickyDirectionEnum } from './wpGrid.enum';
import { wpGridStyles } from './wpGridStyles';
import clsx from 'clsx';
import { cellMinHeight } from './constants';
import WpToolTip from 'components/wp-tooltip';
import WpTypography from 'components/wp-typography';

const WpGrid: React.FC<IWpGridProps> = (props) => {
  const classes = wpGridStyles({});
  const tableReference = useRef<HTMLTableElement>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<SoftConfigType>({});
  const [stickyColumnsData, setStickyColumnsData] = useState<__IWpGridStickyColumnsDataGroup>({});
  const distanceBottomRef = useRef(0);
  const [singleRowHeight, setSingleRowHeight] = useState(0);
  const maxRowLimit = props.maxRowLimit || 10;
  const tableDisabled = props.tableDisabled || false;
  const hasInfiniteScroll = props.hasInfiniteScroll || true;
  function handlePageChange(_event: unknown, page: number) {
    setPage(page);
    if (_event) updateRowsData();
    props.events?.onPageUpdate?.(page);
  }

  function handleRowsPerPageChange(newRowsPerPage: number) {
    const currentStartItemIndex = rowsPerPage * page;
    const newPage = currentStartItemIndex ? parseInt(String(currentStartItemIndex / newRowsPerPage)) : 0;
    setRowsPerPage(newRowsPerPage);
    props.events?.onRowsPerPageUpdate?.(newRowsPerPage);
    handlePageChange(null, newPage);
  }

  function updateRowsData(isRowDataUpdate?: boolean) {
    if (props.serverSidePaging) {
      if (!isRowDataUpdate) setFilteredRows(props.rows);
    } else {
      const startPos = page * rowsPerPage;
      const tempFilteredRows =
        hasInfiniteScroll || props.events?.onReachedEnd
          ? props.rows
          : props?.showPagination
          ? props.rows.slice(startPos, startPos + rowsPerPage)
          : props.rows.slice(0, startPos + rowsPerPage);

      setFilteredRows(tempFilteredRows);
    }
  }

  function getColumnsWithSortingEnabled(
    currentSortColumn: IWpGridColumn,
    currentSortConfig: SoftConfigType
  ): IWpGridColumn[] {
    const sortedColumns = Object.keys(currentSortConfig).filter((key) =>
      [WpGridColumnSortDirectionEnum.ASC, WpGridColumnSortDirectionEnum.DESC].includes(currentSortConfig[key])
    );

    return props.columns
      .filter((column) => sortedColumns.includes(column.field))
      .map((column) => ({
        ...column,
        currentSortColumn: currentSortColumn.field,
        sortDirection: currentSortConfig[column.field],
      }));
  }
  function handleSortRequest(currentSortColumn: IWpGridColumn, currentSortConfig: SoftConfigType) {
    props.events?.onColumnSorting?.(getColumnsWithSortingEnabled(currentSortColumn, currentSortConfig));
  }

  useEffect(() => {
    updateRowsData(true);
  }, [props.rows]);

  useEffect(() => {
    handleRowsPerPageChange(props.rowsPerPage || 10);
  }, [props.rowsPerPage]);

  useEffect(() => {
    handlePageChange(true, props.page || 0);
  }, [props.page]);

  useEffect(() => {
    updateRowsData();
  }, [rowsPerPage, page]);

  useEffect(() => {
    let leftWidthSum = '';
    let rightWidthSum = '';
    const tempData: __IWpGridStickyColumnsDataGroup = {};
    /** For left sticky columns */
    for (let index = 0, len = props.columns.length; index < len; index++) {
      const col = props.columns[index];
      if (col.sticky && col.sticky === WpGridStickyDirectionEnum.LEFT) {
        tempData[index] = {
          isLeft: true,
          width: col.width || '140px',
          leftPosition: leftWidthSum ? `calc(${leftWidthSum} + 16px)` : '0px', //added 16px to account for padding
          rightPosition: 'auto',
        };
        if (leftWidthSum) leftWidthSum += ' + ';
        leftWidthSum += col.width || '140px';
      }
    }
    /** For right sticky columns */
    for (let index = props.columns.length - 1; index >= 0; index--) {
      const col = props.columns[index];
      if (col.sticky && col.sticky === WpGridStickyDirectionEnum.RIGHT) {
        tempData[index] = {
          isLeft: false,
          width: col.width || '140px',
          leftPosition: 'auto',
          rightPosition: rightWidthSum ? `calc(${rightWidthSum})` : '0px',
        };
        if (rightWidthSum) rightWidthSum += ' + ';
        rightWidthSum += col.width || '140px';
      }
    }
    setStickyColumnsData(tempData);
  }, [props.columns]);

  // scroll debounce
  const scrollTimeOut = useRef<ReturnType<typeof setTimeout>>();

  const startDebounceScrollTimer = useCallback(() => {
    clearTimeout(scrollTimeOut.current);

    scrollTimeOut.current = setTimeout(() => {
      scrollTimeOut.current = undefined;
    }, props.scrollDebounceTime || 700);
  }, []);

  useEffect(() => {
    return () => clearTimeout(scrollTimeOut.current);
  }, []);

  // infinity scroll
  const scrollListener = (e: UIEvent<HTMLElement>) => {
    const table = e.currentTarget;
    if (scrollTimeOut.current || !table || props?.showPagination) return;

    const { scrollHeight, clientHeight, scrollTop } = table;

    const bottom = scrollHeight - clientHeight;
    const bottomOffset = distanceBottomRef.current || Math.round((bottom / 100) * 20);
    if (!distanceBottomRef.current) {
      distanceBottomRef.current = bottomOffset;
    }

    const isNeedUpdate = scrollTop > bottom - bottomOffset;

    if (isNeedUpdate) {
      props.events?.onReachedEnd?.();
      startDebounceScrollTimer();
    }
  };

  /*
  Below codes takes the height of the single individual rows.
  */
  useEffect(() => {
    const elements = tableReference.current?.querySelectorAll('.wp-grid-table-row');
    // Take the row with the least height for storing height of single row as all rows maybe of different heights
    let smallestRowHeight = Infinity;
    elements?.forEach((row) => {
      if (row && row.clientHeight) {
        if (row.clientHeight < smallestRowHeight) smallestRowHeight = row.clientHeight;
      }
    });

    setSingleRowHeight(Number.isFinite(smallestRowHeight) ? smallestRowHeight : cellMinHeight);
  }, [filteredRows]);

  /*
  Scroll => minHeight : if no. of rows is less than 10 : single row height * no.of rows
  Scroll => minHeight : if no. of rows is greater than 10 : single row height * 10
  Scroll => maxHeight: single row height * 10
  Pagination => minHeight : no.of rows per page * single row height + 50 [50 is just a spacing between table body
  and the bottom pagination label]
  
  If the component is in infinite scroll mode set min height to the full height of the content,
  this combined with reduced max height ensures that scroll bar is always visible in this case
  */
  const getMinHeight = () => {
    if (props.noHeightRestrictions) return 'auto';

    if (hasInfiniteScroll && props?.rows.length > maxRowLimit) return 'auto';
    switch (props?.showPagination) {
      case true:
        return rowsPerPage * singleRowHeight + 50;
      default:
        return props?.rows.length < maxRowLimit ? singleRowHeight * props?.rows.length : singleRowHeight * maxRowLimit;
    }
  };

  const getMaxHeight = () => {
    if (props.noHeightRestrictions) return 'none';
    // Since when in infinite scroll mode we load more data on scroll reaching the bottom,
    // its important that scrollbar is always visible, decreasing the max height a little does that
    if (hasInfiniteScroll && props?.rows.length > maxRowLimit) return singleRowHeight * (maxRowLimit - 0.25);
    else return props?.showPagination ? '' : singleRowHeight * maxRowLimit;
  };

  const getOverflowY = () => {
    if (props.noHeightRestrictions) return 'auto';
    // Show a scrollbar by default when infinite scroll is on
    // Introduced a new property because
    // using "showPagination" prop will not suffice as we have tables which don't have both infinite scroll and pagination
    // EG: "Small Table With Safely Sized Columns", "Small Table With Sized Columns Forced To Expand", etc. (See stories)
    if (hasInfiniteScroll && props?.rows.length > maxRowLimit) return 'scroll';

    return props?.rows.length < maxRowLimit ? 'visible' : 'auto';
  };

  return (
    <div className={classes.wpGridWrapper}>
      <TableContainer
        component={Paper}
        elevation={0}
        style={{
          minHeight: getMinHeight(),
          maxHeight: getMaxHeight(),
          overflowY: getOverflowY(),
        }}
        onScroll={scrollListener}
        ref={tableReference}
      >
        {tableDisabled && (
          <WpToolTip title={props.tooltipTextDisabledTable || 'disabled'}>
            <div className={classes.masked}></div>
          </WpToolTip>
        )}
        <Table
          className={clsx('wp-grid', { 'wp-grid-hover': props?.disableHoverStyles })}
          stickyHeader={!props?.showPagination}
        >
          <TableHead>
            {props.hasSubCol && (
              <TableRow>
                {props.columns.map((value) => {
                  if (value.mergedColumn) {
                    return (
                      <TableCell
                        key={`key_${value.mergedColumn.title}`}
                        colSpan={value.mergedColumn.colSpan}
                        align="center"
                      >
                        {value.mergedColumn.title}
                      </TableCell>
                    );
                  }
                  return '';
                })}
              </TableRow>
            )}
            <TableRow>
              {props.childContent && props.iconColumnIndex === undefined && (
                <WpGridHeaderCell
                  column={{ title: '', field: '' }}
                  setSortConfig={setSortConfig}
                  sortConfig={sortConfig}
                  disabled={tableDisabled}
                  resetSortState={props.resetSortState}
                  onSortRequest={() => {
                    /** */
                  }}
                />
              )}
              {props.columns.map((col, index) => (
                <WpGridHeaderCell
                  key={`Grid_Header_Cell_${index}`}
                  disabled={tableDisabled}
                  setSortConfig={setSortConfig}
                  sortConfig={sortConfig}
                  resetSortState={props.resetSortState}
                  column={col}
                  onSortRequest={handleSortRequest}
                  stickyColumnData={stickyColumnsData[index]}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(!filteredRows || filteredRows.length === 0) && (
              <TableRow className="wp-grid-table-row">
                <TableCell
                  colSpan={props.columns.length + (props.childContent && props.iconColumnIndex === undefined ? 1 : 0)}
                  className="wpg-no-data-watermark"
                >
                  <div className={classes.placeholder}>
                    {props.emptyPlaceholder || (
                      <>
                        {props.showPlaceholderImg && <NothingCreatedIcon />}
                        <WpTypography>Nothing to be seen here, yet.</WpTypography>
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {filteredRows.map((row, rowIndex) => (
              <WpGridRow
                key={props.keyExtractor ? props.keyExtractor(row) : `Grid_Row_${rowIndex}`}
                keyExtractor={props.keyExtractor}
                row={row}
                disabled={tableDisabled}
                gridData={props}
                rowIndex={rowIndex}
                stickyColumnDataGroup={stickyColumnsData}
                hasExpandedGridTable={props?.hasExpandedGridTable}
                hasDefaultExpandedChildContext={props?.hasDefaultExpandedChildContext}
              />
            ))}
          </TableBody>
          {props.footerRows && props.footerRows.length > 0 && (
            <TableFooter
              style={
                props.stickyFooter
                  ? {
                      position: 'sticky',
                      bottom: 0,
                    }
                  : undefined
              }
            >
              {props.footerRows.map((row, rowIndex) => (
                <WpGridRow
                  isFooter
                  disabled={tableDisabled}
                  key={props.keyExtractor ? props.keyExtractor(row) : `Grid_Footer_Row_${rowIndex}`}
                  keyExtractor={props.keyExtractor}
                  row={row}
                  gridData={props}
                  rowIndex={rowIndex}
                  stickyColumnDataGroup={stickyColumnsData}
                />
              ))}
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      {props.showPagination && (
        <TablePagination
          labelRowsPerPage={props.paginationLabel && props.paginationLabel + ':'}
          rowsPerPageOptions={props.rowsPerPageOptions || [5, 10, 25]}
          component="div"
          count={props.totalCount || props.rows.length || 0}
          rowsPerPage={rowsPerPage || 10}
          page={page || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={(event) => handleRowsPerPageChange(parseInt(event.target.value))}
        />
      )}
    </div>
  );
};

export default WpGrid;
