import React from 'react';
import WpBackButton from 'components/wp-backbutton';
import WpTypography from 'components/wp-typography';
import WpTabs, { IWpTabsProps } from 'components/wp-tabs/wpTabs';
import { IWpStepperProps, WpStepper } from 'components/wp-stepper/wpStepper';
import { wpHeaderStyles } from './wpHeaderStyles';

export interface WpHeaderProps {
  title: string;
  description?: string;
  backButtonTitle?: string;
  tabsProps?: IWpTabsProps;
  stepperProps?: IWpStepperProps;
  onBackAction?: () => void;
}

const WpHeader = ({ title, description, backButtonTitle, tabsProps, stepperProps, onBackAction }: WpHeaderProps) => {
  const classes = wpHeaderStyles({ tabsProps, stepperProps, backButtonTitle });

  return (
    <div className={classes.container}>
      {backButtonTitle && onBackAction && (
        <div className={classes.backBtn}>
          <WpBackButton onBackAction={onBackAction}>
            <WpTypography variant="p1Bold">{backButtonTitle}</WpTypography>
          </WpBackButton>
        </div>
      )}
      {!!stepperProps && (
        <div className={classes.stepper}>
          <WpStepper props={stepperProps} />
        </div>
      )}
      <WpTypography variant="h1">{title}</WpTypography>
      {description && <WpTypography className={classes.description}>{description}</WpTypography>}
      {!!tabsProps && (
        <div className={classes.tabs}>
          <WpTabs {...tabsProps} />
        </div>
      )}
    </div>
  );
};
export default WpHeader;
