import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import StyledMenu from '../filter-styled-menu/filterStyledMenu';
import WpButton from '../../wp-button';
import WpTypography from '../../wp-typography';
import { WpCheckbox } from '../../wp-checkbox';
import { IWpFilterWidgetDropdownOption, IWpFilterWidgetDropdownType } from '../wpFilterWidget.interface';
import { filterTypeStyles } from './filterTypeStyles';
import clsx from 'clsx';
import WpIcon from 'components/wp-icon';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';
import caretUpSolid from 'components/wp-icon/icons/caret-up-solid';

type WpFilterWidgetFilterTypeProps = {
  filterType: IWpFilterWidgetDropdownType;
  hideSelectAllGlobal: boolean;
  setHeight: (value: string | number, selectedId?: number) => void;
  index: number;
  changeTempOption: (index: number, option: IWpFilterWidgetDropdownOption[]) => void;
};

const WpFilterWidgetFilterType = ({
  changeTempOption,
  filterType,
  hideSelectAllGlobal,
  index,
  setHeight,
}: WpFilterWidgetFilterTypeProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [optionsList, setOptionsList] = useState(filterType.filterItemList);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = useState(0);
  const dropdownOptionsRef = useRef<HTMLDivElement | null>(null);
  const classes = filterTypeStyles();
  const { isSingleSelectMode, hideSelectAllOption } = filterType;
  const filterTypeName = isSingleSelectMode
    ? optionsList.find((option) => option.isSelected)?.name || filterType.name
    : filterType.name;

  const openDropdown = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const closeDropdown = () => {
    setAnchorEl(null);
  };

  function onFilterAllSelectionChange(checked: boolean) {
    setIsAllSelected(checked);
    setOptionsList((prev) => {
      const updatedOptions = prev.map((item) => {
        return { ...item, isSelected: checked };
      });
      changeTempOption(index, updatedOptions);
      return updatedOptions;
    });
  }

  function onFilterOptionChange(optionIndex: number, checked: boolean) {
    setOptionsList((prev) => {
      const updatedOptions = prev.map((item, index) => {
        if (optionIndex === index) return { ...item, isSelected: checked };
        return { ...item };
      });
      changeTempOption(index, updatedOptions);
      return updatedOptions;
    });
  }

  function onFilterOptionSelect(optionIndex: number) {
    setOptionsList((prev) => {
      if (prev[optionIndex].isSelected) {
        closeDropdown();
        return prev;
      }
      const updatedOptions = prev.map((item, index) => ({
        ...item,
        isSelected: optionIndex === index,
      }));
      closeDropdown();
      changeTempOption(index, updatedOptions);
      return updatedOptions;
    });
  }

  function updateSelectAllStatus() {
    let allOptionsSelected = true;
    optionsList.forEach((item) => {
      if (!item.isSelected) allOptionsSelected = false;
    });
    if (isAllSelected !== allOptionsSelected) {
      setIsAllSelected(allOptionsSelected);
    }
  }

  useEffect(() => {
    setOptionsList(JSON.parse(JSON.stringify(filterType.filterItemList)));
  }, [filterType]);

  useEffect(() => {
    if (!isSingleSelectMode) updateSelectAllStatus();
  }, [isSingleSelectMode, optionsList]);

  useEffect(() => {
    if (anchorEl) {
      setHeight(dropdownOptionsRef.current?.clientHeight || 0, selectedId);
    } else {
      setHeight(0);
    }
  }, [anchorEl]);

  return (
    <div className={classes.wpFbdDropdown}>
      <WpButton
        variant="outlined"
        color="default"
        onClick={(event: React.MouseEvent<HTMLElement>) => openDropdown(event, filterType.id)}
        className="wp-filter-btn"
        aria-controls={`wp-filter-dropdown-type-${filterType.name}`}
        aria-haspopup="true"
        disabled={filterType.disabled}
      >
        {filterTypeName}
        {!isSingleSelectMode && ` (${optionsList.filter((option) => option.isSelected).length})`}
        <div className="wpfbd-dropdown-arrow">
          <WpIcon svgIcon={anchorEl ? caretUpSolid : caretDownSolid} color="brown" size={12} />
        </div>
      </WpButton>
      <StyledMenu
        id={`wp-filter-dropdown-type-${filterType.name}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => closeDropdown()}
        keepMounted
        className={clsx(classes.wpFbdDropdownLookMenu, { isSingleSelectMode })}
      >
        <div ref={dropdownOptionsRef} className="wpfbd-dropdown-options" id="wpfbd-dropdown-options">
          {!isSingleSelectMode && !hideSelectAllGlobal && !hideSelectAllOption && (
            <MenuItem>
              <WpCheckbox
                label="Select all"
                value={isAllSelected}
                onChange={(_event, checked) => onFilterAllSelectionChange(checked)}
              />
            </MenuItem>
          )}
          {optionsList.map((option, optionIndex) => (
            <MenuItem
              key={`Filter_Widget_Option_${optionIndex}`}
              className={isSingleSelectMode ? 'wpfbd-dropdown-option-single' : 'wpfbd-dropdown-option'}
            >
              {!isSingleSelectMode && (
                <WpCheckbox
                  label={option.name}
                  value={option.isSelected}
                  onChange={(_event, checked) => onFilterOptionChange(optionIndex, checked)}
                />
              )}
              {isSingleSelectMode && (
                <Box width="100%" onClick={() => onFilterOptionSelect(optionIndex)}>
                  <WpTypography>{option.name}</WpTypography>
                </Box>
              )}
            </MenuItem>
          ))}
        </div>
      </StyledMenu>
    </div>
  );
};

export default WpFilterWidgetFilterType;
