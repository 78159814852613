import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const wpLinkStyles = makeStyles<Theme>((theme) =>
  createStyles({
    wpLink: {
      // these styles apply to all variants
      ...theme.tag?.p1Bold,

      color: theme.palette.link.main,
      display: 'inline-block',
      width: 'fit-content',
      padding: '0 2px',
      cursor: 'pointer',
      borderRadius: '6px',

      '&:not(.disabled):hover': {
        textDecoration: 'underline',
        backgroundColor: 'inherit',
      },
      '&:not(.disabled).Mui-focusVisible': {
        outline: '1px solid' + theme.palette.link.main,
        outlineOffset: '1px',
        textDecoration: 'underline',
        backgroundColor: 'inherit',
      },
      '&:not(.disabled):active': {
        outline: 'none',
        backgroundColor: theme.palette.link.light,
      },
      '&.disabled': {
        color: theme.palette.link.disabled,
        textDecoration: 'none',
        '& svg *': {
          fill: theme.palette.link.disabled,
        },
      },
    },
    comboButton: {
      margin: 0,
      minWidth: 'fit-content',
      boxSizing: 'border-box',
      borderRadius: '6px',

      '&:not(.disabled):hover > span': {
        textDecoration: 'underline',
      },
    },
    wpLinkButton: {
      minWidth: 'fit-content',
      boxSizing: 'border-box',

      '&:not(.disabled):hover > span': {
        textDecoration: 'underline',
      },
      lineHeight: '1.3em',
    },
    wpLinkButtonWithGutter: {
      margin: theme.spacing(1, 2),
    },
    wpLinkButtonWithoutGutter: {
      margin: 0,
    },
    linkSpace: {
      marginRight: '1px',
    },
    sortDownIcon: {
      margin: 0,
      padding: 0,
      minWidth: 0,

      '& svg': {
        margin: theme.spacing(0, 0.5),
      },
    },
    comboWrapper: {
      position: 'relative',
      display: 'flex',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
  })
);
