import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { disabledThemeColor } from '../constants';

export const wpHeaderCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    wpHeaderCell: {
      ...theme.tag.p1Bold,
      backgroundColor: theme.palette.common.white,
      '&.sortable': {
        cursor: 'pointer',
        '& .wpg-sort-icon': {
          ...theme.tag.p1,
          marginLeft: theme.spacing(0.5),
        },
      },
    },
    disabled: {
      backgroundColor: disabledThemeColor,
    },
  })
);
