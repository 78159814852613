const DownloadFile = (documentId: number, url: string) => {
  if (!documentId) return;
  window.open(url + documentId, '_blank');
};

const FormatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const GetDocumentAsBlob = (name: string, data: string, contentType: string) => {
  if (data) {
    const fileBlob = convertBase64ToBlob(data, contentType) as Blob;
    if (!fileBlob) return;
    const fileBlobUrl = URL.createObjectURL(fileBlob as Blob);

    const isEdge = navigator.userAgent.match(/Edge/g);
    const isIE = navigator.userAgent.match(/.NET/g); // IE 11+
    const isOldIE = navigator.userAgent.match(/MSIE/g);

    if (isIE || isOldIE || isEdge) {
      //window.navigator..msSaveBlob(fileBlob, name);
      console.log(name);
    } else {
      setTimeout(() => {
        window.open(fileBlobUrl, '_blank');
      }, 100);
    }
  }
};

function convertBase64ToBlob(base64Data: string, contentType: string) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);

  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  let blob = null;

  // Older browsers may not support Blob, so if we get an exception, try other ways of creating a blob
  try {
    blob = new Blob(byteArrays, {
      type: contentType,
    });
  } catch (exception) {
    // TypeError: try other ways of getting the blob object on older browsers.
    //window.BlobBuilder = window.MSBlobBuilder;

    if (exception.name == 'TypeError') {
      console.log('donwload not supported in old browser');
      // const bb = new BlobBuilder();
      // bb.append(byteArrays);
      // blob = bb.getBlob(contentType);
    } else if (exception.name == 'InvalidStateError') {
      // InvalidStateError
      blob = new window.Blob(byteArrays, {
        type: contentType,
      });
    } else {
      // blob constructor unsupported entirely
      throw new Error(
        `The JavaScript Blob object is not supported in the browser you are using, download is unavailable for older/unsupported browsers or an exception occured`
      );
    }
  }

  return blob;
}

export { DownloadFile, FormatBytes, GetDocumentAsBlob, convertBase64ToBlob };
