import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path d="M6.28904 1.47098C6.39085 1.23796 6.72138 1.23796 6.82319 1.47098L7.69025 3.45557C7.71965 3.52285 7.77336 3.57656 7.84064 3.60596L9.82523 4.47302C10.0583 4.57483 10.0583 4.90537 9.82523 5.00718L7.84064 5.87424C7.77336 5.90363 7.71965 5.95734 7.69025 6.02463L6.82319 8.00921C6.72138 8.24224 6.39085 8.24224 6.28904 8.00921L5.42198 6.02463C5.39258 5.95734 5.33887 5.90363 5.27159 5.87424L3.287 5.00718C3.05397 4.90537 3.05397 4.57483 3.287 4.47302L5.27159 3.60596C5.33887 3.57656 5.39258 3.52285 5.42198 3.45557L6.28904 1.47098Z" />
      <path d="M3.66961 0.0771355C3.71454 -0.0257118 3.86043 -0.0257119 3.90536 0.0771354L4.28804 0.953045C4.30102 0.982742 4.32472 1.00645 4.35442 1.01942L5.23033 1.4021C5.33317 1.44704 5.33317 1.59292 5.23033 1.63786L4.35442 2.02054C4.32472 2.03351 4.30102 2.05722 4.28804 2.08691L3.90536 2.96282C3.86043 3.06567 3.71454 3.06567 3.66961 2.96282L3.28692 2.08691C3.27395 2.05722 3.25024 2.03351 3.22055 2.02054L2.34464 1.63786C2.24179 1.59292 2.24179 1.44704 2.34464 1.4021L3.22055 1.01942C3.25024 1.00645 3.27395 0.982742 3.28692 0.953045L3.66961 0.0771355Z" />
      <path d="M1.88618 5.06724C1.94663 4.92888 2.14288 4.92888 2.20333 5.06724L2.71814 6.24556C2.73559 6.28551 2.76748 6.3174 2.80743 6.33485L3.98575 6.84966C4.1241 6.9101 4.1241 7.10636 3.98575 7.1668L2.80743 7.68161C2.76748 7.69906 2.73559 7.73095 2.71814 7.7709L2.20333 8.94922C2.14288 9.08758 1.94663 9.08758 1.88618 8.94922L1.37138 7.7709C1.35393 7.73095 1.32204 7.69906 1.28209 7.68161L0.103767 7.1668C-0.0345888 7.10636 -0.034589 6.9101 0.103767 6.84966L1.28209 6.33485C1.32204 6.3174 1.35393 6.28551 1.37138 6.24556L1.88618 5.06724Z" />
    </>
  ),
};

export default icon;
