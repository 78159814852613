import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField/TextField';
import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { debounce, get } from '../../utility';
import useStrictKeyPress from 'hooks/useStrictKeyPress';
import WpIcon from 'components/wp-icon';
import infoCircleSolid from 'components/wp-icon/icons/info-circle-solid';
import WpToolTip, { WpToolTipEnum } from 'components/wp-tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface IWpTextFieldProps extends Partial<BaseTextFieldProps> {
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  name: string;
  maxLength?: number;
  enableDebounce?: boolean;
  onChange?: any;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement> | HTMLTextAreaElement) => void;
  inputProps?: any;
  pattern?: string;
  cellvalue?: string;
  handleBlur?: any;
  handleOnFocus?: any;
  onBlur?: any;
  InputProps?: any;
  showHelperText?: boolean;
  strictPattern?: RegExp;
  isBankField?: boolean;
  controlledHandleChange?: (onChange: any, value: any) => void;
  tooltip?: string;
  autofillOff?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      display: 'inline-block',
    },
    fillLabel: {
      '&:before': {
        display: 'inline-block',
        content: 'attr(data-text)',
      },
    },
  })
);

export const FormInput = React.forwardRef(function FormInput(props: IWpTextFieldProps, ref) {
  const debounceDelay = 500;
  const nameForAutofillOff = 'field-' + props.name.split('').reverse().join('') + `-${Date.now()}`;
  const {
    name,
    label,
    required,
    errorobj,
    maxLength = 256,
    helperText,
    onChange,
    enableDebounce = true,
    handleOnFocus,
    showHelperText = true,
    pattern,
    strictPattern,
    isBankField,
    controlledHandleChange,
  } = props;

  const id = props.autofillOff
    ? props.id
      ? props.id.split('').reverse().join('')
      : `input-${nameForAutofillOff}`
    : props.id
    ? props.id
    : `input-${name}`;

  const { onInputChange, ...otherProps } = props;

  let isError = false;
  let errorMessage = '';
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  const classes = useStyles();

  const nestedErrorMessage = get(errorobj || {}, name);
  if (!isError && nestedErrorMessage) {
    isError = true;
    errorMessage = nestedErrorMessage.message;
  }

  const { keyPressEvent, handlePasteEvent } = useStrictKeyPress();
  /**
   * memoized callback function returns a debounced instance
   */
  const debouncedFunction = React.useCallback(
    debounce((event: any) => {
      if (onInputChange) onInputChange(event);
    }, debounceDelay),
    [onInputChange, debounceDelay]
  );
  /**
   * function to handle the input change when user
   * types on the text field
   */
  const handleInputChange = (event: any) => {
    if (!controlledHandleChange) {
      if (enableDebounce) debouncedFunction(event);
      else if (onInputChange) onInputChange(event);
      onChange(event);
    } else {
      controlledHandleChange(onChange, event);
    }
  };

  /**
   * to restrict user to type in more number than the maxlength in case of number field
   */
  const handleMaxLengthExceed = (e: any) => {
    if (props.type === 'number') {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxLength);
    }
  };

  return (
    <React.Fragment>
      {label &&
        (props.tooltip ? (
          <WpToolTip tooltype={WpToolTipEnum.custom} title={props.tooltip} arrow placement="bottom">
            <span className={classes.tooltip}>
              {props.autofillOff && typeof label === 'string' ? (
                <InputLabel htmlFor={id} className={classes.fillLabel} data-text={label}>
                  <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
                </InputLabel>
              ) : (
                <InputLabel htmlFor={id}>
                  {label}
                  <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
                </InputLabel>
              )}
            </span>
          </WpToolTip>
        ) : props.autofillOff && typeof label === 'string' ? (
          <InputLabel htmlFor={id} className={classes.fillLabel} data-text={label}></InputLabel>
        ) : (
          <InputLabel htmlFor={id}>{label}</InputLabel>
        ))}

      <TextField
        {...otherProps}
        id={id}
        name={props.autofillOff ? nameForAutofillOff : props.name}
        onChange={(event) => {
          handleInputChange(event);
        }}
        onPaste={(event) => {
          if (isBankField || strictPattern) {
            handlePasteEvent({ event, name, isBankField });
          }
        }}
        onKeyPress={(event: any) => {
          keyPressEvent({ event, strictPattern, isBankField });
        }}
        onBlur={(event) => {
          props.onBlur(event);
          props.handleBlur?.();
        }}
        fullWidth
        InputLabelProps={{
          required: required || false,
        }}
        onInput={handleMaxLengthExceed}
        error={isError}
        helperText={
          props.autofillOff
            ? showHelperText && (errorMessage || helperText)
              ? ' '
              : ''
            : showHelperText
            ? errorMessage || helperText
            : ''
        }
        variant="outlined"
        label={null}
        inputRef={ref}
        FormHelperTextProps={
          showHelperText && props.autofillOff
            ? {
                className: classes.fillLabel,
                ['data-text' as string]: errorMessage || helperText || '',
              }
            : undefined
        }
        inputProps={{
          onFocus: handleOnFocus,
          pattern,
          maxLength,
          ['data-n' as string]: props.name,
          ['data-q' as string]: props.id,
        }}
      />
    </React.Fragment>
  );
});

export const WpTextField = React.forwardRef(function WpTextField(props: IWpTextFieldProps, ref) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      defaultValue={props.cellvalue || ''}
      name={props.name}
      innerRef={ref}
      render={(field) => <FormInput {...field} {...props} />}
    />
  );
});
