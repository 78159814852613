import React, { useEffect, useMemo } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import { debounce, get } from '../../utility';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import WpToolTip, { WpToolTipEnum } from '../wp-tooltip';
import WpIcon from 'components/wp-icon';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';
import infoCircleSolid from 'components/wp-icon/icons/info-circle-solid';
import useStrictKeyPress from 'hooks/useStrictKeyPress';
import Popper from '@material-ui/core/Popper';
import Chip from '@material-ui/core/Chip';
import { WpLink } from 'components/wp-link';

export interface IWpAutocompleteProps extends Partial<BaseTextFieldProps> {
  name: string;
  multiple: boolean;
  dataSource: Array<Record<any, any>>;
  callBackOnChange?: (selectedValue: string | []) => void;
  callBackOnInputChange?: (event: any, value: string, reason: string) => void;
  customOnChange?: (value: any, onChange: (value: any) => void) => void;
  callBackOnFocus?: () => void;
  displayKey?: string;
  displayValue?: string;
  returnValue?: string;
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  freesolo?: boolean;
  normalisedDisplayValue?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isStatic?: boolean;
  isWithChip?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
  inputProps?: any;
  keydownEvent?: any;
  minCharsStartSearch?: number;
  placeholder?: string;
  getOptionDisabled?: (option: any) => boolean;
  getChipClassName?: (option: Record<string, any>) => string;
  listboxProps?: {
    listboxClasses?: string;
    [key: string]: any;
  };
  disableFilterLogic?: boolean;
  callBackOnBlur?: () => void;
  isTooltipError?: boolean;
  tooltip?: string;
  autofillOff?: boolean;
  showFieldError?: boolean;
  debounceDelay?: number;
  strictPattern?: RegExp;
  callBackAction?: () => void;
  isActionLink?: boolean;
  linkText?: string;
}

const useStyles = makeStyles((theme: any) =>
  createStyles({
    clearSection: {
      '&.has-scroll': {
        paddingRight: '12px',
      },
      height: '26px',
      backgroundColor: theme.palette.common.lightbrown,
      borderRadius: '4px',
      opacity: '60%',
      ...theme.tag.p1,
      padding: '0 !important',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customPopper: {
      border: `1px solid ${theme.palette.common.lightbrown}`,
      boxShadow: 'none',
      borderRadius: 6,
      zIndex: 1300,
      '& .MuiAutocomplete-paper': {
        boxShadow: 'none',
        margin: 0,
      },
      '& .MuiAutocomplete-noOptions': {
        color: theme.palette.common.black,
      },
    },
    customListBox: {
      boxShadow: 'none',
      padding: 0,
      margin: 0,
      maxHeight: '200px',
      overflowY: 'scroll',
      '& .MuiAutocomplete-option': {
        minHeight: 'auto',
        padding: theme.spacing(0.5, 1),
      },
    },
    tooltip: {
      display: 'inline-block',
    },
    fillLabel: {
      '&:before': {
        display: 'inline-block',
        content: 'attr(data-text)',
      },
    },
    disableClearableClasses: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 10px)',
      },
    },
  })
);

const WpPopper = (props: any) => {
  const classes = useStyles();

  return <Popper {...props} className={classes.customPopper} />;
};

const WpAutoComplete = React.forwardRef(function WpAutoComplete(props: IWpAutocompleteProps, ref) {
  const classes = useStyles();
  const { control } = useFormContext();
  const nameForMarkup = props.name.split('').reverse().join('') + `-${Date.now()}`;
  const {
    name,
    //multiple,
    value = null,
    required,
    label,
    dataSource,
    callBackOnChange,
    callBackOnInputChange,
    customOnChange,
    callBackOnFocus,
    disableClearable = false,
    displayKey = 'key',
    displayValue = 'value',
    returnValue = null,
    errorobj,
    isLoading = false,
    freesolo = false,
    normalisedDisplayValue = false,
    isClearable = false,
    isStatic = true,
    disabled = false,
    isWithChip = false,
    keydownEvent,
    minCharsStartSearch = 3,
    placeholder,
    getOptionDisabled,
    getChipClassName,
    listboxProps,
    callBackOnBlur,
    disableFilterLogic,
    isTooltipError,
    showFieldError = false,
    debounceDelay = 500,
    strictPattern,
    callBackAction,
    isActionLink = false,
    linkText = '',
  } = props;
  const { keyPressEvent, handlePasteEvent } = useStrictKeyPress();

  const id = props.autofillOff
    ? props.id
      ? props.id.split('').reverse().join('')
      : `wp-autocomplete-${nameForMarkup}`
    : props.id
    ? props.id
    : `wp-autocomplete-${name}`;

  const freesoloTextLimit = 256;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Array<any>>([]);
  // const [selectedValue, setselectedValue] = React.useState<any>(undefined);
  const [freeSoloValue, setFreeSoloValue] = React.useState<undefined | string>(undefined);
  const [isInit, setInit] = React.useState(false);

  let isError = false;
  let errorMessage = '';
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }

  const nestedErrorMessage = get(errorobj || {}, name);
  if (!isError && nestedErrorMessage) {
    isError = true;
    errorMessage = nestedErrorMessage.message;
  }

  const currentValue = control.getValues(name);
  const addClearOption: boolean = useMemo(() => {
    return isClearable && !options.some((listItem) => listItem.type === 'clear');
  }, [isClearable, options]);
  /** Logic to add the */

  useEffect(() => {
    const list = dataSource;
    if (addClearOption) list.unshift({ type: 'clear' }); //adding clear option
    if (freesolo && list.length && !isInit) {
      if (list.findIndex((item) => item[displayKey] === currentValue) === -1) {
        setInit(true);
        // pushing value to support free solo
        list.push({
          [displayKey]: currentValue,
          [displayValue]: currentValue,
          freesolo: true,
        });
      }
    }
    if (isActionLink && !list.some((option) => option.type === 'actionLink')) {
      list.push({ type: 'actionLink', [displayKey]: -1, [displayValue]: linkText });
    }
    setOptions(Object.keys(list).map((key) => list[key as any]) as []);
  }, [dataSource, currentValue]);

  /**
   * function called when user selects an option in callback
   * @param event Target event
   */
  const handleChange = (event: any) => {
    if (!!callBackOnChange) {
      callBackOnChange(event as never);
    }
  };

  /**
   * Function handles the events that we need when user types on input field
   * @param event Dom event
   * @param value user typed textfield value
   * @param reason kind of action user takes up
   * @returns void
   */
  const onChangeInputChangeActions = (event: any, value: string, reason: string) => {
    if (reason === 'reset') return;
    if (!!callBackOnInputChange) {
      callBackOnInputChange(event, value, reason);
    }
  };

  /**
   * function returns a debounced instance
   */
  const debouncedFunction = debounce((event: any, value: string, reason: string) => {
    onChangeInputChangeActions(event, value, reason);
  }, debounceDelay);
  /**
   * memoized callback function to handle the input change when user
   * types on the text field
   */
  const handleInputChange = React.useCallback((event: any, value: string, reason: string) => {
    if (freesolo && value.length <= freesoloTextLimit) setFreeSoloValue(value);
    if (!isStatic) {
      debouncedFunction(event, value, reason);
    } else {
      onChangeInputChangeActions(event, value, reason);
    }
  }, []);

  /**
   * Function to check for the selected option
   * @param option option Object
   * @param value selected value
   * @returns boolean if the options and value are the same
   */
  const handleOutput = (option: any, value: any) => {
    return option[displayKey] === value[displayKey];
  };

  /**
   * Function has a custom filter to show the clear button
   * @param param
   * @returns
   */
  const filterLogic = ({ options, inputValue }: { options: any; inputValue: string }) => {
    const list = options.filter((item: any) => {
      if (item.freesolo) return false; // filtering out free solo values
      if (item[displayValue]) {
        // Normalize the displayValue value by trimming and replacing multiple spaces with a single space
        return normalisedDisplayValue
          ? item[displayValue].trim().replace(/\s+/g, ' ').toLowerCase().includes(inputValue.toLowerCase())
          : item[displayValue].toLowerCase().includes(inputValue.toLowerCase());
      }
    });
    if (list.length) {
      if (addClearOption) list.unshift({ type: 'clear' });
      if (isActionLink && !list.some((option: any) => option.type === 'actionLink')) {
        list.push({ type: 'actionLink', [displayKey]: -1, [displayValue]: linkText });
      }
    }
    return list;
  };

  /**
   * Function to return the actual value to the autocomplete component,
   * cases: supports if user gives the primary key/ id  of the options from API or if the user give the object as a whole
   * @param hookValue String or Object
   * @returns the actual value which like {[displayKey]:'' , [displayValue]:''}
   */
  const getFormStateValue = (hookValue: any) => {
    if (hookValue !== undefined && hookValue !== null) {
      if (hookValue[displayKey] === undefined)
        return (
          options.filter((item) => item[displayKey] === hookValue)[0] || {
            [displayKey]: hookValue,
            [displayValue]: hookValue,
          }
        );
      else return hookValue;
    }
    return '';
  };

  const renderNoOption = () => {
    return <div className="nooption">No search results</div>;
  };

  const renderController = () => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={value}
        rules={{ required: required }}
        render={({ onChange, onBlur, value: hookValue }: any) => {
          const valueUpdated = getFormStateValue(hookValue);
          const { listboxClasses, ...otherListboxProps } = listboxProps || {};
          return (
            <>
              <Autocomplete
                popupIcon={<WpIcon svgIcon={caretDownSolid} color="brown" size={12} />}
                ListboxProps={{
                  className: clsx(classes.customListBox, listboxClasses),
                  ...otherListboxProps,
                }}
                PopperComponent={WpPopper}
                freeSolo={freesolo}
                ref={ref}
                disabled={disabled}
                disableClearable={disableClearable}
                getOptionDisabled={getOptionDisabled}
                noOptionsText={renderNoOption()}
                onChange={({}, dataValue: any) => {
                  let _data = dataValue;
                  if (!_data || !_data.type || _data.type !== 'clear') {
                    if (_data && !_data[displayKey] && freesolo) {
                      // logic to support free solo
                      _data = { [displayKey]: _data, [displayValue]: _data };
                    } else if (_data && _data[displayKey] === undefined && !freesolo) {
                      // logic to remove the data when user is not in free solo mode
                      _data = null;
                    }
                    if (returnValue && _data) {
                      // logic if return value is specified by the user
                      _data = _data[returnValue];
                    }

                    if (customOnChange) {
                      customOnChange(_data, onChange);
                    } else {
                      onChange(_data);
                      handleChange(_data);
                    }
                  }
                }}
                onFocus={() => callBackOnFocus && callBackOnFocus()}
                onBlur={(e) => {
                  // to save freesolo value when user clicks outside
                  if (freesolo) {
                    let _data: Record<string, string | undefined> | undefined | string = {
                      [displayKey]: freeSoloValue,
                      [displayValue]: freeSoloValue,
                    };
                    if (returnValue && _data) {
                      _data = _data[returnValue];
                    }
                    onChange(_data);
                  }
                  // normal blur function
                  onBlur(e);
                  callBackOnBlur?.();
                }}
                inputValue={freeSoloValue} // controlled search input value
                value={valueUpdated}
                fullWidth={true}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onKeyDownCapture={keydownEvent}
                renderOption={(option: any) => {
                  if (isClearable && option.type === 'clear') {
                    return (
                      <>
                        <span
                          className={classes.clearSection}
                          onClick={() => {
                            control.setValue(name, null);
                          }}
                        >
                          Clear selection
                        </span>
                      </>
                    );
                  }
                  if (isActionLink && option.type === 'actionLink') {
                    return <WpLink onClick={callBackAction}>{linkText}</WpLink>;
                  }

                  return (
                    <React.Fragment>
                      <span>{option[displayValue]}</span>
                      {option?.status && isWithChip && (
                        <Chip
                          className={getChipClassName?.(option)}
                          label={option.status}
                          color="primary"
                          size="small"
                        />
                      )}
                    </React.Fragment>
                  );
                }}
                onInputChange={(event: any, value: string, reason: string) => {
                  if (event || !freeSoloValue?.length) {
                    handleInputChange(event, value, reason);
                    if (freesolo) {
                      onChange(value);
                    }
                  }
                }}
                getOptionSelected={(option, value) => handleOutput(option, value)}
                getOptionLabel={(option: any) => {
                  return option[displayValue] ? option[displayValue] : valueUpdated[displayValue] || '';
                }}
                filterOptions={(options, { inputValue }) => {
                  if (!disableFilterLogic && minCharsStartSearch <= inputValue.length) {
                    return filterLogic({ options, inputValue });
                  } else {
                    return options;
                  }
                }}
                options={options}
                loading={isLoading}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      error={isError}
                      required={required}
                      label={null}
                      variant="outlined"
                      placeholder={placeholder}
                      InputProps={{
                        ...params.InputProps,
                        ...props.inputProps,
                        endAdornment: (
                          <div className={clsx(disableClearable && classes.disableClearableClasses)}>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </div>
                        ),
                      }}
                      onPaste={(event) => {
                        if (strictPattern) {
                          handlePasteEvent({ event, name, strictPattern });
                        }
                      }}
                      onKeyPress={(event: any) => {
                        if (strictPattern) {
                          keyPressEvent({ event, strictPattern });
                        }
                      }}
                    />
                  );
                }}
              />
              {isError && showFieldError && <FormHelperText error>{errorMessage}</FormHelperText>}
            </>
          );
        }}
      />
    );
  };

  return (
    <>
      {label &&
        (props.tooltip ? (
          <WpToolTip tooltype={WpToolTipEnum.custom} title={props.tooltip} arrow placement="bottom">
            <span className={classes.tooltip}>
              {props.autofillOff && typeof label === 'string' ? (
                <InputLabel htmlFor={id} className={classes.fillLabel} data-text={label}>
                  <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
                </InputLabel>
              ) : (
                <InputLabel htmlFor={id}>
                  {label} <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
                </InputLabel>
              )}
            </span>
          </WpToolTip>
        ) : props.autofillOff && typeof label === 'string' ? (
          <InputLabel htmlFor={id} className={classes.fillLabel} data-text={label}></InputLabel>
        ) : (
          <InputLabel htmlFor={id}>{label}</InputLabel>
        ))}
      {isTooltipError ? (
        <WpToolTip
          arrow
          aria-multiline={true}
          placement="bottom"
          title={isTooltipError ? errorMessage ?? '' : ''}
          tooltype={WpToolTipEnum.custom}
          isTrackPosition={false}
        >
          <span>
            {renderController()}
            {props.autofillOff ? (
              isError && !isTooltipError && <FormHelperText error>{errorMessage}</FormHelperText>
            ) : (
              <FormHelperText className={classes.fillLabel} data-text={errorMessage} error />
            )}
          </span>
        </WpToolTip>
      ) : (
        renderController()
      )}
    </>
  );
});

export default WpAutoComplete;
