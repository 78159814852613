export enum AppEnvironments {
  PROD = '/prod',
  STG = '/stg',
  UAT = '/uat',
  BETA = '/beta',
  DEV2 = '/dev2',
  DEV3 = '/dev3',
  QA2 = '/qa2',
  DEV = '/dev',
  QA = '/qa',
  Fin1 = '/finance1',
  Fin2 = '/finance2',
  Infra1 = '/infra1'
}

// As per official documentation of Launch darkly they claim clientId are safe to expose:
//https://docs.launchdarkly.com/sdk/client-side/react/react-web
//Never embed a server-side SDK key into a client-side application
//Client-side IDs are not secret and you can expose them in your client-side code without risk. However, never embed a server-side SDK key into a client-side application.
export const LdCLient = {
  [AppEnvironments.STG]: '64ffa1258d3d1d120bb4d7ec',
  [AppEnvironments.BETA]: '64ffa11d42dd8c121f8729b9',
  [AppEnvironments.UAT]: '64ffa112985fe9125d4090e0',
  [AppEnvironments.Fin1]: '64ffa100985fe9125d4090b4',
  [AppEnvironments.Fin2]: '64ffa10719c2e611de5b5f57',
  [AppEnvironments.QA2]: '64ffa0eb09d34c1281e2eaed',
  [AppEnvironments.QA]: '64ffa0c419c2e611de5b5e9f',
  [AppEnvironments.DEV2]: '64ffa0b942dd8c121f8728cb',
  [AppEnvironments.DEV3]: '652f904e8eaa7c12cecf9b9f',
  [AppEnvironments.DEV]: '64ffa0ae2c30601226a6eda1',
  [AppEnvironments.PROD]: '64ff3d67212ec711d50abfa9',
  [AppEnvironments.Infra1]: '6549cb4e99c5de12ef453b92',
};
