import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles, Theme as AugmentedTheme, useTheme } from '@material-ui/core/styles';

import { alpha } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import WpIcon from 'components/wp-icon';
import WpTypography from 'components/wp-typography';
import { WpLink, IWpLinkProps } from 'components/wp-link';
import { GutterDirection, IWpIconProps } from 'components/wp-icon/wpIcon.interface';
import closeOutline from 'components/wp-icon/icons/close-outline';
import alertCircleSolid from 'components/wp-icon/icons/alert-circle-solid';
import alertTriangleSolid from 'components/wp-icon/icons/alert-triangle-solid';
import checkCircleSolid from 'components/wp-icon/icons/check-circle-solid';
import infoCircleSolid from 'components/wp-icon/icons/info-circle-solid';
import sparkleSolid from 'components/wp-icon/icons/sparkle-solid';
import employeeSolid from 'components/wp-icon/icons/employee-solid';
import calendarCheckOutline from 'components/wp-icon/icons/calendar-check-outline';
import clockOutline from 'components/wp-icon/icons/clock-outline';
import IconButton from '@material-ui/core/IconButton';

export enum WpStatementsEnum {
  action = 1,
  alerts = 2,
  general = 3,
  profile = 4,
}

export enum WpGeneralStatementsEnum {
  active = 1,
  passive = 2,
  disabled = 3,
}

export enum WpAlertStatementsEnum {
  error = 1,
  warning = 2,
}

type StatementVariants =
  | 'critical'
  | 'warning'
  | 'success'
  | 'highpriority'
  | 'lowpriority'
  | 'starred'
  | 'calendar'
  | 'clock'
  | 'customFilled'
  | 'bigHighlight';

type customFillBg = 'primary' | 'secondary';

export interface IWpStatements {
  /**
   * @deprecated No longer used. Use `variant` prop instead
   */
  type?: number;
  message?: string | ReactNode;
  customclass?: string;
  /**
   * @deprecated No longer used. Use `variant` prop instead
   */
  gentype?: number;
  /**
   * @deprecated No longer used. Use `variant` prop instead
   */
  alerttype?: number;
  variant?: StatementVariants;
  link?: IWpLinkProps;
  onClose?: any;
  gutter?: {
    spacing: number;
    direction: GutterDirection;
  };
  // ADD CHECKBOX
  iconProps?: IWpIconProps;
  customBg?: customFillBg;
  title?: string;
  noBorder?: boolean;
  centerIcon?: boolean;
}

const generalStyles = makeStyles<AugmentedTheme>((theme) =>
  createStyles({
    gencontainer: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      '& svg': {
        display: 'block',
        height: '15px',
        width: '15px !important',
      },
    },
    activecontainer: {
      borderColor: theme.palette.primary.main,
    },
    passivecontainer: {
      borderColor: theme.palette.common?.black,
      background: theme.palette.common?.white,
    },
    disabledcontainer: {
      borderColor: theme.palette.common.lightbrown,
    },
    content: { padding: '7px !important' },
    activecontent: {
      color: theme.palette.common.black,
    },
    passivecontent: {
      color: theme.palette.common?.black,
    },
    iconcontainer: {
      padding: '10px 7px',
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.primary.main, 0.4)}`,
    },
    activeiconcontainer: {
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
    passiveiconcontainer: {
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.common?.black, 0.4)}`,
      '& svg': {
        color: theme.palette.common?.black,
      },
    },
    disablediconcontainer: {
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.common.lightbrown, 0.4)}`,
      '& svg': {
        color: theme.palette.common.lightbrown,
      },
    },
  })
);

const statementStyles = makeStyles<AugmentedTheme>((theme) =>
  createStyles({
    gencontainer: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      background: theme.palette.common.white,
    },
    starred: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      borderColor: theme.palette.primary.disabled,
      background: theme.palette.primary.light,
    },
    bigHighlight: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      borderColor: theme.palette.background.highlight,
      background: theme.palette.background.paper,
      borderLeft: `${theme.spacing(2)}px solid`,
    },
    bigHTitle: {
      marginBottom: theme.spacing(1),
    },
    bigHLink: {
      marginTop: theme.spacing(1),
    },
    customBg: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
    },
    noBorder: {
      border: '0',
    },
    primaryCustomBg: {
      borderColor: theme.palette.primary.disabled,
      background: theme.palette.primary.light,
    },
    secondaryCustomBg: {
      borderColor: theme.palette.secondary.disabled,
      background: theme.palette.secondary.light,
    },
    customIcon: {
      display: 'flex',
      padding: '12px 0px 10px 7px',
    },
    centerIcon: {
      alignItems: 'center',
    },
    calendar: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      borderColor: theme.palette.secondary.disabled,
      background: theme.palette.secondary.light,
    },
    starredLeft: {
      display: 'flex',
      padding: '10px 0px 10px 7px',
    },
    left: {
      display: 'flex',
      padding: '10px 7px',
    },
    content: { padding: theme.spacing(1), flex: '1' },
    bigHighlightContent: { padding: theme.spacing(2), flex: '1' },
  })
);

const alertStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    container: {
      display: 'flex',
      height: 'auto',
      minHeight: '33px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '6px',
      overflow: 'hidden',
      '& svg': {
        display: 'block',
        height: '15px',
        width: '15px !important',
      },
      background: theme.palette.common.white,
    },
    errorcontainer: {
      borderColor: theme.palette.error.main,
    },
    warningcontainer: {
      borderColor: theme.palette.warning.main,
    },
    content: {
      color: theme.palette.common?.black,
      padding: '7px !important',
    },
    iconcontainer: {
      padding: '10px 7px',
    },
    messagecontainer: {
      display: 'inline-block',
    },
    erroriconcontainer: {
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.error.main, 0.4)}`,
      '& svg': {
        color: theme.palette.error.main,
      },
    },
    warningiconcontainer: {
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.warning.main, 0.4)}`,
      '& svg': {
        color: theme.palette.warning.main,
      },
    },
  })
);

const actionStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    container: {
      display: 'flex',
      borderRadius: '0px 4px 4px 0px',
      overflow: 'hidden',
      border: '1px solid' + theme.palette.primary.main,
      borderLeft: '2px solid' + theme.palette.primary.main,
      minHeight: '38px',
      height: 'auto',
    },
    content: {
      color: theme.palette.common.black,
      padding: '0px !important',
    },
    messagecontainer: {
      padding: '8px 16px',
    },
  })
);

const profileStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    container: {
      display: 'flex',
      borderRadius: '6px',
      overflow: 'hidden',
      border: '1px solid' + theme.palette.primary.main,
      minHeight: '33px',
      height: 'auto',
      backgroundColor: theme.palette.common.white,
    },
    content: {
      display: 'block',
      color: theme.palette.common?.black,
      padding: '8px 16px 8px 0px!important',
    },
    iconcontainer: {
      padding: '10px 7px',
      borderLeft: `${theme.spacing(1)}px solid ${alpha(theme.palette.primary.main, 0.4)}`,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  })
);

const getThemeColor = (variant: StatementVariants, theme: AugmentedTheme) => {
  switch (variant) {
    case 'critical':
      return theme.palette.error.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'success':
      return theme.palette.success.main;
    case 'lowpriority':
      return theme.palette.common.black;
    default:
      return theme.palette.common.black;
  }
};

const getLightColor = (variant: StatementVariants, theme: AugmentedTheme) => {
  switch (variant) {
    case 'critical':
      return theme.palette.error.light;
    case 'warning':
      return theme.palette.warning.light;
    case 'success':
      return theme.palette.success.light;
    case 'lowpriority':
      return theme.palette.decorative.grey.light;
    default:
      return theme.palette.decorative.grey.light;
  }
};

const getColor = (variant: StatementVariants) => {
  switch (variant) {
    case 'critical':
      return 'error';
    case 'warning':
      return 'warning';
    case 'success':
      return 'success';
    case 'lowpriority':
      return 'black';
    default:
      return 'black';
  }
};
const getIcon = (variant: StatementVariants) => {
  switch (variant) {
    case 'critical':
      return alertCircleSolid;
    case 'warning':
      return alertTriangleSolid;
    case 'success':
      return checkCircleSolid;
    case 'highpriority':
      return infoCircleSolid;
    case 'lowpriority':
      return infoCircleSolid;
    default:
      return infoCircleSolid;
  }
};

const WpStatements = React.forwardRef(function WpStatements(props: IWpStatements, ref) {
  const {
    type = WpStatementsEnum.general,
    message,
    customclass,
    gentype = WpGeneralStatementsEnum.active,
    alerttype = WpAlertStatementsEnum.error,
  } = props;
  const genclasses = generalStyles();
  const statementClasses = statementStyles();
  const alertclasses = alertStyles();
  const actionclasses = actionStyles();
  const profileclasses = profileStyles();
  const theme = useTheme();

  return (
    <>
      {props.variant &&
        (props.variant === 'starred' ? (
          <div
            data-ref={ref}
            className={statementClasses.starred}
            style={{
              marginLeft:
                props.gutter?.direction === 'left' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginRight:
                props.gutter?.direction === 'right' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginTop:
                props.gutter?.direction === 'top' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginBottom:
                props.gutter?.direction === 'bottom' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
            }}
          >
            <div className={statementClasses.starredLeft}>
              <WpIcon svgIcon={sparkleSolid} color="yellow" />
            </div>
            <div className={statementClasses.content}>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} />}
            </div>
            {props.onClose && (
              <div>
                <IconButton onClick={props.onClose}>
                  <WpIcon size={12} svgIcon={closeOutline} color="black" />
                </IconButton>
              </div>
            )}
          </div>
        ) : props.variant === 'bigHighlight' ? (
          <div
            data-ref={ref}
            className={statementClasses.bigHighlight}
            style={{
              marginLeft:
                props.gutter?.direction === 'left' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginRight:
                props.gutter?.direction === 'right' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginTop:
                props.gutter?.direction === 'top' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginBottom:
                props.gutter?.direction === 'bottom' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
            }}
          >
            <div className={statementClasses.bigHighlightContent}>
              <WpTypography variant="h4" component="p" className={statementClasses.bigHTitle}>
                {props.title}
              </WpTypography>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} className={statementClasses.bigHLink} />}
            </div>
            {props.onClose && (
              <div>
                <IconButton onClick={props.onClose}>
                  <WpIcon size={12} svgIcon={closeOutline} color="black" />
                </IconButton>
              </div>
            )}
          </div>
        ) : props.variant === 'customFilled' ? (
          <div
            data-ref={ref}
            className={clsx(statementClasses.customBg, {
              [statementClasses.primaryCustomBg]: props.customBg === 'primary',
              [statementClasses.secondaryCustomBg]: props.customBg === 'secondary',
              [statementClasses.noBorder]: props.noBorder === true,
            })}
            style={{
              marginLeft:
                props.gutter?.direction === 'left' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginRight:
                props.gutter?.direction === 'right' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginTop:
                props.gutter?.direction === 'top' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
              marginBottom:
                props.gutter?.direction === 'bottom' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter?.spacing)
                  : 0,
            }}
          >
            <div
              className={clsx(statementClasses.customIcon, {
                [statementClasses.centerIcon]: props.centerIcon === true,
              })}
            >
              <WpIcon {...props.iconProps} />
            </div>
            <div className={statementClasses.content}>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} />}
            </div>
            {props.onClose && (
              <div>
                <IconButton onClick={props.onClose}>
                  <WpIcon size={12} svgIcon={closeOutline} color="black" />
                </IconButton>
              </div>
            )}
          </div>
        ) : props.variant === 'calendar' ? (
          <div
            data-ref={ref}
            className={statementClasses.calendar}
            style={{
              marginLeft:
                props.gutter?.direction === 'left' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props?.gutter?.spacing)
                  : 0,
              marginRight:
                props.gutter?.direction === 'right' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props?.gutter?.spacing)
                  : 0,
              marginTop:
                props.gutter?.direction === 'top' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props?.gutter?.spacing)
                  : 0,
              marginBottom:
                props.gutter?.direction === 'bottom' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props?.gutter?.spacing)
                  : 0,
            }}
          >
            <div className={statementClasses.starredLeft}>
              <WpIcon svgIcon={calendarCheckOutline} color="secondaryDisabled" />
            </div>
            <div className={statementClasses.content}>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} />}
            </div>
            {props.onClose && (
              <div>
                <IconButton onClick={props.onClose}>
                  <WpIcon size={12} svgIcon={closeOutline} color="black" />
                </IconButton>
              </div>
            )}
          </div>
        ) : props.variant === 'clock' ? (
          <div data-ref={ref} className={statementClasses.calendar}>
            <div className={statementClasses.starredLeft}>
              <WpIcon svgIcon={clockOutline} color="secondaryDisabled" />
            </div>
            <div className={statementClasses.content}>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} />}
            </div>
            {props.onClose && (
              <div>
                <IconButton onClick={props.onClose}>
                  <WpIcon size={12} svgIcon={closeOutline} color="black" />
                </IconButton>
              </div>
            )}
          </div>
        ) : (
          <div
            data-ref={ref}
            className={statementClasses.gencontainer}
            style={{
              borderColor: getThemeColor(props.variant ?? 'lowpriority', theme),
              marginLeft:
                props.gutter?.direction === 'left' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props?.gutter?.spacing)
                  : 0,
              marginRight:
                props.gutter?.direction === 'right' ||
                props.gutter?.direction === 'x' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter.spacing)
                  : 0,
              marginTop:
                props.gutter?.direction === 'top' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter.spacing)
                  : 0,
              marginBottom:
                props.gutter?.direction === 'bottom' ||
                props.gutter?.direction === 'y' ||
                props.gutter?.direction === 'all'
                  ? theme.spacing(props.gutter.spacing)
                  : 0,
            }}
          >
            <div
              className={statementClasses.left}
              style={{
                borderLeft: `${theme.spacing(1)}px solid ${getLightColor(props.variant ?? 'lowpriority', theme)}`,
              }}
            >
              <WpIcon svgIcon={getIcon(props.variant)} color={getColor(props.variant)} />
            </div>
            <div className={statementClasses.content}>
              <WpTypography>{message}</WpTypography>
              {props.link && <WpLink {...props.link} />}
            </div>
          </div>
        ))}
      {type == WpStatementsEnum.general && !props.variant && (
        <div
          data-ref={ref}
          className={clsx(
            genclasses.gencontainer,
            {
              [genclasses.activecontainer]: gentype == WpGeneralStatementsEnum.active,
              [genclasses.passivecontainer]: gentype == WpGeneralStatementsEnum.passive,
              [genclasses.disabledcontainer]: gentype == WpGeneralStatementsEnum.disabled,
            },
            customclass
          )}
        >
          <div
            className={clsx(genclasses.iconcontainer, {
              [genclasses.activeiconcontainer]: gentype == WpGeneralStatementsEnum.active,
              [genclasses.passiveiconcontainer]: gentype == WpGeneralStatementsEnum.passive,
              [genclasses.disablediconcontainer]: gentype == WpGeneralStatementsEnum.disabled,
            })}
          >
            <WpIcon
              svgIcon={infoCircleSolid}
              color={gentype === WpGeneralStatementsEnum.active ? 'primary' : 'black'}
            />
          </div>
          <Typography
            className={clsx(genclasses.content, {
              [genclasses.activecontent]: gentype == WpGeneralStatementsEnum.active,
              [genclasses.passivecontent]:
                gentype == WpGeneralStatementsEnum.passive || gentype == WpGeneralStatementsEnum.disabled,
            })}
          >
            {message}
          </Typography>
        </div>
      )}
      {type == WpStatementsEnum.alerts && !props.variant && (
        <div
          className={clsx(
            alertclasses.container,
            {
              [alertclasses.errorcontainer]: alerttype == WpAlertStatementsEnum.error,
              [alertclasses.warningcontainer]: alerttype == WpAlertStatementsEnum.warning,
            },
            customclass
          )}
        >
          <div
            className={clsx(genclasses.iconcontainer, {
              [alertclasses.warningiconcontainer]: alerttype == WpAlertStatementsEnum.warning,
              [alertclasses.erroriconcontainer]: alerttype == WpAlertStatementsEnum.error,
            })}
          >
            <WpIcon
              svgIcon={alerttype === WpAlertStatementsEnum.error ? alertCircleSolid : alertTriangleSolid}
              color={alerttype === WpAlertStatementsEnum.error ? 'error' : 'warning'}
            />
          </div>
          <Typography className={clsx(alertclasses.content)}>{message}</Typography>
        </div>
      )}
      {type == WpStatementsEnum.action && !props.variant && (
        <div className={clsx(actionclasses.container, customclass)}>
          <div className={actionclasses.messagecontainer}>
            <Typography className={actionclasses.content}>{message}</Typography>
          </div>
        </div>
      )}
      {type == WpStatementsEnum.profile && !props.variant && (
        <div className={profileclasses.container}>
          <div className={profileclasses.iconcontainer}>{<WpIcon svgIcon={employeeSolid} color="primary" />}</div>
          <Typography className={profileclasses.content}>{message}</Typography>
        </div>
      )}
    </>
  );
});
export default WpStatements;
