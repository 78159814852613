import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85355 0.146447C10.0488 0.341709 10.0488 0.658291 9.85355 0.85355L0.853553 9.8536C0.658291 10.0488 0.341709 10.0488 0.146447 9.8536C-0.0488156 9.65829 -0.0488156 9.34171 0.146447 9.14645L9.14645 0.146447C9.34171 -0.0488158 9.65829 -0.0488158 9.85355 0.146447Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.146447 0.146447C0.341709 -0.0488158 0.658291 -0.0488158 0.853553 0.146447L9.85355 9.14645C10.0488 9.34171 10.0488 9.65829 9.85355 9.8536C9.65829 10.0488 9.34171 10.0488 9.14645 9.8536L0.146447 0.85355C-0.0488156 0.658291 -0.0488156 0.341709 0.146447 0.146447Z"
      />
    </>
  ),
};

export default icon;
