import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        d="M.006 2.908c.018.132.072.24.175.35.16.171 4.03 4.228 4.061 4.258a1.119 1.119 0 001.516 0c.031-.03 3.9-4.086 4.061-4.258a.608.608 0 00.175-.354.629.629 0 00-.188-.543.61.61 0 00-.485-.174.602.602 0 00-.395.18c-.016.015-.905.946-1.976 2.07A782.33 782.33 0 015 6.48c-.002 0-.88-.92-1.95-2.043-1.071-1.124-1.96-2.055-1.976-2.07a.61.61 0 00-.306-.169.687.687 0 00-.352.022.657.657 0 00-.383.39c-.03.09-.04.201-.027.298z"
        clipRule="evenodd"
      />
    </>
  ),
};

export default icon;
