import WpIcon from 'components/wp-icon';
import arrowLeftOutline from 'components/wp-icon/icons/arrow-left-outline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { WpLink } from 'components/wp-link';
import React, { ReactNode } from 'react';
import clsx from 'clsx';

export interface WpBackButtonProps {
  onBackAction: () => void;
  children: string | ReactNode;
  customClass?: string;
  link?: boolean;
}
const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    text: {
      color: theme.palette.text.primary,
      display: 'flex',
    },
    link: {
      color: theme.palette.link.main,
    },
  })
);

const WpBackButton = ({ children, onBackAction, customClass, link }: WpBackButtonProps) => {
  const classes = useStyles();

  return (
    <WpLink
      isButton
      noGutter
      className={clsx(classes.text, customClass, { [classes.link]: link })}
      onClick={onBackAction}
    >
      <WpIcon svgIcon={arrowLeftOutline} color={link ? 'link' : 'black'} size={12} gutter="right" />
      {children}
    </WpLink>
  );
};

export default WpBackButton;
