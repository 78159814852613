import React, { ReactNode, useCallback, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme as AugmentedTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import WpTypography from 'components/wp-typography';
import WpButton from 'components/wp-button';
import Box from '@material-ui/core/Box';
import WpIcon from 'components/wp-icon';
import closeOutline from 'components/wp-icon/icons/close-outline';
import useWindowSize from 'utility/useWindowSize';
import { useWpMediaQuery } from 'styles/useMedia';
export interface IWpDialogProps extends Partial<DialogProps> {
  // title: keyof JSX.IntrinsicElements;
  // title: string;
  title?: any;
  description?: string;
  content?: ReactNode;
  actions?: ReactNode;
  /**
   * @deprecated No longer used.
   */
  closeicon?: boolean;
  media?: ReactNode;
  customTitleClass?: string;
  unsavedChanges?: boolean;
  unsavedStay?: any;
  unsavedLeave?: any;
}

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    mobilePaper: {
      height: '100%',
    },
    paper: {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.common.lightbrown}`,
    },
    dialogcontainer: {
      width: '100%',
      background: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
    },
    dialogOuterContainer: {
      boxShadow: 'none',
    },
    content: {
      flex: '1',
      overflowY: 'auto',
      maxHeight: `calc(100dvh - 70px)`,
    },
    mobile: {
      '& .MuiDialogActions-root': {
        background: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.common.lightbrown}`,
      },
    },
  })
);
const WpDialog = React.forwardRef(function WpDialog(props: IWpDialogProps, ref) {
  const classes = useStyles();
  const { closeicon, unsavedChanges, fullScreen = false, maxWidth = 'sm', open, ...otherProps } = props;
  const [showFullScreen, setShowFullScreen] = useState(false);

  const size = useWindowSize();

  const { isMobileScreen } = useWpMediaQuery();

  const boxRef = useCallback(
    (node: any) => {
      if (node != null) {
        const dialogHeight = node.getBoundingClientRect().height;

        console.log(dialogHeight);
        // TODO: make these numbers dynamic
        // 64 = margin of not fullscreen dialog
        // 70 = actions
        // 16 = buffer
        if (dialogHeight + 64 + 70 + 16 > size[1] && isMobileScreen) {
          setShowFullScreen(true);
        } else {
          setShowFullScreen(false);
        }
      }
    },
    [size, open]
  );

  return (
    <Dialog
      fullScreen={showFullScreen}
      open={open as boolean}
      onClose={props.onClose}
      aria-labelledby={String(props.title)}
      ref={ref}
      maxWidth={showFullScreen ? false : unsavedChanges ? 'xs' : maxWidth}
      {...otherProps}
      title={''}
      PaperProps={{
        elevation: 0,
        className: clsx(otherProps.className, classes.paper),
      }}
    >
      <Box display="flex" className={clsx({ [classes.mobilePaper]: showFullScreen })}>
        <div className={clsx(classes.dialogcontainer, { [classes.mobile]: showFullScreen })}>
          <div className={clsx({ [classes.content]: showFullScreen })}>
            <div ref={boxRef}>
              <MuiDialogTitle disableTypography>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <div>
                    <WpTypography variant="h3" className={clsx(props.customTitleClass)}>
                      {unsavedChanges ? 'Leave this page?' : props.title}
                    </WpTypography>
                    {props.description && <WpTypography>{props.description}</WpTypography>}
                  </div>
                  {closeicon ? (
                    <IconButton
                      aria-label="close"
                      onClick={(event) => {
                        props?.onClose ? props.onClose(event, 'escapeKeyDown') : null;
                      }}
                    >
                      <WpIcon size={12} svgIcon={closeOutline} color="brown" />
                    </IconButton>
                  ) : null}
                </Box>
              </MuiDialogTitle>
              <DialogContent>
                {unsavedChanges ? (
                  <WpTypography>Changes you’ve made so far will not be saved.</WpTypography>
                ) : (
                  props.content
                )}
              </DialogContent>
            </div>
          </div>
          <DialogActions>
            {unsavedChanges ? (
              <>
                <WpButton variant="outlined" onClick={props.unsavedStay}>
                  Stay on page
                </WpButton>
                <WpButton variant="contained" important onClick={props.unsavedLeave}>
                  Leave page
                </WpButton>
              </>
            ) : (
              props.actions
            )}
          </DialogActions>
        </div>
      </Box>
    </Dialog>
  );
});
export default WpDialog;
