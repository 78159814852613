import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        d="M5,0a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,0ZM7.442,4.192l-2.75,2.75a.625.625,0,0,1-.884,0l-1.25-1.25a.625.625,0,0,1,.884-.884l.808.808L6.558,3.308a.625.625,0,0,1,.884.884Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
};

export default icon;
