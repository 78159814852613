import { DecimalCharacterType, Languages, LanguageType, SeparatorType } from './index';

interface IReplace {
  value: string | number;
  language?: LanguageType;
  digitGroupSeparator: SeparatorType;
  decimalCharacter?: DecimalCharacterType;
  customPrecision?: number;
  zeroDecimals?: boolean;
  addZeros?: boolean;
}

export const precision = 2;

export const retrieveActualValue = ({ value, language, digitGroupSeparator }: IReplace) => {
  if (digitGroupSeparator) {
    const normal = value.toString().split(digitGroupSeparator).join('');
    if (language === Languages.EnglishCa || language === Languages.EnglishUs) return normal;
    return normal.toString().split(',').join('.');
  }
  return value;
};

const getDecimalNumbers = ({
  decimalNumbers,
  customPrecision,
}: {
  decimalNumbers: string | null;
  customPrecision: number;
}) => {
  const decimal = decimalNumbers;
  if (decimalNumbers && decimalNumbers.length < customPrecision && customPrecision > 1) {
    const numberOfZeros = customPrecision - decimalNumbers.length;
    const zeros = '0'.repeat(numberOfZeros);
    const resultString = decimalNumbers + zeros;
    return resultString;
  }
  return decimal;
};

export const commafy = ({
  decimalCharacter,
  digitGroupSeparator,
  value,
  customPrecision,
  zeroDecimals = false,
  addZeros = true,
}: IReplace) => {
  if ((value || zeroDecimals) && decimalCharacter) {
    const hasNegative = value.toString().includes('-');
    value = value.toString().replace('-', ''); // to handle negative numbers
    const updated = value.toString().split(decimalCharacter);
    const chars = updated[0].split('').reverse();
    const withCommas = [];
    for (let i = 1; i <= chars.length; i++) {
      withCommas.push(chars[i - 1]);
      if (i % 3 == 0 && i != chars.length) {
        withCommas.push(digitGroupSeparator);
      }
    }
    const val = withCommas.reverse().join('');

    const finalPrecision = customPrecision || precision;
    const decimalNumbers = updated[1] !== undefined ? updated[1].substring(0, finalPrecision) : null;

    const formattedDecimalNumbers = getDecimalNumbers({ decimalNumbers, customPrecision: finalPrecision });
    return `${hasNegative ? '-' : ''}${val}${
      updated[1] !== undefined
        ? `${decimalCharacter}${addZeros ? formattedDecimalNumbers : decimalNumbers}`
        : zeroDecimals || (chars.length === 1 && zeroDecimals)
        ? `${decimalCharacter}${addZeros ? '0'.repeat(finalPrecision) : '00'}`
        : ''
    }`;
  }
  return value;
};

export const convertStringToCurrency = ({
  language = Languages.EnglishUs,
  value,
  zeroDecimals,
}: {
  language?: IReplace['language'];
  value: string | number;
  zeroDecimals?: boolean;
}) => {
  let decimalCharacter: IReplace['decimalCharacter'] = '.';
  let digitGroupSeparator: IReplace['digitGroupSeparator'] = ',';
  if (language === Languages.FrenchCa) {
    decimalCharacter = ',';
    digitGroupSeparator = '.';
  }
  return commafy({ value, digitGroupSeparator, decimalCharacter, zeroDecimals });
};
