import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        d="M10,1.5A1.5,1.5,0,0,0,8.5,0h-7A1.5,1.5,0,0,0,0,1.5v7A1.5,1.5,0,0,0,1.5,10h7A1.5,1.5,0,0,0,10,8.5Zm-2.325.882a.75.75,0,0,1,.193,1.043l-2.75,4a.752.752,0,0,1-.521.319.789.789,0,0,1-.1.006.745.745,0,0,1-.488-.181l-1.75-1.5a.75.75,0,0,1,.976-1.138l.9.776a.252.252,0,0,0,.2.058.247.247,0,0,0,.174-.107l2.12-3.083A.75.75,0,0,1,7.675,2.382Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  altText: 'Square check filled in',
};

export default icon;
