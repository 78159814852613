import React, { ReactNode, useState } from 'react';

import { Controller, DeepMap, FieldError, useFormContext } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import WpIcon from 'components/wp-icon';
import checkSquareSolid from 'components/wp-icon/icons/check-square-solid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import WpTypography from 'components/wp-typography';

/* This is from the backend to defined element here only checkbox and radiobutton*/
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldLabel: {
      ...theme.tag.p1Bold,
    },
    root: {
      '& .MuiTypography-h5': {
        paddingLeft: theme.spacing(0),
      },
      '& .MuiRadio-root, & .MuiCheckbox-root': {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    errorState: {
      '&.MuiRadio-root': {
        color: theme.palette.error.main,
      },
    },
    formControl: {
      '& .MuiButtonBase-root': {
        padding: theme.spacing(0.5, 1, 0.5, 0),
      },
      '& .MuiFormControlLabel-label': {
        color: theme.palette.common.black,
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: theme.palette.common.white,

      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
    checkedIcon: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}, inset 0 -1px 0 ${theme.palette.primary.main}`,
      background: theme.palette.primary.disabled,
      '&:before': {
        display: 'block',
        width: 10,
        height: 10,
        content: '""',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        margin: 3,
        boxSizing: 'border-box',
      },
    },
    checkIcon: {
      borderRadius: '2px',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2)',
      backgroundColor: theme.palette.common.white,

      '$root.Mui-focusVisible &': {
        outline: `1px auto ${theme.palette.common.lightbrown}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        background: theme.palette.primary.disabled,
      },
      'input:disabled ~ &': {
        background: theme.palette.background.disabled,
      },
    },
  })
);

export enum RadioCheckTypeEnum {
  notDefined = 0,
  radioButton,
  checkbox,
}
type FieldKeyValue = string | number | Array<string | number>;
export interface ICheckRadioProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, newProps?: any) => void;
  callBackOnChange?: (selectedValue: FieldKeyValue) => void;
  elementType?: RadioCheckTypeEnum | any;
  title?: string | ReactNode;
  id?: number;
  name?: any;
  dataList?: Record<any, any>[];
  rules?: any;
  displayKey?: string;
  displayValue?: string;
  otherProps?: Record<any, any>;
  errorobj?: DeepMap<Record<string, any>, FieldError>;
  defaultValue?: FieldKeyValue;
  disabled?: boolean;
  className?: string;
  description?: string;
}
const WpCheckRadio: React.FC<ICheckRadioProps> = (props: ICheckRadioProps) => {
  const {
    title,
    onChange,
    callBackOnChange,
    elementType = RadioCheckTypeEnum.radioButton,
    dataList,
    defaultValue,
    errorobj,
    id,
    otherProps,
    rules,
    name: fieldName,
    displayKey = 'value',
    displayValue = 'label',
    disabled,
    className,
    description,
  } = props;
  const classes = useStyles();
  const { control } = useFormContext();
  const [fieldValue, setFieldValue] = useState<FieldKeyValue | undefined>(defaultValue);

  /* if fieldName provinded assign that name else assign WpCheckRadio with id or any unique value as id */
  const name = fieldName ? fieldName : `WpCheckRadio_${id}`;
  let errorMessage = '';
  let isError = false;
  if (errorobj && errorobj.hasOwnProperty(name)) {
    isError = true;
    errorMessage = errorobj[name].message;
  }
  const returnCheckedOrNot = (currentValue: any) => {
    const getFieldValue = fieldValue;
    return typeof getFieldValue === 'object' && getFieldValue.length
      ? { checked: getFieldValue.includes(currentValue) }
      : { checked: getFieldValue === currentValue };
  };
  const isNaN = (x: any) => {
    return x !== x;
  };
  const fieldOnChange = (e: React.ChangeEvent, newProps: any) => {
    const field = e.target as HTMLInputElement;
    let fetchValue = fieldValue;
    if (field.type === 'checkbox') {
      /* Only if checkbox we need to get the array */
      const fieldValue: any = !isNaN(Number(field[displayKey])) ? Number(field[displayKey]) : field[displayKey];
      const arrayField = fetchValue && Array.isArray(fetchValue) && fetchValue.length ? fetchValue : [];
      fetchValue = returnCheckboxClearValue(arrayField, fieldValue, field.checked);
    } else {
      /* Else Radio will accept only one single string value */
      fetchValue = !isNaN(Number(field[displayKey])) ? Number(field[displayKey]) : field[displayKey];
    }
    setFieldValue(fetchValue as never);
    if (!!callBackOnChange) {
      callBackOnChange(fetchValue as never);
    }
    return newProps.onChange(fetchValue);
  };
  const returnCheckboxClearValue = (arrayValue: Array<string | number> = [], value: string, checked = true) => {
    let newValue: Array<string | number> = arrayValue;
    if (checked && newValue.indexOf(value) === -1) {
      newValue = [...newValue, value];
    } else if (!checked && newValue.indexOf(value) !== -1) {
      newValue = newValue.filter((arrayVal) => arrayVal !== value);
    }
    return newValue;
  };
  return dataList?.length ? (
    <Grid className={clsx(classes.root, className)}>
      {title && <Typography className={classes.fieldLabel}> {title} </Typography>}
      {description && <WpTypography>{description}</WpTypography>}
      <RadioGroup>
        <FormControl error={isError}>
          {dataList.map((data, index) => {
            const { [displayKey]: key, [displayValue]: value, ...others } = data;
            return (
              <Controller
                name={name}
                value={key}
                key={index}
                control={control}
                rules={rules}
                defaultValue={!!defaultValue && !isNaN(Number(defaultValue)) ? Number(defaultValue) : defaultValue}
                render={({ value: chck_value, ...newProps }) => (
                  <FormControlLabel
                    name={name}
                    value={key}
                    disabled={disabled}
                    className={classes.formControl}
                    control={
                      elementType == RadioCheckTypeEnum.checkbox ? (
                        <Checkbox
                          color="primary"
                          disableRipple
                          icon={<span className={classes.checkIcon} />}
                          checkedIcon={<WpIcon svgIcon={checkSquareSolid} color="primary" />}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            fieldOnChange(event, newProps);
                            onChange && onChange(event, newProps);
                          }}
                          {...(others ?? {})}
                        />
                      ) : (
                        <Radio
                          // ref={radioRef}
                          color="primary"
                          disableRipple
                          className={`${isError ? classes.errorState : ''}`}
                          icon={<span className={classes.icon} />}
                          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            fieldOnChange(event, newProps);
                            onChange && onChange(event, newProps);
                          }}
                          {...(others ?? {})}
                        />
                      )
                    }
                    label={value}
                    defaultValue={chck_value}
                    {...returnCheckedOrNot(key)}
                    {...(otherProps ?? {})}
                  />
                )}
              />
            );
          })}
          <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
      </RadioGroup>
    </Grid>
  ) : (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.fieldLabel}> No Data </Typography>
      </Grid>
    </Grid>
  );
};
export default WpCheckRadio;
