import Portal from '@material-ui/core/Portal';
import clsx from 'clsx';
import WpButton from 'components/wp-button';
import WpComboButton from 'components/wp-combobutton/wpComboButton';
import { WpTypography } from 'components/wp-typography/wpTypography';
import React, { ReactElement } from 'react';
import { wpBottomNavStyles } from './wpBottomNavStyles';
import { useMediaQueryIsMobile } from '../../styles/useMedia';

interface WpBottomNavCommonProps {
  fn?: any;
  text: string;
  disabled?: boolean;
  form?: string;
  type?: string;
  component?: ReactElement;
  to?: string;
  tooltip?: string;
}

export interface WpBottomNavProps {
  /**
   * @deprecated Use `portal` prop instead.
   */
  standAlone?: boolean; //delete once gone from ui repo
  portal?: boolean;
  secondaryBtn?: WpBottomNavCommonProps;
  optBtnLeft?: WpBottomNavCommonProps & {
    variant?: 'text' | undefined;
  };
  optBtnRight?: WpBottomNavCommonProps;
  primaryBtn?: WpBottomNavCommonProps & {
    isCombo?: boolean;
    options?: any;
  };
  navText?: string | React.ReactNode;
  enableMobileView?: boolean;
  customClass?: string | undefined;
}

const WpBottomNav = (props: WpBottomNavProps) => {
  const portalContainer = document.getElementById('layout-footer-portal');
  const classes = wpBottomNavStyles();
  const { portal = true, primaryBtn, secondaryBtn, optBtnLeft, optBtnRight, navText, enableMobileView = true } = props;
  const isMobile = useMediaQueryIsMobile();

  const bottomNav = () => (isMobile && enableMobileView ? bottomNavForMobile() : bottomNavForWeb());

  const bottomNavForWeb = () => (
    <div className={clsx({ [classes.footer]: !portal }, classes.container, props.customClass)}>
      <div className={classes.navBar}>
        <div className={classes.left}>
          {secondaryBtn && (
            <WpButton
              variant="outlined"
              noGutter
              onClick={secondaryBtn.fn}
              disabled={secondaryBtn.disabled}
              form={secondaryBtn.form}
              type={secondaryBtn.type}
              tooltip={secondaryBtn.tooltip}
            >
              {secondaryBtn.text}
            </WpButton>
          )}
          {optBtnLeft && (
            <WpButton
              noGutter
              variant={optBtnLeft.variant === 'text' ? 'text' : 'outlined'}
              onClick={optBtnLeft.fn}
              disabled={optBtnLeft.disabled}
              form={optBtnLeft.form}
              type={optBtnLeft.type}
              tooltip={optBtnLeft.tooltip}
            >
              {optBtnLeft.text}
            </WpButton>
          )}
        </div>
        {navText &&
          (typeof navText === 'string' ? (
            <div>
              <WpTypography>{navText}</WpTypography>
            </div>
          ) : (
            navText
          ))}
        <div className={classes.right}>
          {optBtnRight && (
            <WpButton
              noGutter
              variant="outlined"
              onClick={optBtnRight.fn}
              disabled={optBtnRight.disabled}
              form={optBtnRight.form}
              type={optBtnRight.type}
              tooltip={optBtnRight.tooltip}
            >
              {optBtnRight.text}
            </WpButton>
          )}

          {primaryBtn && primaryBtn?.isCombo ? (
            <WpComboButton
              noGutter
              options={primaryBtn.options}
              onButtonClick={primaryBtn.fn}
              disabled={primaryBtn.disabled}
              label={primaryBtn.text}
              placement="top"
              color="primary"
            />
          ) : primaryBtn && !primaryBtn?.isCombo ? (
            <WpButton
              noGutter
              variant="contained"
              onClick={primaryBtn.fn}
              disabled={primaryBtn.disabled}
              form={primaryBtn.form}
              type={primaryBtn.type}
              tooltip={primaryBtn.tooltip}
            >
              {primaryBtn.text}
            </WpButton>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );

  const bottomNavForMobile = () => (
    <div className={clsx({ [classes.footer]: !portal })}>
      <div className={classes.mobileNavBar}>
        {secondaryBtn && (
          <WpButton
            variant="outlined"
            noGutter
            onClick={secondaryBtn.fn}
            disabled={secondaryBtn.disabled}
            form={secondaryBtn.form}
            type={secondaryBtn.type}
          >
            {secondaryBtn.text}
          </WpButton>
        )}

        {primaryBtn && !primaryBtn?.isCombo ? (
          <WpButton
            fullWidth
            noGutter
            variant="contained"
            onClick={primaryBtn.fn}
            disabled={primaryBtn.disabled}
            form={primaryBtn.form}
            type={primaryBtn.type}
            className={classes.primaryBtn}
          >
            {primaryBtn.text}
          </WpButton>
        ) : (
          ''
        )}
      </div>
    </div>
  );

  return portal ? (
    <>
      <Portal container={portalContainer}>{bottomNav()}</Portal>
    </>
  ) : (
    <>{bottomNav()}</>
  );
};

export default WpBottomNav;
