import React from 'react';
import TextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Controller, RegisterOptions, useFormContext, DeepMap, FieldError } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { commafy, precision, retrieveActualValue } from './helpers';
import { get } from 'utility/get';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import WpToolTip, { WpToolTipEnum } from '../wp-tooltip';
import WpIcon from 'components/wp-icon';
import infoCircleSolid from 'components/wp-icon/icons/info-circle-solid';
import alertCircle_outline from 'components/wp-icon/icons/alert-circle-solid';
import WpTypography from 'components/wp-typography';
import { IWpIconProps, IconColor } from 'components/wp-icon/wpIcon.interface';

export type DecimalCharacterType = ',' | '.';

export type SeparatorType = ',' | '.';

export type LanguageType = 'fr-ca' | 'en-ca' | 'en-us' | string;

export interface IWpNumberField {
  label?: React.ReactNode;
  id?: string;
  decimalCharacter?: DecimalCharacterType;
  defaultValue?: any;
  name: string;
  startSymbol?: string | React.ReactElement;
  endSymbol?: string | React.ReactElement;
  language?: LanguageType;
  errorObj: DeepMap<Record<string, any>, FieldError>;
  fieldType: 'money' | 'negative' | 'negative-money' | 'percentage' | 'custom' | 'number' | 'character-money';
  handleChange?: (onChange: (value: any) => void, value: number | string) => void;
  handleOnFocus?: () => void;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  isControlled: boolean;
  value?: any;
  enableSimpleErrorGetter?: boolean;
  disabled?: boolean;
  hideErrorMsg?: boolean;
  customPrecision?: number;
  autoFocus?: boolean;
  InputProps?: Partial<OutlinedInputProps>;
  customClasses?: any;
  blurEvent?: () => void;
  keydownEvent?: (e: any) => void;
  percentageLimit?: number;
  maxLength?: number;
  allowFirstSignMinus?: boolean;
  isNotControlledInput?: boolean;
  onChangeInput?: (value: any) => void;
  placeholder?: string;
  isTooltipError?: boolean;
  tooltip?: string;
  allowOnlyPositive?: boolean;
  changeStartAdornmentOnError?: boolean;
  startAdornmentErrorType?: IconColor;
  warningIcon?: IWpIconProps['svgIcon'];
  showWarning?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    showWarning: {
      '& .MuiInputBase-root': {
        border: `1px solid ${theme.palette.warning.main}`,
      },
    },
    textFieldBlock: {
      fontVariantNumeric: 'tabular-nums',

      '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: theme.spacing(),
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: theme.spacing(),
      },
      '& .Mui-error': {
        color: theme.palette.error.main,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        '-webkit-text-fill-color': theme.palette.text.disabled,
      },
      '& .errorIcon': {
        display: 'inline-block',
        marginBottom: theme.spacing(0.25),
        marginRight: theme.spacing(0.5),
      },
    },
    inputAlignRight: {
      '& .MuiOutlinedInput-input': {
        textAlign: 'right',
      },
    },
    tooltip: {
      display: 'inline-block',
    },
    percentMax: {
      '& .MuiOutlinedInput-input': {
        flexBasis: theme.spacing(5),
      },
    },
  })
);

export enum Languages {
  EnglishCa = 'en-ca',
  EnglishUs = 'en-us',
  FrenchCa = 'fr-ca',
}

const WpNumberField = ({
  label,
  name,
  id = `wp-numberfield-${name}`,
  defaultValue,
  startSymbol = '-',
  endSymbol = '$',
  language = Languages.EnglishUs,
  errorObj,
  fieldType,
  handleChange,
  handleOnFocus,
  rules,
  isControlled = true,
  disabled = false,
  enableSimpleErrorGetter = false,
  value,
  customPrecision,
  autoFocus = false,
  InputProps,
  customClasses,
  blurEvent,
  keydownEvent,
  hideErrorMsg = false,
  percentageLimit = 100,
  maxLength,
  allowFirstSignMinus = false,
  onChangeInput = () => undefined,
  isNotControlledInput,
  placeholder,
  isTooltipError,
  allowOnlyPositive = true,
  changeStartAdornmentOnError = false,
  startAdornmentErrorType = 'error',
  warningIcon = alertCircle_outline,
  showWarning,
  ...props
}: IWpNumberField) => {
  const error = enableSimpleErrorGetter ? errorObj?.[name] : get(errorObj || {}, name);
  const errorMessage = error?.message;
  const classes = useStyles();
  const defaultPrecision = customPrecision ?? precision;
  const { control } = useFormContext();

  const decimalCharacter = language === Languages.EnglishUs || language === Languages.EnglishCa ? '.' : ',';
  const digitGroupSeparator = language === Languages.EnglishUs || language === Languages.EnglishCa ? ',' : '.';

  const addToolTipInfoIcon = () => {
    const message = errorMessage || showWarning;
    return message && changeStartAdornmentOnError ? (
      <WpToolTip arrow aria-multiline={true} placement="bottom" title={message} tooltype={WpToolTipEnum.custom}>
        <span className="errorIcon">
          <WpTypography>
            {message && changeStartAdornmentOnError && (
              <WpIcon
                svgIcon={warningIcon}
                size={15}
                color={showWarning ? 'warning' : startAdornmentErrorType}
                gutter="left"
              />
            )}
          </WpTypography>
        </span>
      </WpToolTip>
    ) : null;
  };

  const moneyField = () => {
    if (language === Languages.EnglishUs || language === Languages.EnglishCa)
      return {
        startAdornment: (
          <InputAdornment position="start">
            {addToolTipInfoIcon()}
            <WpTypography>$</WpTypography>
          </InputAdornment>
        ),
        endAdornment: [],
      };
    return {
      startAdornment: [],
      endAdornment: (
        <InputAdornment position="end">
          <WpTypography>$</WpTypography>
        </InputAdornment>
      ),
    };
  };
  const negativeMoney = () => {
    if (language === Languages.EnglishUs || language === Languages.EnglishCa)
      return {
        startAdornment: (
          <InputAdornment position="start">
            <WpTypography>-$</WpTypography>
          </InputAdornment>
        ),
        endAdornment: [],
      };
    return {
      startAdornment: (
        <InputAdornment position="start">
          <WpTypography>-</WpTypography>
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end">
          <WpTypography>$</WpTypography>
        </InputAdornment>
      ),
    };
  };
  const renderNegativeField = () => {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <WpTypography>-</WpTypography>
        </InputAdornment>
      ),
      endAdornment: [],
    };
  };
  const renderPercentageField = () => {
    return {
      startAdornment: [],
      endAdornment: (
        <InputAdornment position="end">
          <WpTypography>%</WpTypography>
        </InputAdornment>
      ),
    };
  };
  const renderCustom = () => {
    return {
      startAdornment: !!startSymbol ? <InputAdornment position="start">{startSymbol}</InputAdornment> : null,
      endAdornment: !!endSymbol ? <InputAdornment position="end">{endSymbol}</InputAdornment> : null,
    };
  };

  const renderFieldTypes = () => {
    switch (fieldType) {
      case 'money':
        return moneyField();
      case 'negative-money':
        return negativeMoney();
      case 'negative':
        return renderNegativeField();
      case 'percentage':
        return renderPercentageField();
      case 'custom':
        return renderCustom();
      case 'character-money':
        return renderCustom();
      case 'number':
        return { startAdornment: [], endAdornment: [] };
      default:
        return moneyField();
    }
  };

  /**
   *This function add comma's and decimal characters to the number field value
   * @param currentValue String or number the current value of the number field
   */
  const addComma = (currentValue: string | number) => {
    return commafy({
      value: currentValue?.toString(),
      decimalCharacter,
      digitGroupSeparator,
      addZeros: false,
    });
  };

  const replaceZeroPrefix = (currentValue: string | number) => {
    const newValue = currentValue?.toString();
    if (!newValue) return currentValue;

    if (newValue.charAt(0) === '0' && !['', decimalCharacter].includes(newValue.charAt(1))) {
      return newValue.slice(1);
    }

    return newValue;
  };

  const replaceDollarSign = (value: string | number) => value?.toString().replace(/\$/g, '');
  const addDollarSign = (value: string | number) => (!!replaceDollarSign(value) ? `$ ${value}` : value);

  /** The actual value is now applied logic to add comma's and dots */
  const getValue = (og_value: string | number) => {
    let updatedValue = value;
    let updated_og_value = og_value;

    if (fieldType === 'character-money') {
      updatedValue = replaceDollarSign(updatedValue);
      updated_og_value = replaceDollarSign(updated_og_value);
    }

    if (language === Languages.FrenchCa) {
      updatedValue = updatedValue?.toString().split('.').join(',');
      updated_og_value = updated_og_value?.toString().split('.').join(',');
    }

    updatedValue = addComma(replaceZeroPrefix(updatedValue));
    updated_og_value = addComma(replaceZeroPrefix(updated_og_value));

    if (fieldType === 'character-money' && !!value) {
      updatedValue = addDollarSign(updatedValue);
      updated_og_value = addDollarSign(updated_og_value);
    }

    return isControlled ? updatedValue : updated_og_value;
  };

  const notAllowAlpha = (onChange: (value: any) => void, value: any) => {
    if (value) {
      const decimalPlaces = value.split('.');
      const firstElemIsMinus = allowFirstSignMinus && decimalPlaces[0] === '-';
      let newValue = '';

      if (decimalPlaces.length) newValue = `${decimalPlaces[0].replace(/\D/g, '')}`;

      if (decimalPlaces[1] !== undefined) newValue += `.${decimalPlaces[1].replace(/\D/g, '')}`;
      else newValue = firstElemIsMinus ? `-${value.replace(/\D/g, '')}` : value.replace(/\D/g, '');

      if (handleChange && isControlled) handleChange(onChange, newValue);
      else onChange(newValue);
    } else onChange('');
  };

  const handleFieldChanges = (onChange: (value: any) => void, actualVal: any) => {
    if (handleChange && isControlled) handleChange(onChange, actualVal);
    else onChange(actualVal);
  };

  const isValidValueWithSeparator = (value: string | number) => {
    // below logic is to set decimal place precision
    const splitValueList = value.toString().split('.');
    const afterSeparatorValue = splitValueList[1];
    const isValueHasSeparator = splitValueList.length > 1;
    return defaultPrecision === 0 && isValueHasSeparator
      ? false
      : !afterSeparatorValue || (afterSeparatorValue && afterSeparatorValue.length <= defaultPrecision);
  };

  const checkForFrenchMoneyField = () => {
    if (fieldType === 'money' || fieldType === 'percentage' || fieldType === 'character-money') {
      if (language === Languages.FrenchCa) {
        return classes.inputAlignRight;
      }
    }
    return '';
  };
  /**
   *render text fields for all kinds of variations
   * @param
   * @returns material ui text fields
   */
  const renderField = ({
    og_value,
    onChange,
    onBlur,
    isCurrencyField,
  }: {
    og_value: any;
    onChange: (value: any) => void;
    onBlur?: (value: any) => void;
    isCurrencyField: boolean;
  }) => {
    return (
      <TextField
        placeholder={placeholder}
        value={isCurrencyField ? getValue(og_value) : typeof og_value === 'object' ? value : og_value}
        variant="outlined"
        fullWidth
        className={clsx(
          classes.textFieldBlock,
          showWarning && classes.showWarning,
          customClasses,
          checkForFrenchMoneyField(),
          {
            [classes.percentMax]: fieldType === 'percentage',
          }
        )}
        error={Boolean(error)}
        helperText={!hideErrorMsg && !isTooltipError ? errorMessage : ''}
        disabled={disabled}
        autoFocus={autoFocus}
        InputProps={{
          ...InputProps,
          startAdornment:
            // Need for backward compatibility if we need to remove startAdornment
            InputProps?.startAdornment === null
              ? null
              : [InputProps?.startAdornment, renderFieldTypes().startAdornment],
          endAdornment:
            // Need for backward compatibility if we need to remove endAdornment
            InputProps?.endAdornment === null ? null : [renderFieldTypes().endAdornment, InputProps?.endAdornment],
          onFocus: handleOnFocus,
        }}
        onChange={({ target }) => {
          let eventValue = target.value;
          if (allowOnlyPositive && !allowFirstSignMinus && eventValue[0] == '-') {
            eventValue = eventValue.slice(1);
          }
          if (maxLength) {
            const isDotPresent = String(eventValue).includes('.');
            const [beforeDotValue, afterDotValue] = String(eventValue).split('.');
            // do not include comma and dots in maxlength caculation
            const strippedValue = String(eventValue).split(/\W|_/g).join('');
            const strippedBeforeDotValue = String(beforeDotValue).split(/\W|_/g).join('');
            if (
              (isDotPresent && !afterDotValue?.length && strippedBeforeDotValue?.length + 1 > maxLength) ||
              strippedValue.length > maxLength
            )
              return;
          }
          if (isCurrencyField) {
            const actualValue = retrieveActualValue({
              value: eventValue,
              language,
              digitGroupSeparator,
            });
            // This condition is specific to character-money type fields. In the case of such fields, the actualValue will be appended with '$' or the related currency character, thus triggering the else condition.'.
            if (!isNaN(Number(actualValue))) {
              if (isValidValueWithSeparator(actualValue)) handleFieldChanges(onChange, actualValue);
            } else notAllowAlpha(onChange, actualValue);
          } else {
            if (!isNaN(Number(eventValue))) {
              if (!isValidValueWithSeparator(eventValue)) return;
              if (fieldType === 'percentage') {
                // validation for percentage field to support only till 100
                if (Number(eventValue) <= percentageLimit) handleFieldChanges(onChange, eventValue);
              } else {
                handleFieldChanges(onChange, eventValue);
              }
            } else {
              notAllowAlpha(onChange, eventValue);
            }
          }
        }}
        onKeyDown={keydownEvent}
        onBlur={(e) => {
          blurEvent?.();
          onBlur?.(e);
        }}
      />
    );
  };

  const renderController = () => {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue?.toString()}
        render={({ onChange, value: og_value, onBlur }) => {
          return fieldType === 'percentage' || fieldType === 'number' || fieldType === 'negative'
            ? renderField({
                og_value: isControlled ? value : og_value ?? value,
                onChange,
                onBlur,
                isCurrencyField: false,
              })
            : renderField({
                og_value: isControlled ? value : og_value ?? value,
                onChange,
                onBlur,
                isCurrencyField: true,
              });
        }}
      />
    );
  };

  return (
    <>
      {label &&
        (props.tooltip ? (
          <WpToolTip tooltype={2} title={props.tooltip} arrow>
            <span className={classes.tooltip}>
              <InputLabel htmlFor={id}>
                {label}
                <WpIcon svgIcon={infoCircleSolid} size={13} color="black" gutter="left" />
              </InputLabel>
            </span>
          </WpToolTip>
        ) : (
          <InputLabel htmlFor={id}>{label}</InputLabel>
        ))}

      {isNotControlledInput ? (
        <>
          {fieldType === 'percentage' || fieldType === 'number' || fieldType === 'negative'
            ? renderField({ og_value: value, onChange: onChangeInput, isCurrencyField: false })
            : renderField({ og_value: value, onChange: onChangeInput, isCurrencyField: true })}
        </>
      ) : isTooltipError ? (
        <WpToolTip
          arrow
          aria-multiline={true}
          placement="bottom"
          title={isTooltipError ? errorMessage ?? '' : ''}
          tooltype={WpToolTipEnum.custom}
          isTrackPosition={false}
        >
          <span>{renderController()}</span>
        </WpToolTip>
      ) : (
        renderController()
      )}
    </>
  );
};

export default WpNumberField;
