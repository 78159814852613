import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const wpHeaderStyles = makeStyles<Theme>((theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    backBtn: {
      paddingBottom: theme.spacing(1),
    },
    tabs: {
      marginTop: theme.spacing(1),
    },
    stepper: {
      margin: theme.spacing(2, 0, 3, 0),

      '& .MuiStepper-root': {
        padding: 0,
      },
    },
  })
);
