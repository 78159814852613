import React from 'react';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import WpIcon from 'components/wp-icon';
import arrowDownOutline from 'components/wp-icon/icons/arrow-down-outline';
import { IconType } from 'components/wp-icon/icons/icon-types';
import { IconColor } from 'components/wp-icon/wpIcon.interface';
import WpToolTip, { IWpToolTipProps, WpToolTipEnum } from 'components/wp-tooltip';

export interface IWpTabsProps extends Partial<TabsProps> {
  tabs: IWpTabProps[];
  enableMenuClick?: boolean;
  onTabChange?: (event: any, value: any) => void;
  setTabIndex?: (event: any, value: any) => Promise<number>;
  showLabelOnTabChange?: boolean;
  classList?: any;
  ref?: any;
  preventInnerOnChange?: boolean;
  removeDefaultSelection?: boolean;
  maxWidthLabel?: number;
}

export interface IWpTabProps extends Partial<TabProps> {
  component?: React.ReactNode;
  to?: string;
  /**
   * @deprecated No longer used. Use startIcon instead
   */
  fonticonstart?: IconProp;
  /**
   * @deprecated No longer used. Use endIcon instead
   */
  fonticonend?: IconProp;
  startIcon?: IconType;
  endIcon?: IconType;
  dropmenu?: boolean;
  menutabs?: Array<IWpTabProps>;
  iconColor?: IconColor;
  tooltipProps?: IWpToolTipProps;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    labelContainer: {
      width: 'auto',
      padding: 0,
    },
    iconstart: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        '& svg': {
          margin: '4px 5px 0 5px',
        },
      },
    },
    iconend: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row-reverse',
        '& svg': {
          margin: '4px 5px 0 5px',
        },
      },
    },
    iconRestriction: {
      pointerEvents: 'none',
    },
    disabled: {
      backgroundColor: theme.palette.background.disabled,
      opacity: '.5',
    },
    tabs: {
      minHeight: 'auto',

      '& .MuiTabs-flexContainer': {
        gap: theme.spacing(3),
      },
      '& button': {
        minWidth: 10,
        margin: `0px !important`,
      },
      '& .MuiTab-root': {
        padding: theme.spacing(0, 0, 0.5, 0),
        margin: 0,
        maxWidth: '400px',
        minHeight: 'auto',
      },
      '& .MuiTab-textColorInherit:not(.Mui-disabled)': {
        opacity: 1,
      },
      '& .MuiTab-labelIcon': {
        minHeight: '0px !important',
      },
    },
    dialogPaper: {
      border: `1px solid ${theme.palette.common.lightbrown}`,
    },
    tooltipWrapper: {
      display: 'block',
    },
    truncate: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  })
);
export const WpTabs = React.forwardRef(function WpTabs(props: IWpTabsProps, ref) {
  const {
    value = props?.removeDefaultSelection ? undefined : 0,
    tabs,
    setTabIndex,
    variant = 'standard',
    indicatorColor = 'primary',
    onFocusVisible,
    preventInnerOnChange = false,
    onTabChange,
    classList,
    showLabelOnTabChange,
    maxWidthLabel,
    ...others
  } = props;

  const [selectedValue, setSelectedValue] = React.useState(value);
  const classes = useStyles({ maxWidthLabel });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | any>(null);
  const open = Boolean(anchorEl);
  const [selectedLabel, setSelectedLabel] = React.useState<any>(null);
  const [moreOption, setMoreOption] = React.useState<any>(null);
  const [childTab, setChildTabs] = React.useState<{
    index: number;
    options: Array<IWpTabProps>;
  }>();
  const defaultTootipProps: Omit<IWpToolTipProps, 'title'> = {
    tooltype: WpToolTipEnum.fixed,
    arrow: true,
    placement: 'bottom-start',
    hidden: true,
    children: <></>,
  };

  React.useEffect(() => {
    const filterd = tabs.filter((item) => item.dropmenu)?.[0];
    if (filterd) setSelectedLabel(filterd.label);
  }, []);

  React.useEffect(() => {
    setSelectedValue(value);
    window.dispatchEvent(new CustomEvent('resize'));
  }, [value]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    const tab = props.tabs[newValue];
    if (tab && tab.dropmenu && tab.menutabs) {
      setMoreOption({ label: tab.label, key: tab.key });
      setChildTabs({ index: newValue, options: tab.menutabs });
      setAnchorEl(event.target);
    }
    if (!!setTabIndex) {
      newValue = await setTabIndex(event, newValue);
      setSelectedValue(newValue);
      if (!!onTabChange && !preventInnerOnChange) onTabChange(event, newValue);
    } else {
      setSelectedValue(newValue);
      if (!!onTabChange) onTabChange(event, newValue);
    }
  };

  const setChildTab = (option: IWpTabProps) => {
    setAnchorEl(null);
    setSelectedValue(childTab?.index);
    if (!!onTabChange) onTabChange(event, option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMoreOption = React.useCallback(() => {
    let options: Array<IWpTabProps> = [];
    if (childTab && childTab.options.length) options = [...childTab.options, moreOption];
    return options;
  }, [childTab, moreOption, selectedLabel]);

  const renderChildOptions = () => {
    if (showLabelOnTabChange) {
      return getMoreOption().map((option, index) => (
        <MenuItem
          key={`sub_tab_${index}`}
          onClick={() => {
            if (!option.disabled) {
              setSelectedLabel(option.label);
              setChildTab(option);
            }
          }}
          className={`${option.disabled ? classes.disabled : ''}`}
        >
          {option.label}
        </MenuItem>
      ));
    }
    return childTab?.options.map((option, index) => (
      <MenuItem
        key={`sub_tab_${index}`}
        onClick={() => {
          if (!option.disabled) setChildTab(option);
        }}
        className={`${option.disabled ? classes.disabled : ''}`}
      >
        {option.label}
      </MenuItem>
    ));
  };

  const getRegularTabProps = (tab: IWpTabProps, index: number) => ({
    className: clsx({
      [classes.iconstart]: tab.startIcon,
      [classes.iconend]: tab.endIcon,
    }),
    key: index,
    ...tab,
    icon:
      tab.startIcon || tab.endIcon ? (
        <WpIcon svgIcon={tab.startIcon ? tab.startIcon : tab.endIcon} color={tab.iconColor} />
      ) : (
        tab.icon
      ),
    label: maxWidthLabel ? (
      <div className={classes.truncate} style={{ maxWidth: maxWidthLabel }}>
        {tab.label}
      </div>
    ) : (
      tab.label
    ),
  });

  const getMenuTabProps = (tab: IWpTabProps, index: number) => ({
    disableRipple: true,
    className: classes.iconend,
    key: index,
    ...tab,
    label: selectedLabel || tab.label,
    onClick: () => {
      if (!!onTabChange && props.enableMenuClick) onTabChange(null, tab.label);
    },
    icon: <WpIcon svgIcon={arrowDownOutline} size={12} />,
  });

  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: { borderRadius: 10 },
        }}
        innerRef={ref}
        value={selectedValue}
        variant={variant}
        indicatorColor={indicatorColor}
        onChange={(event, newValue) => !preventInnerOnChange && handleChange(event, newValue)}
        {...others}
        className={clsx(classes.tabs, classList)}
      >
        {tabs.map((tab: IWpTabProps, index: number) => {
          const { dropmenu, tooltipProps, ...others } = tab || {};
          return !!tooltipProps?.title ? (
            <WpToolTip {...defaultTootipProps} {...tooltipProps}>
              {tab.disabled ? (
                <span className={classes.tooltipWrapper}>
                  {!!dropmenu ? (
                    <Tab {...getMenuTabProps(others, index)} />
                  ) : (
                    <Tab {...getRegularTabProps(others, index)} />
                  )}
                </span>
              ) : !!dropmenu ? (
                <Tab {...getMenuTabProps(others, index)} />
              ) : (
                <Tab {...getRegularTabProps(others, index)} />
              )}
            </WpToolTip>
          ) : !!dropmenu ? (
            <Tab {...getMenuTabProps(others, index)} />
          ) : (
            <Tab {...getRegularTabProps(others, index)} />
          );
        })}
      </Tabs>
      <Menu
        id="tab_menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ variant: 'outlined', elevation: 1 }}
      >
        {renderChildOptions()}
      </Menu>
    </>
  );
});
export default WpTabs;
