import React, { ReactNode } from 'react';
import { wpStepperStyles, WpStepperConnector } from './wpStepperStyles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import clsx from 'clsx';

export interface IWpStepperProps {
  steps: Array<IWpStepperStep>;
  isNonLinear?: boolean;
  alternativeLabel?: boolean;
  events?: {
    reset: () => void;
    setActiveStep: (stepIndex: number) => void;
    getActiveStep: () => { stepIndex: number; step: IWpStepperStep };
  };
  isCustomStepperReq?: boolean; // will be removed once all steppers start using it
}

export interface IWpStepperStep {
  name: string;
  isActive?: boolean;
  customClass?: string;
  isDisabled?: boolean;
  isComplete?: boolean;
  hasError?: boolean;
  icon?: any;
  onClick?: (step: IWpStepperStep, stepIndex: number, ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const WpStepper: React.FC<{ props: IWpStepperProps }> = ({
  props = {
    steps: [],
    isNonLinear: false,
    events: {
      reset: () => {
        console.log('Stepper Events Not Binded Yet !!!');
      },
      setActiveStep: () => {
        console.log('Stepper Events Not Binded Yet !!!');
      },
      getActiveStep: () => {
        console.log('Stepper Events Not Binded Yet !!!');
      },
    },
    isCustomStepperReq: false,
    alternativeLabel: false,
  },
  ...args
}) => {
  const classes = wpStepperStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  props.events = {
    reset: () => {
      setActiveStep(0);
    },
    setActiveStep: (stepIndex: number) => {
      setActiveStep(stepIndex);
    },
    getActiveStep: () => {
      return {
        step: props.steps[activeStep],
        stepIndex: activeStep,
      };
    },
  };
  const stepClicked = (step: IWpStepperStep, stepIndex: number, ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (step.onClick) {
      step.onClick(step, stepIndex, ev);
    } else {
      setActiveStep(stepIndex);
    }
  };

  const getStepNodes = () => {
    const stepNodes: ReactNode[] = [];
    props.steps.forEach((step: IWpStepperStep, stepIndex: number) => {
      stepNodes.push(
        <Step
          key={step.name}
          completed={step.isComplete}
          className={`${classes.stepLabel} ${step.customClass}`}
          onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => stepClicked(step, stepIndex, ev)}
        >
          <StepButton>
            {step.icon ? (
              <StepLabel
                StepIconComponent={() => step.icon || undefined}
                error={step.hasError}
                className={classes.stepLabel}
              >
                {step.name}
              </StepLabel>
            ) : (
              <StepLabel error={step.hasError} className={classes.stepLabel}>
                {step.name}
              </StepLabel>
            )}
          </StepButton>
        </Step>
      );
    });
    return stepNodes;
  };

  const createStepper = (props: any) => {
    return (
      <Stepper
        nonLinear={props.isNonLinear}
        activeStep={activeStep}
        {...props}
        alternativeLabel={props.alternativeLabel}
      >
        {getStepNodes()}
      </Stepper>
    );
  };

  return (
    <div className={clsx('wp-horizontal-stepper', classes.root)} {...args}>
      {/* Below condition will be removed once steppers start using customConnector througout application */}
      {props.isCustomStepperReq ? createStepper({ connector: <WpStepperConnector />, ...props }) : createStepper({})}
    </div>
  );
};
