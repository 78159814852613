/**
 * Content Layout Component
 *
 * This content creates a common layout that can be used across the application.
 * To see guidelines visit: https://www.figma.com/file/G4gp7enFLV680KYfAVFoss/Wagepoint's-Design-System?node-id=2513%3A1460
 */
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import clsx from 'clsx';
import { useWpMediaQuery } from 'styles/useMedia';

export interface IContentLayoutProps {
  children?: React.ReactNode;
  /**
   * @deprecated No longer used.
   */
  headerBackgroundColor?: string;
  /**
   * @deprecated No longer used.
   */
  contentBackgroundColor?: string;
  /**
   * @deprecated No longer used.
   */
  footerBackgroundColor?: string;
  header?: React.ReactNode;
  /**
   * @deprecated No longer used.
   */
  hasFooter?: boolean | undefined;
  customClass?: string;
  /**
   * @deprecated No longer used.
   */
  customHeaderHeight?: number;
  /**
   * @deprecated No longer used.
   */
  customFooterHeight?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    wplayoutcontainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      height: `calc(100vh - 63px)`, //63px is the height of the top navigation
    },
    wpcontentPane: {
      height: '100%',
      position: 'relative',

      // styling the scrollbar
      overflow: 'auto',
      scrollbarGutter: 'stable',

      '&::-webkit-scrollbar': {
        width: '0.5em',
        height: '100%',
        background: 'none',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.background.highlight,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: 6,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    wpContent: {
      margin: 'auto',
      maxWidth: 1172,
      padding: theme.spacing(1, 2, 2, 2),
      boxSizing: 'border-box',
    },
    wpContentXs: {
      maxWidth: 380,
    },
    wpContentSm: {
      maxWidth: 578,
    },
    wpContentMd: {
      maxWidth: 776,
    },
    wpContentLg: {
      maxWidth: 974,
    },
    wpContentIsDesktopScreen: {
      padding: theme.spacing(1, 1, 2, 1),
    },
    wpcontentFooter: {
      display: 'block',
    },
    snackbarPortal: {
      position: 'relative',
      '& .MuiSnackbar-root': {
        position: 'absolute',
      },
    },
  })
);

export const WpContentLayout = (props: IContentLayoutProps) => {
  const { children, header, customClass } = props;
  const mediaQuery = useWpMediaQuery();
  const classes = useStyles();
  return (
    <div className={clsx(classes.wplayoutcontainer, customClass)}>
      <div className={classes.wpcontentPane} id={'layout-content-portal'}>
        <div
          className={clsx(
            classes.wpContent,
            {
              [classes.wpContentXs]: props.size === 'xs',
              [classes.wpContentSm]: props.size === 'sm',
              [classes.wpContentMd]: props.size === 'md',
              [classes.wpContentLg]: props.size === 'lg',
              [classes.wpContentIsDesktopScreen]: mediaQuery.isDesktopScreen,
            },
            'wp-content'
          )}
        >
          {header ? <div>{header}</div> : []}
          {children}
        </div>
      </div>
      <div id={'layout-footer-portal'} className={classes.wpcontentFooter}>
        <div id={'snackbar-portal'} className={classes.snackbarPortal}></div>
      </div>
    </div>
  );
};
