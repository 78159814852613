import React from 'react';
import WpButton from '../wp-button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 12px',
      marginRight: '12px',
      '& .action-pane': {
        '& .MuiButtonBase-root': {
          marginLeft: '0 !important',
        },
      },
    },
  })
);
type IDefaultFormText = {
  handleCancel?: () => void;
  handleAddClick?: (value: any) => void;
  formTemplate?: any;
};

const DefaultFormText = ({ handleAddClick, handleCancel, formTemplate }: IDefaultFormText) => {
  const classes = useStyles();
  const [text, setText] = React.useState('');
  return (
    <div className={classes.container}>
      {formTemplate ? (
        formTemplate
      ) : (
        <>
          <div className="text-pane">
            <TextField
              label={null}
              value={text}
              onChange={(e) => setText(e.target.value)}
              fullWidth
              variant="outlined"
            ></TextField>
          </div>
          <div className="action-pane">
            <WpButton
              onClick={() => {
                if (handleAddClick) handleAddClick(text);
                setText('');
              }}
              color="primary"
              variant="contained"
            >
              Add
            </WpButton>
            <WpButton onClick={handleCancel} variant="contained">
              Cancel
            </WpButton>
          </div>
        </>
      )}
    </div>
  );
};

export default DefaultFormText;
