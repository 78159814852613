import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50006 7.33334L8.49994 7.33334C8.63543 7.33334 8.75261 7.28203 8.85156 7.17941C8.95052 7.07669 9 6.95518 9 6.81477C9 6.67436 8.95052 6.55285 8.85156 6.45023L5.35162 2.82071C5.25257 2.71809 5.13539 2.66668 5 2.66668C4.86461 2.66668 4.74733 2.71809 4.64847 2.82071L1.14853 6.45023C1.04967 6.55285 1 6.67436 1 6.81477C1 6.95518 1.04967 7.07669 1.14853 7.17941C1.24739 7.28203 1.36467 7.33334 1.50006 7.33334Z"
      />
    </>
  ),
};

export default icon;
