import React, { ReactNode } from 'react';

import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { WpTypography } from 'components/wp-typography/wpTypography';
import WpIcon from 'components/wp-icon';
import Button from '@material-ui/core/Button';
import closeOutline from 'components/wp-icon/icons/close-outline';
import clsx from 'clsx';

export type PillVariant = 'blue' | 'green' | 'red' | 'orange' | 'grey' | 'brown';

const wpPillStyles = makeStyles<Theme>((theme) =>
  createStyles({
    pill: {
      boxSizing: 'border-box',
      display: 'inline-flex',
      whiteSpace: 'nowrap',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50px',
    },
    // Can't use props to set the height and padding
    // because it make problem with performance
    pillSizeSmall: {
      minHeight: 22,
      padding: theme.spacing(0, 0.5),
    },
    pillSizeLarge: {
      minHeight: 26,
      padding: theme.spacing(0.5, 1),
    },
    button: {
      margin: 0,
      padding: theme.spacing(0.5),
      borderRadius: '50%',
      minWidth: 'auto',
    },
  })
);

export interface IWpPillProps {
  label?: any;
  icon?: ReactNode;
  color?: PillVariant;
  onDelete?: any;
  size?: string;
}

const WpPill = (props: IWpPillProps) => {
  const { label, onDelete, color = 'grey', icon } = props;
  const theme = useTheme();
  const classes = wpPillStyles(props);
  return (
    <div
      className={clsx(classes.pill, {
        [classes.pillSizeSmall]: props.size === 'small',
        [classes.pillSizeLarge]: props.size !== 'small',
      })}
      style={{ backgroundColor: color ? theme.palette.decorative[color].light : theme.palette.decorative.grey.light }}
    >
      {icon}
      {label && (
        <WpTypography
          variant="p2"
          style={{ color: color ? theme.palette.decorative[color].main : theme.palette.decorative.grey.main }}
        >
          {label}
        </WpTypography>
      )}
      {onDelete && (
        <Button className={classes.button} onClick={onDelete}>
          <WpIcon svgIcon={closeOutline} color={color} size={10} />
        </Button>
      )}
    </div>
  );
};

export default WpPill;
