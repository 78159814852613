import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const wpDatePickerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiFormControl-root .MuiFormHelperText-root': {
        display: 'none',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 0,
      },
      '& .MuiIconButton-root': {
        padding: 6,
      },
    },
    day: {
      '&.recommended': {
        position: 'relative',
        '& .MuiIconButton-label': {
          color: theme.palette.common.white,
        },
        '& svg': {
          position: 'absolute',
          width: '90%',
          height: '90%',
          left: '2px',
          '& path': {
            fill: theme.palette.primary.main,
          },
        },
      },
      '&.selected': {
        '& .MuiPickersDay-day': {
          width: '33px',
          height: '33px',
        },
      },
    },
    paper: {
      // let's keep this class name here so design team can experiment with treatments
      // '& .MuiPickersCalendarHeader-daysHeader, .MuiPickersCalendarHeader-switchHeader': {
      //   display: 'none',
      // },
      // '& .MuiPickersBasePicker-pickerView': {
      //   minHeight: 0,
      // },
      '& .MuiPickersMonthSelection-container': {
        flexDirection: 'column',
        gap: theme.spacing(2),
        overflow: 'scroll',
        flexWrap: 'noWrap',
        maxHeight: '300px',
      },

      '& .MuiPickersYearSelection-container': {
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: theme.spacing(2),
      },
    },
  })
);

export const toolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      background: theme.palette.primary.light,
      height: 'auto',
      padding: theme.spacing(1),
      minHeight: 'auto',
      borderBottom: `1px solid ${theme.palette.common.lightbrown}`,

      '& .MuiPickersToolbarText-toolbarTxt': {
        color: theme.palette.common.black,
      },
      '& .MuiPickersToolbarText-toolbarBtnSelected': {
        color: theme.palette.primary.main,
      },
    },
  })
);
