import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { wpCheckBoxStyles } from './wpCheckBoxStyles';
import WpIcon from 'components/wp-icon';
import checkSquareSolid from 'components/wp-icon/icons/check-square-solid';
import clsx from 'clsx';
import WpTypography from 'components/wp-typography';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const WpCheckbox = forwardRef<
  any,
  {
    label: string | ReactNode;
    value: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    disabled?: boolean;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    isError?: boolean;
    indeterminate?: boolean;
    isControlled?: boolean;
    description?: ReactNode;
    isLabelBold?: boolean;
  }
>(function WpCheckbox(props, ref) {
  const { value, onChange, disabled, indeterminate, labelPlacement, isError, description, isLabelBold } = props;

  const [isChecked, setIsChecked] = useState<boolean>(value);
  const classes = wpCheckBoxStyles();
  function handleChange(event: React.ChangeEvent<HTMLInputElement> | undefined, checked: boolean) {
    if (value === undefined) setIsChecked(checked);
    if (event) onChange(event, checked);
  }

  useEffect(() => {
    if (value !== undefined) setIsChecked(value);
  }, [value]);

  const label = isLabelBold ? <WpTypography variant="p1Bold">{props.label}</WpTypography> : props.label;

  return (
    <>
      <FormControlLabel
        className={classes.wpCheckBox}
        control={
          <Checkbox
            ref={ref}
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
            color="primary"
            checkedIcon={<WpIcon svgIcon={checkSquareSolid} color="primary" />}
            disableRipple
            indeterminate={indeterminate}
            icon={<span className={clsx(classes.icon, { ['error']: isError })} />}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {description && <WpTypography className={classes.description}>{description}</WpTypography>}
    </>
  );
});

export default WpCheckbox;
