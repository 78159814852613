import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.2448 1.2448C2.04183 0.447767 3.12283 0 4.25 0C5.37717 0 6.45817 0.447767 7.2552 1.2448C8.05223 2.04183 8.5 3.12283 8.5 4.25C8.5 5.37717 8.05223 6.45817 7.2552 7.2552C6.45817 8.05223 5.37717 8.5 4.25 8.5C3.12283 8.5 2.04183 8.05223 1.2448 7.2552C0.447767 6.45817 0 5.37717 0 4.25C0 3.12283 0.447767 2.04183 1.2448 1.2448ZM4.25 1C3.38805 1 2.5614 1.34241 1.9519 1.9519C1.34241 2.5614 1 3.38805 1 4.25C1 5.11195 1.34241 5.9386 1.9519 6.5481C2.5614 7.15759 3.38805 7.5 4.25 7.5C5.11195 7.5 5.9386 7.15759 6.5481 6.5481C7.15759 5.9386 7.5 5.11195 7.5 4.25C7.5 3.38805 7.15759 2.5614 6.5481 1.9519C5.9386 1.34241 5.11195 1 4.25 1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54842 6.54842C6.74369 6.35316 7.06027 6.35316 7.25553 6.54842L9.85353 9.14642C10.0488 9.34169 10.0488 9.65827 9.85353 9.85353C9.65827 10.0488 9.34169 10.0488 9.14642 9.85353L6.54842 7.25553C6.35316 7.06027 6.35316 6.74369 6.54842 6.54842Z"
      />
    </>
  ),
};

export default icon;
