import React from 'react';
import { useState } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import WpButton from '../wp-button';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

import { wpLinkStyles } from './wpLinkStyles';
import WpIcon from 'components/wp-icon';
import { useDisableBySelector } from 'utility';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WpTypography } from 'components/wp-typography/wpTypography';
import { WpCheckbox } from 'components/wp-checkbox';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';
import downloadOutline from 'components/wp-icon/icons/download-outline';
import externalLinkOutline from 'components/wp-icon/icons/external-link-outline';
import WpToolTip, { IWpToolTipProps } from 'components/wp-tooltip';

export interface IWpLinkProps extends ButtonProps {
  isButton?: boolean;
  isExternal?: boolean;
  isDownload?: boolean;
  isCombo?: boolean;
  noGutter?: boolean;
  label?: React.ReactNode;
  isOpen?: boolean;
  dataList?: Array<ListItem>;
  href?: string;
  preventDefault?: boolean;
  tooltip?: IWpToolTipProps;

  onSubmitCallback?: (...args: any[]) => void;
  onSubmitTitle?: React.ReactNode;
  comboVariant?: string;
  /**
   * @deprecated Is not used anymore. Now only download and external links have icons.
   */
  withPencilIcon?: boolean;
  /**
   * @deprecated Is not used anymore. Now only download and external links have icons.
   */
  startIcon?: React.ReactNode;
  noIcon?: boolean;
}
interface ListItem {
  disabled?: boolean;
  label: React.ReactNode;
  onClick?: (...args: any[]) => void;
}

const TooltipWrapper: React.FC<{ tooltip?: IWpToolTipProps }> = (props) => {
  const { children, tooltip } = props;

  return tooltip?.title ? (
    <WpToolTip {...tooltip}>
      <span>{children}</span>
    </WpToolTip>
  ) : (
    <>{children}</>
  );
};

const WpLink: React.FC<IWpLinkProps> = (props) => {
  const classes = wpLinkStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checked, setChecked] = useState<Record<any, any>[]>([]);
  const {
    children,
    isButton,
    isExternal,
    isDownload,
    className,
    isCombo,
    comboVariant,
    onClick,
    onSubmitCallback,
    onSubmitTitle,
    href,
    preventDefault,
    label,
    dataList,
    tooltip,
    noGutter,
    ...others
  } = props;
  const isDisableBySelector = useDisableBySelector(children);

  const handleClickLink = (e: any) => {
    e.preventDefault();
    if (!others.disabled && !isDisableBySelector && onClick) {
      onClick(e);
    }
  };
  const handleItemOnclick = (e: React.MouseEvent<HTMLElement>, item: any) => {
    e.preventDefault();
    e.stopPropagation();
    item.onClick ? item.onClick() : null;
    handleOpenClick();
  };
  const handleOpenClick = (status?: boolean, e?: React.MouseEvent<HTMLButtonElement>) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    e && setAnchorEl(e.currentTarget);
    setOpen((prev) => (status !== undefined ? status : !prev));
  };
  const handleCliskAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const generateLinkItems = () => {
    if (dataList?.length) {
      return dataList?.map((data, index) => {
        const isDisabled = useDisableBySelector(data.label);
        return (
          <MenuItem
            disabled={isDisabled || data.disabled}
            key={index}
            component={Link}
            href=""
            onClick={(e: any) => handleItemOnclick(e, data)}
            color="textPrimary"
          >
            {data.label}
          </MenuItem>
        );
      });
    }
    return <WpTypography variant="p1Bold"> No Data </WpTypography>;
  };

  const handleOnchange = (e: any, item: ListItem) => {
    if (e.target.checked) {
      setChecked([...checked, item]);
    } else {
      const newList = checked.filter((oldItem) => oldItem.label !== item.label);
      setChecked(newList);
    }
  };

  const handleAddAll = (e: any) => {
    if (e.target.checked) {
      setChecked(dataList ? dataList : []);
    } else {
      setChecked([]);
    }
  };

  const onsubmit = () => {
    onSubmitCallback ? onSubmitCallback(checked) : null;
    handleOpenClick();
  };

  const generateCheckboxItems = () => {
    if (dataList?.length) {
      return (
        <>
          <MenuItem>
            <WpCheckbox
              label="Select all"
              value={dataList?.length === checked.length && dataList?.length !== 0}
              onChange={handleAddAll}
            />
          </MenuItem>
          {dataList?.map((data, i) => {
            return (
              <MenuItem key={i}>
                <WpCheckbox
                  label={data.label}
                  value={checked.includes(data)}
                  onChange={(e) => handleOnchange(e, data)}
                />
              </MenuItem>
            );
          })}
          <MenuItem>
            <WpButton noGutter variant="contained" color="primary" onClick={onsubmit}>
              {onSubmitTitle}
            </WpButton>
          </MenuItem>
        </>
      );
    }
    return <WpTypography> No Data </WpTypography>;
  };

  return isButton || isExternal || isDownload || isCombo ? (
    <>
      {/* TODO: RB - update Button components with WpButton, text variant */}
      {isButton && (
        <TooltipWrapper tooltip={tooltip}>
          <Button
            className={clsx(
              classes.wpLink,
              classes.wpLinkButton,
              { [classes.wpLinkButtonWithoutGutter]: noGutter, [classes.wpLinkButtonWithGutter]: !noGutter },
              className
            )}
            onClick={onClick}
            {...others}
            disabled={isDisableBySelector || others.disabled}
            disableRipple
          >
            {children}
          </Button>
        </TooltipWrapper>
      )}
      {isExternal && (
        <TooltipWrapper tooltip={tooltip}>
          <Link
            className={clsx(classes.wpLink, className, {
              disabled: isDisableBySelector || others.disabled,
            })}
            href={href ? href : ''}
            target="_blank"
            rel="noopener"
          >
            {children} <span className={classes.linkSpace} />
            {!props.noIcon && <WpIcon svgIcon={externalLinkOutline} color="link" />}
          </Link>
        </TooltipWrapper>
      )}

      {/* TODO: RB - download should be a button, replace icons with wp*/}
      {isDownload && (
        <TooltipWrapper tooltip={tooltip}>
          <Link
            className={clsx(classes.wpLink, className, {
              disabled: isDisableBySelector || others.disabled,
            })}
            href=""
            onClick={handleClickLink}
          >
            {children}
            <WpIcon color="link" svgIcon={downloadOutline} />
          </Link>
        </TooltipWrapper>
      )}
      {isCombo && (
        <div className={classes.comboWrapper} onClick={(e) => e.stopPropagation()}>
          <TooltipWrapper tooltip={tooltip}>
            <Button
              className={clsx(classes.wpLink, classes.comboButton, className)}
              onClick={onClick}
              {...others}
              disabled={useDisableBySelector(label) || others.disabled}
              disableRipple
            >
              {label}
            </Button>
          </TooltipWrapper>
          <Button
            className={clsx(classes.sortDownIcon, className)}
            onClick={(e) => !others.disabled && !isDisableBySelector && handleOpenClick(!open, e)}
            {...others}
            disabled={isDisableBySelector || others.disabled}
            disableRipple
          >
            <WpIcon svgIcon={caretDownSolid} color="link" />
          </Button>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={handleCliskAway}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{ variant: 'outlined' }}
          >
            {comboVariant === 'multi' && generateCheckboxItems()}
            {comboVariant === 'simple' && generateLinkItems()}
            {children}
          </Menu>
        </div>
      )}
    </>
  ) : (
    <TooltipWrapper tooltip={tooltip}>
      <Link
        className={clsx(classes.wpLink, className, {
          disabled: isDisableBySelector || others.disabled,
        })}
        href={href}
        onClick={(e) => {
          !preventDefault && handleClickLink(e);
        }}
      >
        {children}
      </Link>
    </TooltipWrapper>
  );
};

export default WpLink;
