import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        d="M7.092.006a.612.612 0 00-.35.175c-.17.16-4.228 4.03-4.257 4.061a1.119 1.119 0 000 1.516c.03.031 4.085 3.9 4.257 4.061.111.104.219.157.354.175a.629.629 0 00.543-.188.61.61 0 00.174-.485.602.602 0 00-.18-.395c-.015-.016-.946-.905-2.07-1.976A782.33 782.33 0 013.52 5c0-.002.92-.88 2.043-1.95 1.124-1.071 2.055-1.96 2.07-1.976a.61.61 0 00.169-.306.687.687 0 00-.022-.352.657.657 0 00-.39-.383.689.689 0 00-.298-.027z"
        clipRule="evenodd"
      />
    </>
  ),
};

export default icon;
