import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { disabledThemeColor } from './constants';

export const wpGridStyles = makeStyles<Theme>((theme) =>
  createStyles({
    columWithEllipse: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      direction: 'initial',
    },
    WpChckSelAll: {
      padding: theme.spacing(0, 2, 0, 0),
    },
    wpGridWrapper: {
      position: 'relative',
      '& .MuiTable-stickyHeader': {
        borderCollapse: 'collapse',
      },
      '& .MuiTable-root': {
        borderCollapse: 'inherit',
      },
      '& .wp-grid-hover': {
        '& tr.MuiTableRow-root:not(.MuiTableRow-head):hover': {
          backgroundColor: 'inherit',
          '& .MuiTableCell-root.sticky-left,& .MuiTableCell-root.sticky-right': {
            backgroundColor: 'inherit',
          },
        },
      },
      '& .wp-grid': {
        '& .MuiTableHead-root': {
          borderTop: `1px solid ${theme.palette.common.lightbrown}`,
        },
        '& .MuiTableRow-head': {
          '& .MuiTableCell-head': {
            '&.sticky-left, &.sticky-right': {
              zIndex: 4,
            },
            ...theme.tag?.p1Bold,
            padding: theme.spacing(1),
          },
        },
        '& .MuiTableBody-root': {
          '& .MuiTableCell-body': {
            verticalAlign: 'middle',
            padding: theme.spacing(1),
          },

          '& .MuiTableRow-root:last-child > .MuiTableCell-root': {
            borderBottom: 'none',
          },
        },
        '& .MuiTableRow-root': {
          '& .MuiTableCell-root': {
            ...theme.tag?.p1,

            '&.sticky-left': {
              zIndex: 3,
              position: 'sticky',
              left: '0',
              backgroundColor: theme.palette.common.white,
            },

            '&.sticky-right': {
              zIndex: 3,
              position: 'sticky',
              right: '0',
              backgroundColor: theme.palette.common.white,
            },

            '&.left-shadow': {
              borderLeft: '1px solid ' + theme.palette.common.lightbrown,
            },

            '&.right-shadow': {
              borderRight: '1px solid ' + theme.palette.common.lightbrown,
            },
          },
        },
        '& .wp-grid-small-text': {
          ...theme.tag?.p3,
          color: theme.palette.common.black,
        },
        '& .wp-block': {
          display: 'block',
        },
        '& tr.MuiTableRow-root:not(.MuiTableRow-head):hover': {
          backgroundColor: theme.palette.primary.light,

          '& .MuiTableCell-root.sticky-left,& .MuiTableCell-root.sticky-right': {
            backgroundColor: theme.palette.primary.light,
          },
        },

        '& wp-row-child-style': {
          paddingBottom: 0,
          paddingTop: 0,
        },

        '& .MuiTableCell-footer': {
          color: theme.palette.common.black,
          background: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.common.lightbrown}`,
          padding: theme.spacing(1),
        },

        '& .wpg-child-row-with-toogler': {
          display: 'inline-flex',
          gap: theme.spacing(0.5),
          '&.iconstart': {
            direction: 'rtl',
          },
        },

        '& .wpg-child-row-toggler': {
          alignSelf: 'center',
          svg: {
            transitionDuration: '.25s',
          },
          '&.expanded svg': {
            transform: 'rotate(180deg)',
          },
          '&.iconstartStyles': {
            marginRight: '3px',
          },
          '&.iconendStyles': {
            marginLeft: '3px',
          },
        },
      },
    },
    masked: {
      backgroundColor: disabledThemeColor,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0.5,
      zIndex: 10,
    },
    placeholder: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      '& svg': {
        marginBottom: theme.spacing(3),
      },
    },
  })
);
