import React, { useEffect } from 'react';
import { wpHeaderCellStyles } from './wpHeaderCellStyles';
import { WpGridColumnSortDirectionEnum, WpGridVerticalAlignmentEnum } from '../wpGrid.enum';
import { IWpGridColumn, IWpGridHeaderCellProps } from '../wpGrid.interface';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import WpIcon from 'components/wp-icon';
import caretDownSolid from 'components/wp-icon/icons/caret-down-solid';
import caretUpSolid from 'components/wp-icon/icons/caret-up-solid';
import caretSortSolid from 'components/wp-icon/icons/caret-sort-solid';

const WpGridHeaderCell: React.FC<IWpGridHeaderCellProps> = (props) => {
  const classes = wpHeaderCellStyles();

  const setSortDirection = (value: WpGridColumnSortDirectionEnum) => {
    props.setSortConfig((prev) => {
      return { ...prev, [props.column.field]: value };
    });
  };
  function handleColumnSorting(currentColumn: IWpGridColumn) {
    const column = { ...currentColumn };
    if (!column.enableSorting) return;
    switch (props.sortConfig[column.field]) {
      case WpGridColumnSortDirectionEnum.ASC: {
        column.sortDirection = WpGridColumnSortDirectionEnum.DESC;
        break;
      }
      case WpGridColumnSortDirectionEnum.DESC: {
        column.sortDirection = WpGridColumnSortDirectionEnum.NONE;
        break;
      }
      default: {
        column.sortDirection = WpGridColumnSortDirectionEnum.ASC;
        break;
      }
    }

    const nextSortConfig = { ...props.sortConfig };
    if (props.resetSortState) {
      Object.keys(nextSortConfig).forEach((key) => {
        if (key === props.column.field) {
          nextSortConfig[props.column.field] = column.sortDirection || WpGridColumnSortDirectionEnum.NONE;
        } else {
          nextSortConfig[key] = WpGridColumnSortDirectionEnum.NONE;
        }
      });
    } else {
      nextSortConfig[props.column.field] = column.sortDirection;
    }

    props.setSortConfig(nextSortConfig);
    props.onSortRequest(column, nextSortConfig);
  }

  function getSortedNode() {
    switch (props.sortConfig[props.column.field]) {
      case WpGridColumnSortDirectionEnum.ASC: {
        return <WpIcon color="brown" svgIcon={caretUpSolid} size={10} gutter="bottom" />;
      }
      case WpGridColumnSortDirectionEnum.DESC: {
        return <WpIcon color="brown" svgIcon={caretDownSolid} size={10} gutter="top" />;
      }
      default: {
        return <WpIcon color="brown" svgIcon={caretSortSolid} size={14} />;
      }
    }
  }

  function initSortDirection() {
    switch (props.column.sortDirection) {
      case WpGridColumnSortDirectionEnum.ASC: {
        setSortDirection(WpGridColumnSortDirectionEnum.ASC);
        break;
      }
      case WpGridColumnSortDirectionEnum.DESC: {
        setSortDirection(WpGridColumnSortDirectionEnum.DESC);
        break;
      }
      default: {
        setSortDirection(WpGridColumnSortDirectionEnum.NONE);
        break;
      }
    }
  }

  useEffect(() => {
    initSortDirection();
  }, []);

  return (
    <TableCell
      onClick={() => handleColumnSorting(props.column)}
      className={clsx(
        classes.wpHeaderCell,
        props.disabled && classes.disabled,
        { sortable: props.column.enableSorting },
        props.column.sticky,
        props.column.classList
      )}
      style={{
        verticalAlign: props.column.headerVerticalAlign || WpGridVerticalAlignmentEnum.MIDDLE,
        width: props.column.safeWidth,
        minWidth: props.stickyColumnData?.width || props.column.width || props.column.minWidth || 'auto',
        left: props.stickyColumnData?.leftPosition,
        right: props.stickyColumnData?.rightPosition,
      }}
      align={props.column.headerAlign || props.column.align}
    >
      {props.column.headerTemplateSupply ? (
        props.column.headerTemplateSupply(props.column)
      ) : (
        <span>{props.column.title}</span>
      )}
      {props.column.enableSorting && <span className="wpg-sort-icon">{getSortedNode()}</span>}
    </TableCell>
  );
};

export default WpGridHeaderCell;
