import { useTheme } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const generateStyleString = ({ min, max }: { min?: Breakpoint; max?: Breakpoint }) => {
  const theme = useTheme();
  let breakString = '';
  if (min) breakString = min ? `(min-width: ${theme.breakpoints.values[min]}px)` : '';
  if (breakString.length && max) breakString += ' and ';
  if (max) breakString += max ? `(max-width: ${theme.breakpoints.values[max]}px)` : '';
  return breakString;
};

export const useWpMediaQuery = (enableDeviceSniffing?: boolean) => {
  const isXsScreen = useMediaQuery(generateStyleString({ max: 'sm' })); // theme.breakpoints.down not working when tested, so I've used this approach
  const isMobileScreen = useMediaQuery(generateStyleString({ max: 'md' })); // theme.breakpoints.down not working when tested, so I've used this approach
  const isDesktopScreen =
    useMediaQuery(generateStyleString({ min: 'md' })) ||
    (enableDeviceSniffing && !window.navigator.userAgent.toLowerCase().includes('mobi'));
  return { isXsScreen, isMobileScreen, isDesktopScreen };
};

export const useMediaQueryIsMobile = () => {
  const { isMobileScreen } = useWpMediaQuery();
  return isMobileScreen;
};
