type IStrictKeyPress = {
  event: any;
  strictPattern?: RegExp;
  name?: string;
  callback?: (event: any) => void;
  isBankField?: boolean;
};
import { useFormContext } from 'react-hook-form';
import { FieldPatterns } from '../utility/Regex-patterns/fieldPatterns';

const useStrictKeyPress = () => {
  const form = useFormContext();
  const handlePasteEvent = ({ event, name = '', isBankField }: IStrictKeyPress) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text/plain');
    if (pastedData && isBankField) {
      const modifiedContent = pastedData.replace(FieldPatterns.BANK_FIELD_REPLACE, '');
      const currentValueOfTextField = form.getValues(name);
      if (currentValueOfTextField !== undefined) form.setValue(name, form.getValues(name) + modifiedContent);
      else form.setValue(name, modifiedContent);
    } else if (!isBankField && pastedData) {
      const modifiedContent = pastedData.replace(FieldPatterns.STANDARD_KEYBOARD_CHARACHTERS, '');
      let currentValueOfTextField = form.getValues(name);
      currentValueOfTextField = currentValueOfTextField?.name || currentValueOfTextField;
      if (!!currentValueOfTextField) form.setValue(name, currentValueOfTextField + modifiedContent);
      else form.setValue(name, modifiedContent);
    }
  };

  const keyPressEvent = ({ event, strictPattern, callback, isBankField }: IStrictKeyPress) => {
    const value = `${event.target.value}${event.key}`;
    if (isBankField) {
      if (!FieldPatterns.BANK_FIELD_STANDARDS.test(value)) event.preventDefault();
      else callback?.(event);
    } else if (strictPattern && !(strictPattern && strictPattern.test(value))) event.preventDefault();
    else callback?.(event);
  };
  return { keyPressEvent, handlePasteEvent };
};

export default useStrictKeyPress;
