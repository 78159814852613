// function debounce<Params extends any[]>(
//   func: (...args: Params) => any,
//   timeout: number
// ): (...args: Params) => void {
//   let timer: any;
//   return (...args: Params) => {
//     clearTimeout(timer);
//     timer = setTimeout(() => {
//       func(...args);
//     }, timeout);
//   };
// }

// const debounce = (n: number, fn: (...params: any[]) => any, immed = false) => {
//   let timer: number | undefined = undefined;
//   return function (this: any, ...args: any[]) {
//     if (timer === undefined && immed) {
//       fn.apply(this, args);
//     }
//     clearTimeout(timer);
//     timer = setTimeout(() => fn.apply(this, args), n);
//     return timer;
//   };
// };

const debounce = (func: any, timeout = 300) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export { debounce };
//WIP
