import React, { useEffect } from 'react';
import { WpDatePicker } from 'components/wp-datePicker/wpDatePicker';
import { FormProvider, useForm } from 'react-hook-form';
import { IWpFilterWidgetDropdownType } from '../wpFilterWidget.interface';
import { filterTypeStyles } from './filterTypeStyles';

type WpFilterWidgetFilterDateProps = {
  index: number;
  changeDateOption: (index: number, date: Date | null) => void;
  filterType: IWpFilterWidgetDropdownType;
};

export const FilterDate = ({ filterType, index, changeDateOption }: WpFilterWidgetFilterDateProps) => {
  const methods = useForm();
  const classes = filterTypeStyles();
  const { setValue } = methods;

  useEffect(() => {
    setValue(filterType?.name, filterType.dateValue);
  }, [filterType.dateValue]);
  return (
    <FormProvider {...methods}>
      <div className={classes.dateContainer}>
        <WpDatePicker
          name={filterType?.name}
          label={filterType?.name}
          defaultValue={filterType.dateValue || null}
          callBackOnChange={(date) => {
            changeDateOption(index, date);
          }}
          {...filterType.datePickerProps}
        />
      </div>
    </FormProvider>
  );
};
