export enum WpGridColumnSortDirectionEnum {
  NONE = 0,
  ASC = 1,
  DESC = 2,
}

export enum WpGridTextAlignmentEnum {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
}

export enum WpGridStickyDirectionEnum {
  LEFT = 'sticky-left',
  RIGHT = 'sticky-right',
}

export enum WpGridVerticalAlignmentEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
}
