import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IWpIconProps } from './wpIcon.interface';
import clsx from 'clsx';

const generateColorClassName = (theme: Theme) => {
  const colorMap = {
    primary: theme.palette.primary.main,
    primaryDisabled: theme.palette.primary.disabled,
    secondary: theme.palette.secondary.main,
    secondaryDisabled: theme.palette.secondary.disabled,
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    link: theme.palette.link.main,
    warning: theme.palette.warning.main,
    black: theme.palette.common.black,
    white: theme.palette.common.white,
    brown: theme.palette.common.brown,
    lightbrown: theme.palette.common.lightbrown,
    blue: theme.palette.decorative.blue.main,
    red: theme.palette.decorative.red.main,
    orange: theme.palette.decorative.orange.main,
    green: theme.palette.decorative.green.main,
    grey: theme.palette.decorative.blue.main,
    yellow: theme.palette.decorative.yellow.main,
    disabled: theme.palette.text.disabled,
  };

  return Object.keys(colorMap).reduce((acc, key) => {
    acc[`&.wpIconFillColor-${key} *`] = {
      fill: colorMap[key],
    };

    return acc;
  }, {});
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      verticalAlign: 'middle',
    },
    rootMlWithGutter: {
      marginLeft: theme.spacing(0.5),
    },
    rootMrWithGutter: {
      marginRight: theme.spacing(0.5),
    },
    rootMtWithGutter: {
      marginTop: theme.spacing(0.5),
    },
    rootMbWithGutter: {
      marginBottom: theme.spacing(0.5),
    },
    rootColor: generateColorClassName(theme),
  })
);

const WpIcon = (props: IWpIconProps) => {
  const { size = 16, svgIcon, color } = props;
  const classes = useStyles();
  const selectedIcon = svgIcon;
  const label = selectedIcon?.altText;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(classes.root, classes.rootColor, color ? `wpIconFillColor-${color}` : `wpIconFillColor-black`, {
        [classes.rootMlWithGutter]: props.gutter === 'left' || props.gutter == 'x' || props.gutter == 'all',
        [classes.rootMrWithGutter]: props.gutter === 'right' || props.gutter == 'x' || props.gutter == 'all',
        [classes.rootMtWithGutter]: props.gutter === 'top' || props.gutter == 'y' || props.gutter == 'all',
        [classes.rootMbWithGutter]: props.gutter === 'bottom' || props.gutter == 'y' || props.gutter == 'all',
      })}
      viewBox="0 0 10 10"
      height={size}
      width={size}
      strokeWidth="none"
      aria-labelledby={label}
      role="img"
    >
      {props?.showLabel && <title id={label}>{label}</title>}
      {selectedIcon?.svg}
    </svg>
  );
};

export default WpIcon;
