import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path d="M5 4.59698C2.57 4.59698 2.5 9.53398 2.5 10.097C2.5 10.2296 2.55268 10.3568 2.64645 10.4505C2.74021 10.5443 2.86739 10.597 3 10.597H7C7.13261 10.597 7.25979 10.5443 7.35355 10.4505C7.44732 10.3568 7.5 10.2296 7.5 10.097C7.5 9.53398 7.43 4.59698 5 4.59698Z" />
      <path d="M3.5 2.09698C3.5 2.49481 3.65804 2.87634 3.93934 3.15764C4.22064 3.43895 4.60218 3.59698 5 3.59698C5.39782 3.59698 5.77936 3.43895 6.06066 3.15764C6.34196 2.87634 6.5 2.49481 6.5 2.09698C6.5 1.69916 6.34196 1.31763 6.06066 1.03632C5.77936 0.75502 5.39782 0.596985 5 0.596985C4.60218 0.596985 4.22064 0.75502 3.93934 1.03632C3.65804 1.31763 3.5 1.69916 3.5 2.09698Z" />
    </>
  ),
};

export default icon;
