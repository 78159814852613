import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        d="M5,0a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,0ZM4.375,2.5a.625.625,0,0,1,1.25,0v2a.625.625,0,0,1-1.25,0ZM5,8A1,1,0,1,1,6,7,1,1,0,0,1,5,8Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
};

export default icon;
