import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { default as MuiTypography, TypographyProps } from '@material-ui/core/Typography';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import WpToolTip from 'components/wp-tooltip';
import useWindowSize from 'utility/useWindowSize';

const withCommon = (styles: CSSProperties) => ({
  ...styles,
});

export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'p1' | 'p1Bold' | 'p2' | 'p3' | 'body1' | 'body2';

export type WpTypographyColors =
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'textDisabled'
  | 'error'
  | 'success';
// TODO: RB - body1, body2 will be deprecated

interface ITypographyProps extends Omit<TypographyProps<React.ElementType>, 'variant'> {
  variant?: TypographyVariant;
  truncate?: boolean;
  wrapAnywhere?: boolean;
  maxLines?: number;
}

const DEFAULT_VARIANT: TypographyVariant = 'p1';

export const TypographyStyles = makeStyles<Theme>((theme) =>
  createStyles({
    h1: withCommon(theme.typography.h1),
    h2: withCommon(theme.typography.h2),
    h3: withCommon(theme.typography.h3),
    h4: withCommon(theme.typography.h4),
    p1: withCommon(theme.tag.p1),
    p1Bold: withCommon(theme.tag.p1Bold),
    p2: withCommon(theme.tag.p2),
    p3: withCommon(theme.tag.p3),
    body1: withCommon(theme.typography.body1),
    body2: withCommon(theme.typography.body2),

    truncate: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
    },

    wrapAnywhere: {
      overflowWrap: 'anywhere',
    },

    primary: {
      color: theme.palette.primary.main,
    },

    secondary: {
      color: theme.palette.secondary.main,
    },

    textPrimary: {
      color: theme.palette.text.primary,
    },

    textSecondary: {
      color: theme.palette.text.secondary,
    },

    error: {
      color: theme.palette.error.main,
    },

    success: {
      color: theme.palette.success.main,
    },

    textDisabled: {
      color: theme.palette.text.disabled,
    },
  })
);

export const WpTypography = ({
  variant,
  color = 'textPrimary',
  className,
  wrapAnywhere = false,
  maxLines = 1,
  ...props
}: ITypographyProps) => {
  const classes = TypographyStyles();

  const [truncate, setTruncate] = useState(false);

  const size = useWindowSize();

  const variantCssClassMap: Record<TypographyVariant, string> = {
    h1: classes.h1,
    h2: classes.h2,
    h3: classes.h3,
    h4: classes.h4,
    p1: classes.p1,
    p1Bold: classes.p1Bold,
    p2: classes.p2,
    p3: classes.p3,
    body1: classes.body1,
    body2: classes.body2,
  };

  const variantMap: any = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    p1: 'body1',
    p1Bold: 'body1',
    p2: 'body2',
    p3: 'body2',
  };

  const colorMap: any = {
    textDisabled: classes.textDisabled,
    primary: classes.primary,
    secondary: classes.secondary,
    textPrimary: classes.textPrimary,
    textSecondary: classes.textSecondary,
    error: classes.error,
    success: classes.success,
  };

  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current?.offsetWidth < ref.current?.scrollWidth) setTruncate(true);
    else if (ref.current?.clientHeight < ref.current?.scrollHeight) setTruncate(true);
    else setTruncate(false);
  }, [ref.current, size]);

  const renderTypography = () => (
    <MuiTypography
      ref={ref}
      variant={variantMap[variant || DEFAULT_VARIANT]}
      className={clsx(className, variantCssClassMap[variant || DEFAULT_VARIANT], colorMap[color], {
        [classes.truncate]: props.truncate,
        [classes.wrapAnywhere]: wrapAnywhere,
      })}
      {...props}
      style={{ WebkitLineClamp: props.truncate ? maxLines : undefined, ...props.style }}
    />
  );

  if (props.truncate && truncate) {
    return (
      <WpToolTip title={props.children}>
        <span>{renderTypography()}</span>
      </WpToolTip>
    );
  }

  return renderTypography();
};
