import React from 'react';

export const getNodeText = (node: React.ReactNode): string => {
  if (['string', 'number'].includes(typeof node)) {
    return String(node);
  }

  if (Array.isArray(node)) {
    return node.map(getNodeText).join('');
  }

  if (typeof node === 'object' && node !== null) {
    return getNodeText((node as React.ReactElement).props.children);
  }

  return '';
};
