export const wp = {
  palette: {
    primary: {
      main: '#5C63B2',
      dark: '#494F95',
      disabled: '#C5C8E9',
      light: '#EFF0FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#D0D4FF',
    },
    link: {
      main: '#006FBB',
      disabled: '#80B7DD',
      light: '#BFDBEE',
    },
    common: {
      black: '#2D313F',
      white: '#FFFFFF',
      brown: '#B2A7A7',
      lightbrown: '#DFD8D8',
    },
    error: {
      main: '#BF4240',
      light: '#E5B3B3',
    },
    success: {
      main: '#7A9E63',
      light: '#CAD8C1',
    },
    warning: {
      main: '#DD7408',
      light: '#F1C79C',
    },
    decorative: {
      blue: {
        light: '#DEE0F0',
        main: '#2B2E52',
      },
      green: {
        light: '#CFE7D7',
        main: '#39403B',
      },
      orange: {
        light: '#F8E5D3',
        main: '#5C422B',
      },
      red: {
        light: '#F2D9D9',
        main: '#54231C',
      },
      grey: {
        light: '#E3E5E4',
        main: '#2D313F',
      },
      brown: {
        light: '#DFD8D8',
        main: '#B2A7A7',
      },
      yellow: {
        main: '#FFA750',
      },
    },
    background: {
      default: '#FBFAF9',
      highlight: '#F1EDE9',
      disabled: '#F1F1F1',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2D313F',
      secondary: '#ffffff',
      disabled: '#A9AAAF',
    },
  },
  font: {
    family: {
      primary: 'p22-mackinac-pro',
      secondary: 'Inter',
    },
    weight: {
      regular: 500,
      semibold: 600,
      bold: 700,
    },
    size: {
      base: 15,
    },
    lineHeight: {
      regular: '1.5rem',
    },
  },
  border: {
    radius: {
      default: 6,
    },
    weight: {
      default: `1px solid`,
    },
  },
  // boxshadows
  shadows: {
    default: {},
  },
};

export const wpNew = {
  palette: {
    primary: {
      main: '#538314',
      dark: '#38570F',
      disabled: '#AFC197',
      light: '#EFF3E7',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#7E83D2',
      dark: '#484C7F',
      disabled: '#C2C5EE',
      light: '#F3F3F9',
      contrastText: '#F3F3F9',
    },
    link: {
      main: '#006FBB',
      disabled: '#80B7DD',
      light: '#BFDBEE',
    },
    common: {
      black: '#2D313F',
      white: '#FFFFFF',
      brown: '#B2A7A7',
      lightbrown: '#DFD8D8',
    },
    error: {
      main: '#C7240E',
      light: '#FAA99E',
    },
    success: {
      main: '#538314',
      light: '#E4EDD6',
    },
    warning: {
      main: '#DE6D04',
      light: '#F9BF89',
    },
    decorative: {
      blue: {
        light: '#DEE0F0',
        main: '#2B2E52',
      },
      green: {
        light: '#CFE7D7',
        main: '#39403B',
      },
      orange: {
        light: '#F8E5D3',
        main: '#5C422B',
      },
      red: {
        light: '#F2D9D9',
        main: '#54231C',
      },
      grey: {
        light: '#E3E5E4',
        main: '#2D313F',
      },
      brown: {
        light: '#DFD8D8',
        main: '#B2A7A7',
      },
      yellow: {
        main: '#FFA750',
      },
    },
    background: {
      default: '#FBFAF9',
      highlight: '#F1EDE9',
      disabled: '#F1F1F1',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2D313F',
      secondary: '#ffffff',
      disabled: '#A9AAAF',
    },
  },
  font: {
    family: {
      primary: 'p22-mackinac-pro',
      secondary: 'Inter',
    },
    weight: {
      regular: 400,
      semibold: 600,
      bold: 600,
    },
    size: {
      base: 15,
    },
    lineHeight: {
      regular: '1.5rem',
    },
  },
  border: {
    radius: {
      default: 6,
    },
    weight: {
      default: `1px solid`,
    },
  },
  // boxshadows
  shadows: {
    default: {},
  },
};

export const wp3 = {
  palette: {
    primary: {
      main: '#2778D7',
      dark: '#0066DB',
      disabled: '#C7E1FF',
      light: '#ECF5FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#7E83D2',
      dark: '#484C7F',
      disabled: '#C2C5EE',
      light: '#F3F3F9',
      contrastText: '#F3F3F9',
    },
    link: {
      main: '#006FBB',
      disabled: '#80B7DD',
      light: '#BFDBEE',
    },
    common: {
      black: '#092B51',
      white: '#FFFFFF',
      brown: '#E9ECEF',
      lightbrown: '#E9ECEF',
    },
    error: {
      main: '#C7240E',
      light: '#FAA99E',
    },
    success: {
      main: '#538314',
      light: '#E4EDD6',
    },
    warning: {
      main: '#DE6D04',
      light: '#F9BF89',
    },
    decorative: {
      blue: {
        light: '#DEE0F0',
        main: '#2B2E52',
      },
      green: {
        light: '#CFE7D7',
        main: '#39403B',
      },
      orange: {
        light: '#F8E5D3',
        main: '#5C422B',
      },
      red: {
        light: '#F2D9D9',
        main: '#54231C',
      },
      grey: {
        light: '#E3E5E4',
        main: '#2D313F',
      },
      brown: {
        light: '#DFD8D8',
        main: '#B2A7A7',
      },
      yellow: {
        main: '#FFA750',
      },
    },
    background: {
      default: '#F8FBFF',
      highlight: '#F1EDE9',
      disabled: '#F6F7F9',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2D313F',
      secondary: '#ffffff',
      disabled: '#A9AAAF',
    },
  },
  font: {
    family: {
      primary: 'p22-mackinac-pro',
      secondary: 'Inter',
    },
    weight: {
      regular: 400,
      semibold: 500,
      bold: 500,
    },
    size: {
      base: 14,
    },
    lineHeight: {
      regular: '1.5rem',
    },
  },
  border: {
    radius: {
      default: 6,
    },
    weight: {
      default: `1px solid`,
    },
  },
  // boxshadows
  shadows: {
    default: {},
  },
};
