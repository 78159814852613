import React from 'react';
import { IconType } from './icon-types';

const icon: IconType = {
  svg: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49994 2.66666H1.50006C1.36457 2.66666 1.24739 2.71797 1.14844 2.82059C1.04948 2.92331 1 3.04482 1 3.18523C1 3.32564 1.04948 3.44715 1.14844 3.54977L4.64838 7.17929C4.74743 7.28191 4.86461 7.33332 5 7.33332C5.13539 7.33332 5.25267 7.28191 5.35153 7.17929L8.85147 3.54977C8.95033 3.44715 9 3.32564 9 3.18523C9 3.04482 8.95033 2.92331 8.85147 2.82059C8.75261 2.71797 8.63533 2.66666 8.49994 2.66666Z"
      />
    </>
  ),
};

export default icon;
